import {
  ArrowBack as ArrowBackIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Delete as DeleteIcon,
  Person as PersonIcon,
  Send as SendIcon,
  Settings as SettingsIcon
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Drawer,
  FormControl,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  Slider,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { marked } from 'marked';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../header/Header';
import CustomLogger from '../logger/Logger';

// モデル選択用のPopoverスタイル
const StyledPopover = styled((props) => (
  <Popover
    {...props}
    classes={{ paper: props.className }}
    disablePortal
    TransitionProps={{ timeout: 0 }}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    position: 'relative',
    marginTop: '8px',
    backgroundColor: '#fff',
    borderRadius: '12px',
    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
    overflow: 'visible',
    maxWidth: '280px',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '-15px',
      left: '30px',
      width: 0,
      height: 0,
      borderLeft: '15px solid transparent',
      borderRight: '15px solid transparent',
      borderBottom: '15px solid #fff',
    },
  },
}));

const ChatWindow = () => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [historyCount, setHistoryCount] = useState(0);
  const [model, setModel] = useState('gpt-4o-mini');
  const [hasHistory, setHasHistory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const logger = new CustomLogger();
  const messagesEndRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');
  const location = useLocation();
  const navigate = useNavigate();
  const { notificationCount, notifications, userData, category } = location.state || {};
  const { userId } = location.state || {};
  const iconRef = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [showResolutionOptions, setShowResolutionOptions] = useState(false);
  const [questionContent, setQuestionContent] = useState('');
  const [lastUserMessage, setLastUserMessage] = useState('');
  const [hierarchyData, setHierarchyData] = useState([]);
  const [labelMissingDialogOpen, setLabelMissingDialogOpen] = useState(false);

  // モデル選択Popover関連
  const [modelPopoverOpen, setModelPopoverOpen] = useState(false);
  const [modelPopoverAnchorEl, setModelPopoverAnchorEl] = useState(null);
  const [currentPath, setCurrentPath] = useState([]);

  // 履歴管理
  const [histories, setHistories] = useState([]);
  const [currentHistoryId, setCurrentHistoryId] = useState(null);

  // 履歴パネルを隠すフラグ
  const [isHistoryHidden, setIsHistoryHidden] = useState(false);

  // Temperatureスライダー
  const [temperature, setTemperature] = useState(0.7);

  // キャラクターポップオーバー
  const [characterPopoverOpen, setCharacterPopoverOpen] = useState(false);
  const characterButtonRef = useRef(null);

  // 最近の質問のID
  const [lastQuestionId, setLastQuestionId] = useState(null);

  useEffect(() => {
    if (userId) {
      fetchChatHistories();
    }
  }, [userId]);

  // モデルが未選択ならキャラポップオーバー表示
  useEffect(() => {
    if (!selectedModel) {
      setCharacterPopoverOpen(true);
    }
  }, [selectedModel]);

  // 階層データの取得
  useEffect(() => {
    fetchHierarchyData();
  }, []);

  // messagesが更新されたらスクロール
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // 履歴一覧を取得
  const fetchChatHistories = async () => {
    try {
      const res = await axios.get('/backend/get_chat_histories', {
        params: { userId },
        withCredentials: true,
      });
      if (res.status === 200) {
        setHistories(res.data);
      }
    } catch (error) {
      console.error('チャット履歴の取得エラー:', error);
      setErrorMessage('チャット履歴の取得に失敗しました。');
    }
  };

  // ファイルをダウンロード
  const handleFileDownload = async (filename) => {
    console.log(`Attempting to download file: ${filename}`);
    try {
      const res = await axios.post(
        '/backend/download_pdf_file',
        {
          uniqueFileName: filename,
        },
        { responseType: 'blob' }
      );

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      setTimeout(() => {
        if (link && link.parentNode) {
          link.parentNode.removeChild(link);
        }
        window.URL.revokeObjectURL(url);
      }, 0);
    } catch (error) {
      console.error('Download error:', error);
      alert('ファイルのダウンロードに失敗しました。');
    }
  };
  
  // コンテンツを描画
  function convertLinksOnly(text) {
    const sourcePrefix = 'ソース:';
    const pagePrefix = 'ページ:';
    let formattedText = text;
    let lastIndex = 0;
    const elements = [];
  
    while (true) {
      const sourcePos = formattedText.indexOf(sourcePrefix, lastIndex);
      if (sourcePos === -1) break;
      const pagePos = formattedText.indexOf(pagePrefix, sourcePos + sourcePrefix.length);
      if (pagePos === -1) break;
  
      // ソース: までの部分を追加
      const beforeSource = formattedText.substring(lastIndex, sourcePos);
      if (beforeSource) elements.push(<span key={`before-${lastIndex}`}>{beforeSource}</span>);
  
      // ソース: と ページ: の間をファイル名として取得
      const filenameStart = sourcePos + sourcePrefix.length;
      const filenameEnd = pagePos;
      const filename = formattedText.substring(filenameStart, filenameEnd).trim();
  
      // リンクにする
      const link = (
        <a
          key={`file-download-${filename}-${lastIndex}`}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            handleFileDownload(filename);
          }}
          style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}
        >
          {filename}
        </a>
      );
      elements.push(<span key={`source-label-${lastIndex}`}>{sourcePrefix}</span>);
      elements.push(link);
  
      // ページ: から次の改行 or 文字列末までを追加
      const nextNewline = formattedText.indexOf('\n', pagePos);
      if (nextNewline === -1) {
        elements.push(
          <span key={`page-rest-${lastIndex}`}>
            {formattedText.substring(pagePos)}
          </span>
        );
        lastIndex = formattedText.length;
        break;
      } else {
        const chunk = formattedText.substring(pagePos, nextNewline + 1);
        elements.push(<span key={`page-chunk-${lastIndex}`}>{chunk}</span>);
        lastIndex = nextNewline + 1;
      }
    }
  
    // ループ後の残りを追加
    if (lastIndex < formattedText.length) {
      elements.push(
        <span key={`rest-${lastIndex}`}>
          {formattedText.substring(lastIndex)}
        </span>
      );
    }
  
    return elements;
  }

  // メインのレンダリング関数（exportを外した）
  function renderMessageContent(text) {
    const refKeyword = '参照情報:';
    const refIndex = text.indexOf(refKeyword);

    if (refIndex === -1) {
      // 「参照情報:」がなければ、すべてマークダウンに
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: marked(text),
          }}
        />
      );
    }

    // 参照情報: より上の部分だけマークダウン化
    const topText = text.slice(0, refIndex);

    // 参照情報: 以降はリンク生成のみ
    const bottomText = text.slice(refIndex);

    return (
      <>
        {/* 上の部分をマークダウン変換 */}
        <div
          dangerouslySetInnerHTML={{
            __html: marked(topText),
          }}
        />
        {/* 下の部分をリンク生成のみ */}
        {convertLinksOnly(bottomText)}
      </>
    );
  }  
  // 階層データを取得
  const fetchHierarchyData = async () => {
    try {
      const response = await axios.get('/backend/gethierarchy');
      setHierarchyData(response.data);
    } catch (error) {
      console.error('階層データの取得に失敗しました', error);
    }
  };

  // 特定の履歴をロード
  const loadHistory = async (historyId) => {
    try {
      const res = await axios.get('/backend/get_chat_history_messages', {
        params: { historyId },
        withCredentials: true,
      });
      if (res.status === 200) {
        setMessages(res.data);
        setCurrentHistoryId(historyId);
        setHasHistory(true);
      }
    } catch (error) {
      console.error('チャット履歴のロードエラー:', error);
      setErrorMessage('チャット履歴の読み込みに失敗しました。');
    }
  };

  // 新しいチャットを開始
  const startNewChat = () => {
    setMessages([]);
    setCurrentHistoryId(null);
    setHasHistory(false);
  };

  // メッセージ送信
  const handleSendMessage = async () => {
    if (message.trim() === '') return;

    setIsLoading(true);
    setErrorMessage('');
    setShowResolutionOptions(false);
    setLastUserMessage(message);

    try {
      let saveRes;
      const modelUsed = selectedModel?.type === 'internal'
      ? constructChromaName()
      : (selectedModel?.type ?? 'standard');

      if (!currentHistoryId) {
        // 新しい履歴を作成
        saveRes = await axios.post(
          `/backend/save_chat_question`,
          { userId, text: message ,model_used: modelUsed},
          { withCredentials: true }
        );
        const newHistoryId = saveRes.data.historyId;
        setCurrentHistoryId(newHistoryId);
        fetchChatHistories(); // リスト更新
      } else {
        // 既存の履歴に追加
        saveRes = await axios.post(
          `/backend/save_chat_question`,
          { userId, text: message, historyId: currentHistoryId },
          { withCredentials: true }
        );
      }

      const historyId = saveRes.data.historyId;
      setCurrentHistoryId(historyId);

      // 質問IDをステートに保存 (解決可否の送信で使う)
      const questionId = saveRes.data?.id || null;
      setLastQuestionId(questionId);
      
      // ローカルにメッセージを追加
      const updatedMessages = [...messages, { isUser: true, text: message }];
      setMessages(updatedMessages);
      setHasHistory(true);

      // リクエストデータ
      const requestData = {
        userId,
        questionId: saveRes.data.id,
        historyId,
        settings: { model, historyCount, temperature },
        text: message,
        messages: updatedMessages.slice(-15),
        withCredentials: true,
      };

      // サーバーに送信
      let chatRes;
      if (selectedModel?.type === 'standard') {
        chatRes = await axios.post(`/backend/send_chat_question`, requestData);
      } else if (selectedModel?.type === 'internal') {
        let chroma_name = constructChromaName();
        requestData.chroma_name = chroma_name;
        chatRes = await axios.post(`/backend/send_chat_question_internal_documents`, requestData);
      } else {
        setErrorMessage('モデルを選択してください。');
        console.error('モデル未選択');
        setIsLoading(false); // ローディングを解除
        return;
      }

      if (chatRes && chatRes.status === 200) {
        const newMessage = {
          isUser: false,
          text: chatRes.data.message || chatRes.data,
          type: selectedModel.type
        };
        setMessages((prev) => [...prev, newMessage]);
        setShowResolutionOptions(true);
        fetchChatHistories(); // リスト更新
      } else {
        setErrorMessage('エラーが発生しました。もう一度質問してください。');
        console.error('chatResが正常でない:', chatRes);
      }
    } catch (error) {
      if (error.response) {
        console.error('レスポンスエラー:', error.response);
        setErrorMessage(error.response.data.message || 'サーバーエラー');
      } else if (error.request) {
        console.error('リクエストエラー:', error.request);
        setErrorMessage('サーバーへの接続に失敗しました。');
      } else {
        console.error('未知のエラー:', error.message);
        setErrorMessage(`未知のエラー: ${error.message}`);
      }
    } finally {
      setIsLoading(false);
      setMessage('');
    }
  };

  //「問題は解決しましたか？」の選択肢
  const handleResolutionChoice = async (choice) => {
    console.log(`解決の選択肢: ${choice}`);
    setShowResolutionOptions(false);

    try {
      await axios.post('/backend/update_question_resolution', {
        questionId: lastQuestionId,
        resolution: choice,
      });

      if (choice === 'はい') {
        setMessages((prev) => [
          ...prev,
          { isUser: false, text: 'お役に立てて良かったです！' },
        ]);
      } else if (choice === '再度チャットで質問する') {
        setMessages((prev) => [
          ...prev,
          { isUser: false, text: '再度質問をどうぞ。' },
        ]);
      } else if (choice === '他の人に質問する') {
        setQuestionContent(lastUserMessage);
        navigate('/question', {
          state: {
            userId,
            questionContent: lastUserMessage,
            notificationCount,
            notifications,
            userData,
          },
        });
        setCurrentHistoryId(null);
      }
    } catch (err) {
      console.error('解決可否の送信中にエラー:', err);
    }
  };

  // 履歴リストの描画
  const renderHistories = () => (
    <List>
      {histories.map((history) => (
        <ListItem
          key={history.historyId}
          secondaryAction={
            <IconButton 
              edge="end" 
              aria-label="delete" 
              onClick={(e) => {
                e.stopPropagation(); // ListItem の onClick を防止
                handleDeleteHistory(history.historyId);
              }}
              sx={{
                mr: 0.5,
                '&:hover': {
                  color: '#d32f2f',
                },
              }}
            >
              <DeleteIcon />
            </IconButton>
          }
          onClick={() => loadHistory(history.historyId)}
          sx={{
            backgroundColor: history.historyId === currentHistoryId ? '#cfd8dc' : 'inherit',
            borderLeft: history.historyId === currentHistoryId ? '4px solid #2196f3' : '4px solid transparent',
            '&:hover': {
              backgroundColor: '#b0bec5',
            },
          }}
        >
        <ListItemText
          primary={history.title || `履歴 ${history.historyId}`}
          secondary={history.created_at}
        />
        </ListItem>
      ))}
      {histories.length === 0 && (
        <Typography variant="body2" color="textSecondary">
          履歴がありません。
        </Typography>
      )}
      <ListItem button onClick={startNewChat}>
        <ListItemText primary="新しいチャットを開始" />
      </ListItem>
    </List>
  );

  // 削除確認ダイアログ
  const handleConfirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
  };

  // 削除確認ダイアログを開く
  const handleConfirmDelete = () => {
    setMessages([]);
    setCurrentHistoryId(null);
    setConfirmDeleteOpen(false);
    setHasHistory(false);
  };

  /**
   * 履歴を削除するハンドラー
   * @param {string} historyId - 削除する履歴のID
   */
  const handleDeleteHistory = async (historyId) => {
    try {
      const res = await axios.delete(`/backend/delete_chat_history`, {
        data: { historyId }, // リクエストボディにhistoryIdを含める
        withCredentials: true,
      });

      if (res.status === 200) {
        // 履歴が正常に削除された場合、フロントエンドの状態からも削除
        setHistories((prevHistories) => prevHistories.filter((history) => history.historyId !== historyId));

        // 現在表示されている履歴が削除された場合、メッセージをクリア
        if (currentHistoryId === historyId) {
          setMessages([]);
          setCurrentHistoryId(null);
          setHasHistory(false);
        }
      } else {
        setErrorMessage('履歴の削除に失敗しました。もう一度お試しください。');
        console.error('履歴削除リクエストが正常に完了しませんでした:', res);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error) {
        setErrorMessage(error.response.data.error);
      } else {
        setErrorMessage('履歴の削除中にエラーが発生しました。');
      }
      console.error('履歴削除エラー:', error);
    }
  };

  // モデル選択Popoverを開く
  const handleModelButtonClick = (event) => {
    setModelPopoverAnchorEl(event.currentTarget);
    setModelPopoverOpen(true);
    setCharacterPopoverOpen(false);
  };

  // モデルを選択
  const handleModelSelect = (modelType, label, name) => {
    setSelectedModel({ type: modelType, label: label, name: name });
    setModelPopoverOpen(false);
    setCurrentPath([]);
    if (messages.length > 0) {
      setHasHistory(true);
    }
    // setMessages([]);
    document.cookie = 'messages=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  };

  // 戻るボタン
  const handleBack = () => {
    setCurrentPath(currentPath.slice(0, currentPath.length - 1));
  };

  // 階層を選択
  const currentNodes =
    currentPath.length === 0
      ? hierarchyData
      : currentPath[currentPath.length - 1].children || [];

  // 階層をレンダリング
  const renderHierarchy = (nodes) =>
    nodes.map((node) => (
      <ListItem key={node.id} disablePadding>
        <ListItemButton
          onClick={() => {
            if (!node.label) {
              setLabelMissingDialogOpen(true);
              return;
            }
            handleModelSelect('internal', node.label, node.name);
            setModelPopoverOpen(false);
            setCurrentPath([...currentPath, node]);
          }}
        >
          <ListItemText
            primary={`${node.name}の ChatGPT`}
            secondary={
              node.children && node.children.length > 0
                ? `${node.name}の質問に適しています。`
                : `${node.name}に関する質問に適しています。`
            }
            primaryTypographyProps={{ fontWeight: 'bold' }}
          />
          {node.children && node.children.length > 0 && (
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setCurrentPath([...currentPath, node]);
              }}
              edge="end"
            >
              <ChevronRightIcon />
            </IconButton>
          )}
        </ListItemButton>
      </ListItem>
    ));

  // ラベルをサニタイズする関数
  const sanitizeLabel = (label) => {
    // 空白をアンダースコアに置換
    let sanitized = label.replace(/\s+/g, '_');
    
    // 連続するアンダースコアを単一にする
    sanitized = sanitized.split('_').filter(part => part).join('_');
    
    // 先頭と末尾のアンダースコアを削除
    sanitized = sanitized.replace(/^_+|_+$/g, '');
    
    return sanitized;
  };

  // Chroma名 (内部文書用) を構築
  const constructChromaName = () => {
    const sanitizedLabels = currentPath.map(node => sanitizeLabel(node.label));
    return sanitizedLabels.join('_');
  };

  // 履歴表示/非表示のトグル
  const handleHideHistory = () => setIsHistoryHidden(true);
  const handleShowHistory = () => setIsHistoryHidden(false);

  // 設定ドロワーを開閉
  const handleSettingsClick = () => {
    setSettingsOpen(!settingsOpen);
  };

  // 設定ドロワーの描画
  const renderSettingsDrawer = () => (
    <Drawer anchor="right" open={settingsOpen} onClose={handleSettingsClick}>
      <Box sx={{ width: 300, p: 2 }}>
        <Typography variant="h6" gutterBottom>
          設定
        </Typography>

        <Typography variant="subtitle1">
          Temperature: {temperature}
        </Typography>
        <Slider
          value={temperature}
          min={0}
          max={1}
          step={0.01}
          onChange={(e, newValue) => setTemperature(newValue)}
          valueLabelDisplay="auto"
        />

        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          モデル
        </Typography>
        <FormControl fullWidth variant="outlined">
          <Select value={model} onChange={(e) => setModel(e.target.value)}>
            <MenuItem value="gpt-3.5-turbo">gpt-3.5-turbo</MenuItem>
            <MenuItem value="gpt-4o-mini">gpt-4.0-mini</MenuItem>
            <MenuItem value="gpt-4" disabled>
              gpt-4
            </MenuItem>
          </Select>
        </FormControl>

        <Typography variant="subtitle1" sx={{ mt: 2 }}>
          履歴数
        </Typography>
        <Slider
          value={historyCount}
          min={0}
          max={5}
          step={1}
          onChange={(e, newValue) => setHistoryCount(newValue)}
          valueLabelDisplay="auto"
        />

        <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleSettingsClick}>
          設定を保存して閉じる
        </Button>
      </Box>
    </Drawer>
  );

  return (
    <div>
      {/* ヘッダー */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, paddingTop: '10px' }}>
        <Header
          searchText={searchText}
          notificationCount={notificationCount}
          id="header-popover"
          iconRef={iconRef}
          arrowLeft={<ArrowBackIcon />}
          notifications={notifications}
          userId={userId}
          userData={userData}
          categories={category}
        />
        <Box display="flex" justifyContent="flex-start" my={0} mx="auto" width="100%" py="35px"></Box>
      </Box>

      {/* 画面を左右に分割：左に履歴パネル、右にチャット画面 */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          height: 'calc(100vh - 120px)',
          marginTop: '10px',
          position: 'relative',
        }}
      >
      {/* 履歴パネル（左） */}
      <Box
        sx={{
          width: isHistoryHidden ? '0px' : '300px',
          transition: 'width 0.3s ease',
          overflow: 'hidden',
          borderRight: isHistoryHidden ? 'none' : '1px solid #ccc',
          flexShrink: 0,
        }}
      >
        {!isHistoryHidden && (
          <Box
            sx={{
              width: '300px',
              height: '100%',
              overflowY: 'auto',
              p: 2,
              backgroundColor: '#e0e0e0',
              borderRight: '1px solid #ccc',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: '8px',
            }}
          >
            {/* ヘッダー部分に矢印アイコンボタンを追加 */}
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6">チャット履歴</Typography>
              <IconButton onClick={handleHideHistory} sx={{ ml: 14 }}>
                <ChevronLeftIcon />
              </IconButton>
            </Box>
            {renderHistories()}
          </Box>
        )}
      </Box>

        {/* 右：チャットメイン */}
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          {/* チャット画面ヘッダー */}
          <Box
            className="chat-header"
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              bgcolor: '#f0f0f0',
              px: 3,
              py: 2,
              borderBottom: '2px solid #e0e0e0',
              boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
              position: 'relative',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
              {/* 履歴が隠れている時のみ表示するボタン */}
              {isHistoryHidden && (
                <IconButton onClick={handleShowHistory} sx={{ mr: 1 }}>
                  <ChevronRightIcon
                    sx={{
                      color: '#555555',
                      fontSize: '28px',
                      transition: 'color 0.3s ease',
                      '&:hover': {
                        color: '#000000',
                      },
                    }}
                  />
                </IconButton>
              )}

              {/* モデル選択ボタン */}
              <Button
                variant="outlined"
                onClick={handleModelButtonClick}
                sx={{
                  marginLeft: 2,
                  position: 'relative',
                  zIndex: 2,
                  display: 'flex',
                  alignItems: 'center',
                  padding: '8px 16px',
                  minWidth: '200px',
                  maxWidth: 'fit-content',
                  border: '1px solid #ccc',
                  borderRadius: '12px',
                  backgroundColor: '#f9f9f9',
                  color: '#333',
                  fontSize: '14px',
                  fontWeight: 500,
                  textTransform: 'none',
                  transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    backgroundColor: '#f0f0f0',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  },
                  '&:active': {
                    backgroundColor: '#e6e6e6',
                  },
                }}
                id="model-select-button"
                ref={characterButtonRef}
              >
                <span style={{ flexGrow: 1, textAlign: 'left' }}>
                  {selectedModel?.type === 'standard' && '通常の ChatGPT'}
                  {selectedModel?.type === 'internal' && `${selectedModel.name}の ChatGPT`}
                  {!selectedModel && 'ChatGPTを選択'}
                </span>
                <span
                  style={{
                    fontSize: '16px',
                    color: '#888',
                    fontWeight: '400',
                    marginLeft: 'auto',
                  }}
                >
                  &gt;
                </span>
              </Button>

              {/* キャラクターの吹き出し */}
              {characterPopoverOpen && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: '100%',
                    left: '75%',
                    transform: 'translate(-45%, 10px)',
                    padding: '20px',
                    minWidth: '340px',
                    height: 'auto',
                    borderRadius: '20px',
                    background: 'linear-gradient(145deg, #ffffff, #e6e6e6)',
                    boxShadow:
                      '0 10px 20px rgba(0, 0, 0, 0.2), 0 6px 6px rgba(0, 0, 0, 0.15)',
                    textAlign: 'center',
                    zIndex: 999,
                    animation: 'fadeInPop 0.3s ease-out',
                    border: '1px solid #ddd',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '-12px',
                      left: '30%',
                      transform: 'translateX(-50%)',
                      width: 0,
                      height: 0,
                      borderLeft: '15px solid transparent',
                      borderRight: '15px solid transparent',
                      borderBottom: '15px solid #ffffff',
                      filter: 'drop-shadow(0 -4px 4px rgba(0, 0, 0, 0.1))',
                    }}
                  />
                  <img
                    src="character.png"
                    alt="キャラクター"
                    style={{
                      width: '120px',
                      height: '150px',
                    }}
                  />
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: '16px',
                      color: '#333333',
                      fontWeight: '600',
                      lineHeight: '1.5',
                      textAlign: 'left',
                      marginRight: '5px',
                      maxWidth: 'calc(100% - 140px)',
                    }}
                  >
                    モデル選択ボタンを押して
                    <br />
                    モデルを選択してね！
                  </Typography>
                </Box>
              )}
            </Box>

            {/* 設定ボタン */}
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={handleSettingsClick}>
                <SettingsIcon
                  sx={{
                    color: '#555555',
                    fontSize: '28px',
                    transition: 'color 0.3s ease',
                    '&:hover': {
                      color: '#000000',
                    },
                  }}
                />
              </IconButton>
            </Box>
          </Box>

          {/* メッセージ表示エリア */}
          <Box
            sx={{
              flex: 1,
              overflow: 'auto',
              position: 'relative',
              px: 2,
              py: 2,
              backgroundColor: '#f5f5f5',
            }}
          >
            {isLoading && (
              <Box
                sx={{
                  position: 'fixed',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 9999,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '20px',
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  borderRadius: '10px',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                }}
              >
                <CircularProgress size={60} />
                <Typography variant="subtitle1" sx={{ marginTop: '20px', fontWeight: 'bold' }}>
                  処理中です、しばらくお待ちください...
                </Typography>
              </Box>
            )}

            <List>
              {messages.map((msg, index) => (
                <ListItem
                  key={index}
                  sx={{
                    justifyContent: msg.isUser ? 'flex-end' : 'flex-start',
                    marginBottom: '8px',
                    alignItems: 'flex-start',
                  }}
                >
                  {!msg.isUser && (
                    <img
                      src="character.png"
                      alt="character"
                      style={{
                        width: '40px',
                        height: 'auto',
                        marginRight: '8px',
                        cursor: 'pointer',
                        transition: 'transform 0.3s ease',
                        objectFit: 'contain',
                        objectPosition: 'center',
                      }}
                      onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                      onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                    />
                  )}
                  <Box
                    sx={{
                      position: 'relative',
                      backgroundColor: msg.isUser ? '#81c784' : '#ffffff',
                      borderRadius: '12px',
                      maxWidth: '60%',
                      padding: '8px 16px',
                      boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
                      wordBreak: 'break-word',
                      overflowWrap: 'break-word',
                      display: 'inline-block',
                    }}
                  >
                    {msg.type === 'internal' ? (
                      <div
                        style={{ whiteSpace: 'pre-wrap', lineHeight: '1.2' }}
                      >
                        {renderMessageContent(msg.text)}
                      </div>
                    ) : (
                      <div
                        style={{
                          whiteSpace: 'pre-wrap',
                          lineHeight: '1.2',
                          margin: 0,
                          padding: '4px 0',
                          display: 'table',
                          verticalAlign: 'middle',
                        }}
                        dangerouslySetInnerHTML={{
                          __html: marked(msg.text.trim()),
                        }}
                      />
                    )}
                  </Box>
                  {msg.isUser && (
                    <Avatar
                      alt="User"
                      sx={{ width: 40, height: 40, marginLeft: '8px', backgroundColor: '#2196f3' }}
                    >
                      <PersonIcon style={{ color: '#ffffff' }} />
                    </Avatar>
                  )}
                </ListItem>
              ))}
              <div ref={messagesEndRef} />
            </List>
          </Box>

          {/* メッセージ入力エリア（モデルが選択されている場合のみ表示） */}
          {selectedModel && (
            <Box
              sx={{
                p: 2,
                display: 'flex',
                alignItems: 'center',
                borderTop: '1px solid #ccc',
                backgroundColor: '#ffffff',
              }}
            >
              <TextField
                variant="outlined"
                placeholder="メッセージを入力してください"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                fullWidth
                multiline
                maxRows={4}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                    </InputAdornment>
                  ),
                  sx: {
                    borderRadius: '30px',
                  },
                }}
              />
              <IconButton color="primary" onClick={handleSendMessage} sx={{ ml: 1 }}>
                <SendIcon />
              </IconButton>
            </Box>
          )}

          {/* モデル選択Popover */}
          <StyledPopover
            open={modelPopoverOpen}
            anchorEl={modelPopoverAnchorEl}
            onClose={() => {
              setModelPopoverOpen(false);
              setCurrentPath([]);
            }}
          >
            <Box sx={{ padding: '20px' }}>
              {currentPath.length > 0 && (
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <IconButton onClick={handleBack} sx={{ p: 0 }}>
                    <ArrowBackIcon sx={{ fontSize: '24px', color: '#333' }} />
                  </IconButton>
                  <Breadcrumbs separator="›" aria-label="breadcrumb" sx={{ ml: 1 }}>
                    {currentPath.map((node) => (
                      <Typography key={node.id} sx={{ fontSize: '16px', color: '#555' }}>
                        {node.name}
                      </Typography>
                    ))}
                  </Breadcrumbs>
                </Box>
              )}
              <List>
                {currentPath.length === 0 && (
                  <ListItem disablePadding>
                    <ListItemButton onClick={() => handleModelSelect('standard')}>
                      <ListItemText
                        primary="通常のChatGPT"
                        secondary="一般的な質問に適しています。"
                        primaryTypographyProps={{ fontWeight: 'bold' }}
                      />
                    </ListItemButton>
                  </ListItem>
                )}
                {renderHierarchy(currentNodes)}
              </List>
            </Box>
          </StyledPopover>

          {/* 問題解決の選択肢 */}
          {showResolutionOptions && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 3,
                backgroundColor: '#ffffff',
                borderTop: '1px solid #ccc',
                boxShadow: '0 -2px 5px rgba(0,0,0,0.1)',
              }}
            >
              <Typography variant="h6" sx={{ mb: 3, color: '#333333' }}>
                問題は解決しましたか？
              </Typography>
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                  variant="contained"
                  onClick={() => handleResolutionChoice('はい')}
                  sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                    borderRadius: '24px',
                    backgroundColor: '#4caf50',
                    color: '#ffffff',
                    paddingX: 3,
                    paddingY: 1.5,
                    '&:hover': {
                      backgroundColor: '#388e3c',
                    },
                  }}
                >
                  はい
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleResolutionChoice('再度チャットで質問する')}
                  sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                    borderRadius: '24px',
                    backgroundColor: '#03a9f4',
                    color: '#ffffff',
                    paddingX: 3,
                    paddingY: 1.5,
                    '&:hover': {
                      backgroundColor: '#0288d1',
                    },
                  }}
                >
                  再度チャットで質問する
                </Button>
                <Button
                  variant="contained"
                  onClick={() => handleResolutionChoice('他の人に質問する')}
                  sx={{
                    textTransform: 'none',
                    fontWeight: 'bold',
                    borderRadius: '24px',
                    backgroundColor: '#ff9800',
                    color: '#ffffff',
                    paddingX: 3,
                    paddingY: 1.5,
                    '&:hover': {
                      backgroundColor: '#fb8c00',
                    },
                  }}
                >
                  他の人に質問する
                </Button>
              </Box>
            </Box>
          )}

          {/* エラーメッセージ */}
          {errorMessage && (
            <Typography color="error" sx={{ px: 2, py: 1 }}>
              {errorMessage}
            </Typography>
          )}

          {/* 削除確認ダイアログ */}
          <Dialog
            open={confirmDeleteOpen}
            onClose={handleConfirmDeleteClose}
            aria-labelledby="confirm-delete-dialog-title"
            aria-describedby="confirm-delete-dialog-description"
          >
            <DialogTitle id="confirm-delete-dialog-title">削除の確認</DialogTitle>
            <DialogContent>
              <DialogContentText id="confirm-delete-dialog-description">
                本当に削除してもよろしいですか？
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleConfirmDeleteClose} color="primary">
                いいえ
              </Button>
              <Button onClick={handleConfirmDelete} color="primary" autoFocus>
                はい
              </Button>
            </DialogActions>
          </Dialog>

          {/* ラベル有無のダイアログ */}
          <Dialog
            open={labelMissingDialogOpen}
            onClose={() => setLabelMissingDialogOpen(false)}
            aria-labelledby="label-missing-dialog-title"
            aria-describedby="label-missing-dialog-description"
          >
            <DialogTitle id="label-missing-dialog-title">ラベルが表示されていません</DialogTitle>
            <DialogContent>
              <DialogContentText id="label-missing-dialog-description">
                このモデルは使えません。管理者にご連絡ください。
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setLabelMissingDialogOpen(false)} color="primary">
                閉じる
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </Box> 
      {/* 設定ドロワー */}
      {renderSettingsDrawer()}
    </div>
  );
};

export default ChatWindow;
