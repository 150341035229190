import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  Button,
  CircularProgress,
  Avatar,
  TextField,
  InputAdornment,
} from '@mui/material';
import {
  Send as SendIcon,
  SkipNext as SkipNextIcon,
} from '@mui/icons-material';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { useCookies } from "react-cookie";
import Consent from '../consent/Consent';
import { Person as PersonIcon } from '@mui/icons-material';
import { marked } from 'marked';

const StartChat = () => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([{ isUser: false, text: "以下から選択してください" }]);
  const [choices, setChoices] = useState(["チャットで聞きたい", "マニュアルをもとに聞きたい", "蓄積されたQAをもとに聞きたい"]);
  const [additionalChoices, setAdditionalChoices] = useState([]);
  const [hasHistory, setHasHistory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'refresh_token', 'consent']);
  const [userId, setUserId] = useState(null);
  const [consentGiven, setConsentGiven] = useState(false);
  const [showConsentModal, setShowConsentModal] = useState(false);
  const [lastUserMessage, setLastUserMessage] = useState('');
  const [userData, setUserData] = useState({
    id: '',
    password: '',
    icon: '',
    name: '',
    affiliation: '',
    skill: '',
    role: '',
  });
  const [showInitialScreen, setShowInitialScreen] = useState(true);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [showResolutionOptions, setShowResolutionOptions] = useState(false);
  const [showFurtherOptions, setShowFurtherOptions] = useState(false);
  const [isQuestionInput, setIsQuestionInput] = useState(false);
  const [questionContent, setQuestionContent] = useState('');

  // ユーザーの同意状況を確認
  const checkConsentStatus = async () => {
    const consentCookie = cookies.consent;
    if (consentCookie) {
      const lastConsentDate = new Date(consentCookie);
      const thirtyDaysAgo = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000);
      if (lastConsentDate < thirtyDaysAgo) {
        setShowConsentModal(true);
      } else {
        setConsentGiven(true);
      }
    } else {
      setShowConsentModal(true);
    }
  };

  // 同意を保存
  const handleConsent = async () => {
    setCookie('consent', new Date().toISOString(), { path: '/' });
    setConsentGiven(true);
    setShowConsentModal(false);
  };

  // ユーザーデータを取得
  useEffect(() => {
    async function fetchData() {
      // 7文字のランダムな文字列を生成
      function generateRandomString(length) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        return result;
      }

      // 暗号化されたものを復号化（CryptoJS使用）
      function aesDecryptCryptoJS(encryptedData, keyStr, ivStr) {
        const keyWordArray = CryptoJS.enc.Base64.parse(keyStr);
        const ivWordArray = CryptoJS.enc.Base64.parse(ivStr);

        const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, keyWordArray, {
          iv: ivWordArray,
          mode: CryptoJS.mode.CBC,
          padding: CryptoJS.pad.Pkcs7
        });

        const decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
        return decryptedText;
      }

      // ユーザーデータを取得
      if (location.state && location.state.userId) {
        // location.stateが存在する場合は、その値を使用
        setUserId(location.state.userId);
        checkConsentStatus();
      } else {
        // URLからemployeeNumberのクエリパラメータを取得
        const queryString = new URLSearchParams(window.location.search);
        const keyBase64 = btoa('12345678901234567890123456789012');
        const encodedEncryptedEmployeeNumber = decodeURIComponent(queryString.get('employeeNumber'));
        const ivBase64 = decodeURIComponent(queryString.get('iv'));

        // 両方のパラメータが存在しない場合、エラーページに遷移
        if (!encodedEncryptedEmployeeNumber && !ivBase64) {
          console.log('クエリパラメータが存在しません');
          navigate('/authfailure');
          return;
        }

        // CryptoJSの復号化関数を使用してemployeeNumberFromParamを取得
        const employeeNumberFromParam = aesDecryptCryptoJS(encodedEncryptedEmployeeNumber, keyBase64, ivBase64);
        console.log('Decrypted text:', employeeNumberFromParam);
        try {
          // クエリパラメータが存在しない場合は、サーバーに問い合わせる
          const response = await axios.post(
            '/backend/getcookie',
            {
              employeeNumber: employeeNumberFromParam,
              withCredentials: true
            });
          console.log(response);
          setCookie("token", response.data.access_token);
          setCookie("refresh_token", response.data.refresh_token);

          if (response.data.status === 'exists') {
            setUserId(response.data.userId);
            checkConsentStatus();
          }
        } catch (error) {
          if (error.response && error.response.status === 404) {
            // 7文字のランダムな文字列を生成
            const randomPassword = generateRandomString(7);
            let userData = {
              id: employeeNumberFromParam,
              password: randomPassword,
              icon: 'null',
              name: '',
              affiliation: '',
              skill: '',
              skill_level: 5
            };

            // ユーザーデータを登録
            const res = await axios.post(
              `/backend/adduserdata`,
              {
                userData: userData,
              }, {
              withCredentials: true
            });
            console.log(res);

            // トップページにで必要な情報を取得
            const resTop = await axios.post(
              `/backend/totop`,
              {
                id: userData.id,
              }, {
              withCredentials: true
            });
            console.log(resTop);
            setCookie("token", resTop.data.access_token);
            setCookie("refresh_token", resTop.data.refresh_token);

            // 通知情報を取得
            const resNotification = await axios.post(`/backend/getnotifications`, {
              user_id: userData.id,
              withCredentials: true
            });
            console.log(resNotification);
            const notificationsData = resNotification.data;
            const notificationCountData = notificationsData.length;

            // ユーザーページに遷移
            navigate('/user', {
              state: {
                userId: userData.id,
                notificationCount: notificationCountData,
                notifications: notificationsData
              }
            });
            checkConsentStatus();
          } else {
            console.error('Error fetching data: ', error);
            navigate('/authfailure');
          }
        }
      }
    }
    fetchData();
  }, [location, navigate, setCookie]);

  // ユーザーデータを取得するための useEffect を追加
  useEffect(() => {
    console.log('ユーザーデータを取得します');
    async function fetchUserData() {
      console.log('userId:', userId);
      try {
        if (!userId) {
          return;
        }
        const res = await axios.post(`/backend/getuserdata`, {
          user_id: userId,
          withCredentials: true,
        });
        console.log(res);
        setUserData(res.data.user);
      } catch (error) {
        console.error('ユーザーデータの取得中にエラーが発生しました:', error);
      }
    }

    // ユーザーの同意が得られた場合のみユーザーデータを取得
    if (consentGiven) {
      fetchUserData();
    }
  }, [consentGiven, userId]);

  // メッセージが更新されたらスクロールを最下部に移動
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // 通知を取得
  useEffect(() => {
    console.log('通知を取得します');
    async function fetchNotification() {
        try {
            if (!userId) {
                return;
            }
            const res = await axios.post(`/backend/getnotifications`, {
                user_id: userId,
                withCredentials: true
            });
            console.log(res);
            setNotifications(res.data);
            setNotificationCount(res.data.length);
        } catch (error) {
            console.error('通知の取得中にエラーが発生しました:', error);
        }
    }

    // ユーザーの同意が得られた場合のみ通知を取得
    if (consentGiven) {
        fetchNotification();
    }
  }, [consentGiven, userId]);

  // メッセージを送信したときの処理
  const handleSendMessage = async () => {
    // ローディング開始
    setIsLoading(true);
    setLastUserMessage(message);

    // 質問IDを初期化
    let questionId = null;

    // メッセージが空の場合は何もしない
    if (message.trim() !== '') {
      // isQuestionInputがtrueの場合、質問内容を取得して質問ページに遷移
      if (isQuestionInput) {
        setQuestionContent(message);
        navigate('/question', {
          state: {
            userId,
            questionContent: message,
            notificationCount,
            notifications,
            userData,
          },
        });
        return;
      }

      // メッセージリストに追加
      const updatedMessages = [...messages, { isUser: true, text: message }];
      setMessages(updatedMessages);
      setHasHistory(true);

      try {
        // メッセージが選択肢でないときだけ、質問IDを更新
        if (messages[1] && (messages[1].text === '通常のChatGPTに質問する' || messages[1].text === '社内文書用ChatGPTに質問する')) {
          console.log('メッセージをサーバーに送信して保存:', message);
          const res = await axios.post(`/backend/save_chat_question`, {
            userId: userId,
            text: message,
            withCredentials: true
          });
          questionId = res.data.id;
        }

        // 新たなリクエストを作成して、設定やメッセージをバックエンドに送る
        let chatRes;

        // 最新の特定のメッセージを探す
        const specialMessages = ["通常のChatGPTに質問する", "社内文書用ChatGPTに質問する"];
        let latestMessage = messages.slice().reverse().find(msg => specialMessages.includes(msg.text));

        // メッセージが見つからない場合、デフォルトの処理を行う
        if (!latestMessage) {
          latestMessage = { text: '' };
        } else {
          latestMessage = latestMessage.text;
        }
        const messageType = latestMessage === '社内文書用ChatGPTに質問する' ? 'internal' : 'standard';

        // ChatGPTへ質問を送信
        if (latestMessage === '通常のChatGPTに質問する') {
          console.log('通常のChatGPTに質問します');

          // メッセージリストから特定のメッセージと最新のメッセージを除外
          const filteredMessages = updatedMessages.filter((msg, index) => {
            return msg.text !== "以下から選択してください" &&
              msg.text !== "通常のChatGPTに質問する" &&
              index !== updatedMessages.length - 1;
          });
          console.log(filteredMessages.slice(-15));

          // 通常のChatGPTへ質問を送信
          chatRes = await axios.post(`/backend/send_chat_question`, {
            userId: userId,
            questionId: questionId,
            settings: { model: 'gpt-4o-mini', historyCount: 0, temperature: 1.0 },
            text: message,
            messages: filteredMessages.slice(-15),
            withCredentials: true
          });
        } else if (latestMessage === '社内文書用ChatGPTに質問する') {
          console.log('社内文書用ChatGPTに質問します');

          // メッセージリストから特定のメッセージと最新のメッセージを除外
          const filteredMessages = updatedMessages.filter((msg, index) => {
            return msg.text !== "以下から選択してください" &&
              msg.text !== "社内文書用ChatGPTに質問する" &&
              index !== updatedMessages.length - 1;
          });
          console.log(filteredMessages.slice(-15));

          // 社内文書用ChatGPTへ質問を送信
          chatRes = await axios.post(`/backend/send_chat_question_internal_documents`, {
            userId: userId,
            questionId: questionId,
            settings: { model: 'gpt-4o-mini', historyCount: 0, temperature: 1.0 },
            text: message,
            messages: filteredMessages.slice(-15),
            withCredentials: true
          });
        }

        // レスポンスをチェックして、メッセージリストとCookieに追加
        if (chatRes.status === 200) {
          // レスポンスからメッセージを取得
          const newMessage = { isUser: false, text: chatRes.data, type: messageType };

          // メッセージリストに追加
          setMessages(prevMessages => [...prevMessages, newMessage]);

          // 最新の15メッセージのみを保持する
          const messagesToStore = [...updatedMessages, newMessage].slice(-15);

          // メッセージをCookieに保存
          document.cookie = `messages=${JSON.stringify(messagesToStore)}; path=/`;

          // 「問題は解決しましたか？」を表示
          setShowResolutionOptions(true);
        } else {
          setErrorMessage('エラーが発生しました。もう一度質問してください。');
          console.error('メッセージの受信中にエラーが発生しました:', chatRes.status);
        }
      } catch (error) {
        if (error.response && error.response.status === 504) {
          setErrorMessage("サーバーからの応答がタイムアウトしました。しばらくしてから再試行してください。");
        } else {
          setErrorMessage("メッセージの送信中に未知のエラーが発生しました。再試行してください。");
        }
        console.error('メッセージの送信中にエラーが発生しました:', error);
      }
      // 入力フィールドをクリア
      setMessage('');
      setIsLoading(false); // ローディング終了
    }
  };

  // ファイルをダウンロード
  const handleFileDownload = async (filename) => {
    console.log(`Attempting to download file: ${filename}`);
    try {
      const res = await axios.post('/backend/download_pdf_file', {
        uniqueFileName: filename
      }, { responseType: 'blob' });

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Download error:', error);
      alert('ファイルのダウンロードに失敗しました。');
    }
  };

  // コンテンツを描画
  const renderMessageContent = (text) => {
    const sourcePrefix = "ソース:";
    const pagePrefix = "ページ:";
    let formattedText = text;
    let lastIndex = 0;
    const elements = [];

    // 複数の参照を処理するためのループ
    while (formattedText.includes(sourcePrefix, lastIndex) && formattedText.includes(pagePrefix, lastIndex)) {
      const sourceIndex = formattedText.indexOf(sourcePrefix, lastIndex) + sourcePrefix.length;
      const pageIndex = formattedText.indexOf(pagePrefix, lastIndex);
      const filename = formattedText.substring(sourceIndex, pageIndex).trim();

      // ソースまでのテキストを追加
      elements.push(formattedText.substring(lastIndex, formattedText.indexOf(sourcePrefix, lastIndex)));

      // リンク部分を追加
      if (/^[\w,\s-]+\.[A-Za-z]{3}$/.test(filename)) {
        // ファイル名が `test_stres.pdf` のような形式の場合はリンクとして扱う
        elements.push(
          <a href="#" onClick={(e) => {
            e.preventDefault();
            handleFileDownload(filename);
          }} style={{ color: 'blue', textDecoration: 'underline' }}>
            {filename}
          </a>
        );
      } else {
        // それ以外の形式の場合は普通のテキストとして扱う
        elements.push(
          <span>
            {filename}
          </span>
        );
      }

      // ページ情報を次の行に表示
      elements.push(
        <br />,
        formattedText.substring(pageIndex, formattedText.indexOf('\n', pageIndex) + 1)
      );
      lastIndex = formattedText.indexOf('\n', pageIndex) + 1;  // 次の行から再開
    }

    // 最後のテキスト部分を追加
    elements.push(formattedText.substring(lastIndex));

    return <>{elements}</>;
  };

  // 「問題は解決しましたか？」の選択肢のハンドラー
  const handleResolutionChoice = (choice) => {
    if (choice === 'はい') {
      // トップページに遷移することを通知
      setMessages((prevMessages) => [
        ...prevMessages,
        { isUser: false, text: 'お役に立てて良かったです！トップページに移動します。' },
      ]);

      // 少し待ってからトップ画面に遷移
      setTimeout(() => {
        moveTop();
      }, 2000); // 2秒後に遷移
    } else {
      // 「チャットで質問する」または「他の人に質問する」の選択肢を表示
      setShowResolutionOptions(false);
      setShowFurtherOptions(true);
    }
  };

  // 「どうしますか？」の選択肢のハンドラー
  const handleFurtherChoice = (choice) => {
    if (choice === 'チャットで質問する') {
      // チャットを続ける
      setShowFurtherOptions(false);
    } else {
      // 「他の人に質問する」が選ばれた場合、`lastUserMessage`を`questionContent`にセットして質問ページに遷移
      setShowFurtherOptions(false);
      setQuestionContent(lastUserMessage);
      navigate('/question', {
        state: {
          userId,
          questionContent: lastUserMessage,
          notificationCount,
          notifications,
          userData,
        },
      });
    }
  };

  // クッキーから設定を読み込む
  function getCookie(name) {
    // "name=value"形式の文字列の配列を取得
    const cookieArray = document.cookie.split('; ');
    // 指定した名前のクッキーを探す
    for (let cookieString of cookieArray) {
      const [cookieName, cookieValue] = cookieString.split('=');
      if (cookieName === name) {
        return cookieValue;
      }
    }
    return null;
  }

  // 履歴を削除したときの処理
  function handleDeleteHistory() {
    // メッセージリストをクリア
    setMessages([]);
    // 履歴が削除されたので、hasHistoryをfalseに設定
    setHasHistory(false);
    // Cookieからメッセージの履歴を削除
    document.cookie = "messages=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // ChatWindowを初期状態に戻す
    setMessages([{ isUser: false, text: "以下から選択してください" }]);
    setChoices([]);
    setAdditionalChoices([]);
    setChoices(["チャットで聞きたい", "マニュアルをもとに聞きたい", "蓄積されたQAをもとに聞きたい"]);
  }

  // 選択肢を選択したときの処理
  function handleChoice(choice) {
    setMessages([...messages, { isUser: true, text: choice }]);
    if (choice === "チャットで聞きたい") {
      // 選択肢を更新
      setAdditionalChoices(["通常のChatGPTに質問する", "社内文書用ChatGPTに質問する"]);
      setChoices([]);
    } else {
      // 選択肢をクリア
      setAdditionalChoices([]);
      setChoices(null);
    }
  }

  // トップ画面に戻る
  const moveTop = async () => {
    if (cookies.token !== "") {
      console.log('トップ画面に戻ります')
      try {
        const res = await axios.get(`/backend/me`, {
          data: {},
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + cookies.token,
          },
        });

        console.log(res);
        if (res.data.login_ok === "login_ok") {
          console.log(res.data);
          console.log('トップ画面に戻ります')
          navigate("/top", {
            state: {
              userId: res.data.id,
              userPassword: res.data.password,
            },
          });
        }
      } catch (error) {
        // トークンの有効期限が切れている場合、リフレッシュトークンを再取得する
        if (error.response.data.msg === "Token has expired") {
          console.log('トークンの有効期限が切れています');
          console.log('リフレッシュトークンを再取得します');
          try {
            const res = await axios.get(`/backend/refresh`, {
              data: {},
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + cookies.refresh_token,
              },
            });

            console.log(res);
            removeCookie("token");
            setCookie("token", res.data.access_token);

            // 再取得したトークンでtop画面に戻る
            const updatedToken = res.data.access_token;
            await new Promise((resolve) => setTimeout(resolve, 0));
            console.log('トップ画面に戻ります')
            const res2 = await axios.get(`/backend/me`, {
              data: {},
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + updatedToken,
              },
            });

            console.log(res2);
            if (res2.data.login_ok === "login_ok") {
              console.log('トップ画面に戻ります')
              navigate("/top", {
                state: {
                  userId: res2.data.id,
                  userPassword: res2.data.password,
                },
              });
            }
          } catch (error) {
            console.error(error);
          }
        }
      }
    }
  };

  // ユーザーの同意が得られた場合のみ画面を表示
  if (showConsentModal) {
    return <Consent onConsent={handleConsent} />;
  }

  // 初期表示画面
  if (showInitialScreen) {
    return (
      <Box
        sx={{
          height: '100vh',
          background: 'linear-gradient(to bottom, #e0f7fa, #80deea)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
          animation: 'fadeIn 1s forwards',
        }}
      >
        {/* アニメーションのためのスタイルを追加 */}
        <style>
          {`
            @keyframes fadeIn {
              from { opacity: 0; }
              to { opacity: 1; }
            }
            @keyframes float {
              0% {
                transform: translatey(0px);
              }
              50% {
                transform: translatey(-20px);
              }
              100% {
                transform: translatey(0px);
              }
            }
            @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap');
          `}
        </style>
        <img
          src="character.png"
          alt="character"
          style={{
            width: '200px',
            height: 'auto',
            marginBottom: '20px',
            animation: 'float 3s ease-in-out infinite',
          }}
        />
        <Typography
          variant="h4"
          sx={{
            marginBottom: '20px',
            fontFamily: '"Noto Sans JP", sans-serif',
            color: '#00695c',
            fontWeight: '500',
          }}
        >
          まずはBISくんに聞いてみよう!
        </Typography>
        {/* ボタンを追加 */}
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowInitialScreen(false)}
          sx={{
            fontWeight: 'bold',
            fontSize: '1.2rem',
            padding: '12px 24px',
            borderRadius: '30px',
            textTransform: 'none',
            boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
          }}
        >
          はじめる
        </Button>
      </Box>
    );
  }

  return (
    <div>
      {/* チャットウィンドウ */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          position: 'relative',
          background: 'linear-gradient(to bottom, #f0f0f0, #d9d9d9)',
        }}
      >
        {/* スキップボタンをモダンなデザインに修正 */}
        <Button
          variant="contained"
          color="secondary"
          startIcon={<SkipNextIcon />}
          onClick={moveTop}
          sx={{
            position: 'absolute',
            top: 16,
            right: 16,
            zIndex: 1,
            backgroundColor: '#ff5252',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#ff1744',
            },
            fontWeight: 'bold',
            textTransform: 'none',
            borderRadius: '20px',
            padding: '6px 16px',
          }}
        >
          スキップ
        </Button>

        {/* アニメーションのためのスタイルを追加 */}
        <style>
          {`
            @keyframes fadeIn {
              from { opacity: 0; }
              to { opacity: 1; }
            }
            @keyframes float {
              0% {
                transform: translatey(0px);
              }
              50% {
                transform: translatey(-20px);
              }
              100% {
                transform: translatey(0px);
              }
            }
            @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@500&display=swap');
          `}
        </style>

        {/* メッセージ表示エリア */}
        <Box
          sx={{
            flex: 1,
            overflow: 'auto',
            position: 'relative',
            px: 2,
            py: 2,
            backgroundColor: 'transparent',
          }}
        >
          {isLoading && (
            <Box
              sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 9999,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                borderRadius: '10px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
              }}
            >
              <CircularProgress size={60} />
              <Typography variant="subtitle1" sx={{ marginTop: '20px', fontWeight: 'bold' }}>
                処理中です、しばらくお待ちください...
              </Typography>
            </Box>
          )}
          <List>
            {messages.map((msg, index) => (
              <ListItem
                key={index}
                sx={{
                  justifyContent: msg.isUser ? 'flex-end' : 'flex-start',
                  marginBottom: '8px',
                  alignItems: 'flex-start',
                }}
              >
                {!msg.isUser && (
                  // キャラクター画像を表示
                  <img
                    src="character.png"
                    alt="character"
                    style={{
                      width: '40px',
                      height: 'auto',
                      marginRight: '8px',
                      cursor: 'pointer',
                      transition: 'transform 0.3s ease',
                      objectFit: 'contain',
                      objectPosition: 'center',
                    }}
                    onClick={moveTop}
                    onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                    onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                  />
                )}
              <Box
                sx={{
                  position: 'relative',
                  backgroundColor: msg.isUser ? '#81c784' : '#ffffff',
                  borderRadius: '12px',
                  maxWidth: '60%',
                  padding: '8px 16px',
                  boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
                  wordBreak: 'break-word',
                  overflowWrap: 'break-word',
                  display: 'inline-block',
                }}
              >
                {msg.type === 'internal' ? (
                  <div style={{ whiteSpace: 'pre-wrap', lineHeight: '1.2' }}>
                    {renderMessageContent(msg.text)}
                  </div>
                ) : (
                  <div
                    style={{
                      whiteSpace: 'pre-wrap',
                      lineHeight: '1.2',
                      margin: 0,
                      padding: '4px 0',
                      display: 'table',
                      verticalAlign: 'middle',
                    }}
                    dangerouslySetInnerHTML={{
                      __html: marked(msg.text.trim()),
                    }}
                  />
                )}
              </Box>
                {msg.isUser && (
                  <Avatar
                    alt="User"
                    sx={{ width: 40, height: '40', marginLeft: '8px', backgroundColor: '#2196f3' }}
                  >
                    <PersonIcon style={{ color: '#ffffff' }} />
                  </Avatar>
                )}
              </ListItem>
            ))}
            <div ref={messagesEndRef} />
          </List>
        </Box>

        {/* メッセージ入力エリアまたは選択肢を表示 */}
        {(!choices && !showResolutionOptions && !showFurtherOptions) || isQuestionInput ? (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: 2,
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              borderTop: '1px solid #ccc',
            }}
          >
            <TextField
              variant="outlined"
              placeholder="メッセージを入力してください"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              fullWidth
              multiline
              maxRows={4}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleSendMessage}
                      sx={{
                        bgcolor: '#03a9f4',
                        '&:hover': { bgcolor: '#0288d1' },
                        color: '#ffffff',
                        marginLeft: '8px',
                      }}
                    >
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: '30px',
                },
              }}
            />
          </Box>
        ) : showResolutionOptions ? (
          // 「問題は解決しましたか？」の選択肢を表示
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 2,
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              borderTop: '1px solid #ccc',
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              問題は解決しましたか？
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <Button
                variant="contained"
                onClick={() => handleResolutionChoice('はい')}
                sx={{
                  m: 1,
                  textTransform: 'none',
                  fontWeight: 'bold',
                  borderRadius: '30px',
                  backgroundColor: '#4caf50',
                  color: '#ffffff',
                  '&:hover': {
                    backgroundColor: '#388e3c',
                  },
                }}
              >
                はい
              </Button>
              <Button
                variant="contained"
                onClick={() => handleResolutionChoice('いいえ')}
                sx={{
                  m: 1,
                  textTransform: 'none',
                  fontWeight: 'bold',
                  borderRadius: '30px',
                  backgroundColor: '#f44336',
                  color: '#ffffff',
                  '&:hover': {
                    backgroundColor: '#d32f2f',
                  },
                }}
              >
                いいえ
              </Button>
            </Box>
          </Box>
        ) : showFurtherOptions ? (
          // 「どうしますか？」の選択肢を表示
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 2,
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              borderTop: '1px solid #ccc',
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              どうしますか？
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <Button
                variant="contained"
                onClick={() => handleFurtherChoice('チャットで質問する')}
                sx={{
                  m: 1,
                  textTransform: 'none',
                  fontWeight: 'bold',
                  borderRadius: '30px',
                  backgroundColor: '#03a9f4',
                  color: '#ffffff',
                  '&:hover': {
                    backgroundColor: '#0288d1',
                  },
                }}
              >
                チャットで質問する
              </Button>
              <Button
                variant="contained"
                onClick={() => handleFurtherChoice('他の人に質問する')}
                sx={{
                  m: 1,
                  textTransform: 'none',
                  fontWeight: 'bold',
                  borderRadius: '30px',
                  backgroundColor: '#ff9800',
                  color: '#ffffff',
                  '&:hover': {
                    backgroundColor: '#fb8c00',
                  },
                }}
              >
                他の人に質問する
              </Button>
            </Box>
          </Box>
        ) : (
          // 選択肢を表示
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 2,
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              borderTop: '1px solid #ccc',
            }}
          >
            {choices.map((choice, index) => (
              <Button
                key={index}
                variant="contained"
                onClick={() => handleChoice(choice)}
                disabled={choice === "蓄積されたQAをもとに聞きたい" || choice === "マニュアルをもとに聞きたい"}
                sx={{
                  m: 1,
                  p: 1,
                  textTransform: 'none',
                  height: '50px',
                  fontWeight: 'bold',
                  borderRadius: '30px',
                  backgroundColor: '#03a9f4',
                  color: '#ffffff',
                  '&:hover': {
                    backgroundColor: '#0288d1',
                  },
                  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                }}
              >
                {choice}
              </Button>
            ))}
            {additionalChoices.map((choice, index) => (
              <Button
                key={index}
                variant="contained"
                onClick={() => handleChoice(choice)}
                sx={{
                  m: 1,
                  p: 1,
                  textTransform: 'none',
                  height: '50px',
                  fontWeight: 'bold',
                  borderRadius: '30px',
                  backgroundColor: '#03a9f4',
                  color: '#ffffff',
                  '&:hover': {
                    backgroundColor: '#0288d1',
                  },
                  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                }}
              >
                {choice}
              </Button>
            ))}
          </Box>
        )}
        {errorMessage && (
          <Typography color="error" sx={{ px: 2, py: 1 }}>
            {errorMessage}
          </Typography>
        )}
      </Box>
    </div>
  );
};

export default StartChat;