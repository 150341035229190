const logLevels = {
  DEBUG: 'DEBUG',
  INFO: 'INFO',
  WARN: 'WARN',
  ERROR: 'ERROR'
};

class CustomLogger {
  log(level, message) {
    const timestamp = new Date().toISOString();
    console.log(`[${timestamp}][${level}] ${message}`);
  }

  debug(message) {
    this.log(logLevels.DEBUG, message);
  }

  info(message) {
    this.log(logLevels.INFO, message);
  }

  warn(message) {
    this.log(logLevels.WARN, message);
  }

  error(message) {
    this.log(logLevels.ERROR, message);
  }
}
export default CustomLogger;
