export const skills = [
    {
        category: "機械設計基礎",
        skills: ["工業材料", "機械製図", "材料力学", "機械工学"]
    },
    {
        category: "CADソフトウェア",
        skills: ["2次元CAD", "3次元CAD(CATIA)", "3次元CAD(Siemens NX)", "3次元CAD(SolidWorks)", "3次元CAD(Autodesk Inventor)", "3次元CAD(Creo(旧Pro/ENGINEER))"]
    },
    {
        category: "CAE",
        skills: ["応力解析", "熱解析", "流体解析", "構造最適化", "動力学解析", "電磁場解析"]
    },
    {
        category: "設計",
        skills: ["部品設計", "3次元CADアセンブリ設計", "製品設計", "機械要素設計", "治工具設計", "射出成金型設計", "プレス金型設計"]
    },
    {
        category: "制御",
        skills: ["油空圧機器", "油圧制御", "空圧制御", "制御理論", "制御シミュレーション", "制御計の設計・実装"]
    },
    {
        category: "電気回路設計",
        skills: ["電気回路の設計", "配線", "回路図作成"]
    },
    {
        category: "電子デバイス",
        skills: ["電子部品やデバイスの特性", "選定", "組み込み"]
    },
    {
        category: "電力電子",
        skills: ["電力変換", "電力制御"]
    },
    {
        category: "デジタル信号処理",
        skills: ["デジタル信号処理", "デジタル信号フィルタリング", "デジタル信号変換"]
    },
    {
        category: "プログラミング言語",
        skills: ["Java", "C", "C#", "C++", "Python", "PHP", "HTML"]
    },
    {
        category: "サーバー",
        skills: ["サーバー管理", "サーバー設定", "サーバーセキュリティ"]
    },
    {
        category: "オペレーションシステム",
        skills: ["Windows", "UNIX", "MacOS"]
    },
    {
        category: "クラウド",
        skills: ["AWS (Amazon Web Services)", "Microsoft Azure", "Google Cloud Platform (GCP)", "IBM Cloud", "SalesForce"]
    },
    {
        category: "ソフトウェア",
        skills: ["ソフトウェア開発", "ソフトウェアテスト", "ソフトウェアデバッグ"]
    },
    {
        category: "データベース",
        skills: ["SQL", "MySQL", "Oracle", "PostgreSQL"]
    },
    {
        category: "ネットワーク",
        skills: ["ネットワーク設定", "ネットワークセキュリティ", "ネットワークトラブルシューティング"]
    },
    {
        category: "監視",
        skills: ["システム監視", "ネットワーク監視", "サーバー監視"]
    },
    {
        category: "バックアップ",
        skills: ["バックアップ設計", "バックアップ実行・管理"]
    },
    {
        category: "リストア",
        skills: ["データ復旧", "システムリストア"]
    },
    {
        category: "ジョブ管理",
        skills: ["ジョブ設計", "ジョブ管理"]
    },
    {
        category: "ログ管理",
        skills: ["ログファイルの監視", "ログ解析"]
    },
    {
        category: "セキュリティ",
        skills: ["セキュリティポリシーの設計", "脆弱性評価と対策", "ペネトレーションテスト"]
    },
    {
        category: "障害対応",
        skills: ["システム障害のトラブルシューティング", "障害対応プロセスの設計と実施"]
    },
    {
        category: "災害対策",
        skills: ["災対設計", "データバックアップ・リカバリ"]
    },
    {
        category: "マイクロコントローラ",
        skills: ["C", "C++", "Arduino", "Raspberry Pi"]
    },
    {
        category: "組み込みシステム開発",
        skills: ["デバイスドライバの開発", "リアルタイムオペレーティングシステム (RTOS) の使用", "センサーとのインタフェース開発", "バスプロトコル (I2C, SPI, UART) の使用"]
    },
    {
        category: "組み込みハードウェア設計",
        skills: ["ハードウェア記述言語 (Verilog, VHDL) の使用", "ハードウェアシミュレーションと検証", "ボードレベル回路設計"]
    },
    {
        category: "組み込みシステムテスト",
        skills: ["組み込みシステムのユニットテスト", "統合テスト", "パフォーマンステスト"]
    },
    {
        category: "組み込みセキュリティ",
        skills: ["デバイスセキュリティの設計と実装", "セキュアな通信プロトコルの使用", "脆弱性評価と対策"]
    }

];

