import { Button } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import axios from 'axios';
import { EditorState, convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import 'draft-js/dist/Draft.css';
import { React, useRef, useState } from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import { FormControlLabel, Checkbox } from "@mui/material";
import InputLabel from '@mui/material/InputLabel';

function CommentEditor({ userId, questionId, answer_id, replied_comment_id }) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [newFileName, setNewFileName] = useState(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [editorStateComments, seteditorStateComments] = useState(EditorState.createEmpty());
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
    const [isTextLongEnough, setIsTextLongEnough] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const fileInputRef = useRef(null);
    const [editorContent, setEditorContent] = useState('');
    const [plainTextContent, setPlainTextContent] = useState('');
    const [loadingMessage, setLoadingMessage] = useState("");
    const [isAnonymous, setIsAnonymous] = useState(false);

    const customButtonList = [
        ['undo', 'redo'],
        ['fontSize', 'formatBlock'],
        ['paragraphStyle', 'blockquote'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        ['fontColor', 'hiliteColor', 'textStyle'],
        ['removeFormat'],
        ['outdent', 'indent'],
        ['align', 'horizontalRule', 'list', 'lineHeight'],
        ['table', 'link', 'image'],
    ];

    const handleFileInputClick = () => {
        fileInputRef.current.click();
    };

    // react-draft-wysiwygで入力した内容を取得する関数
    const getContent = (editorState) => {
        const contentState = editorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const markup = stateToHTML(contentState);
        return {
            raw: JSON.stringify(rawContentState),
            markup: markup
        };
    }

    // HTMLからプレーンテキストを抽出する関数
    function extractTextFromHtml(html) {
        const tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = html;
        return tempDivElement.textContent || tempDivElement.innerText || "";
    }

    // エディタの内容を処理する関数
    const handleEditorChangeComments = (content) => {
        setEditorContent(content);

        // エディタ内の画像URLを抽出
        const editorImageUrls = content.match(/<img [^>]*src="([^"]+)"[^>]*>/g)?.map(imgTag => {
            const match = imgTag.match(/src="([^"]+)"/);
            return match ? match[1] : '';
        }) || [];

        console.log('エディタ内の画像URL:', editorImageUrls);

        // selectedImagesからエディタ内にない画像を削除
        setSelectedImages(prevImages => prevImages.filter(img => editorImageUrls.includes(img.url)));

        // HTMLからプレーンテキストを抽出
        const plainText = extractTextFromHtml(content);
        setPlainTextContent(plainText);

        // プレーンテキストの長さを確認し、ステートを更新
        setIsTextLongEnough(plainText.length >= 10);
    };
    // 画像を選択した時に実行される関数
    // 入力されたブロックをjsonとして受け取る
    const getTextBlocksAsJson = (editorState) => {
        const contentState = editorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const textBlocks = rawContentState.blocks.map(block => block.text);
        return textBlocks.join('\n'); // テキストブロックを改行で結合
    };

    // ファイル選択時のハンドラ
    const handleFileSelect = (event) => {
        // 選択された新しいファイルのリストを取得
        const newFilesArray = Array.from(event.target.files);
        console.log(newFilesArray);
        // 既存のファイルリストに新しいファイルを追加
        setSelectedFiles(prevFiles => [...prevFiles, ...newFilesArray]);
    };
    // ファイル削除時のハンドラ
    const handleRemoveFile = (fileName) => {
        setSelectedFiles(selectedFiles.filter(file => file.name !== fileName));
    };
    // ファイルリストの表示
    const renderFileList = () => {
        if (!selectedFiles || selectedFiles.length === 0) {
            return <div style={{ marginTop: '10px' }}>No files selected.</div>;
        }
        return (
            <ul style={{ listStyleType: 'none', padding: 0 }}>
                {selectedFiles.map((file, index) => (
                    <li key={index} style={{ margin: '10px 0', display: 'flex', alignItems: 'center' }}>
                        <span style={{ flexGrow: 1, marginRight: '10px' }}>{file.name}</span>
                        <button
                            onClick={() => handleRemoveFile(file.name)}
                            style={{
                                border: 'none',
                                background: '#990000',
                                color: 'white',
                                padding: '5px 10px',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s ease'
                            }}
                            onMouseOver={(e) => e.target.style.backgroundColor = '#bb3333'}
                            onMouseOut={(e) => e.target.style.backgroundColor = '#990000'}
                        >削除
                        </button>
                    </li>
                ))}
            </ul>
        );
    };

    // 入力された質問をサーバーを介してDBに保存する
    async function PostComments(editorStateComments) {
        setIsLoading(true);
        const { raw, markup } = getContent(editorStateComments);
        const textBlocksJson = getTextBlocksAsJson(editorStateComments);
        let FileName = ''
        let imageFiles = [];
        let otherFiles = [];

        console.log(raw)
        console.log(markup)
        console.log("Text blocks JSON:", textBlocksJson);
        console.log(userId);
        console.log(questionId);
        console.log(answer_id);
        console.log(replied_comment_id);

        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}${currentDate.getMonth() + 1}${currentDate.getDate()}${currentDate.getHours()}${currentDate.getMinutes()}${currentDate.getSeconds()}`;

        // 画像ファイルのアップロード
        setLoadingMessage("画像ファイルをアップロード中...");
        if (selectedImages && selectedImages.length > 0) {
            for (let imageObj of selectedImages) {
                const file = imageObj.file;
                const fileName = `${userId}_${formattedDate}_${file.name}`;
                const formData = new FormData();
                formData.append('file', file, fileName);

                try {
                    await axios.post(`/backend/uploadfile`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    });
                    imageFiles.push(fileName);
                } catch (error) {
                    console.error('Image upload failed:', error);
                }
            }
        }

        // その他のファイルのアップロード
        setLoadingMessage("その他のファイルをアップロード中...");
        if (selectedFiles && selectedFiles.length > 0) {
            for (let file of selectedFiles) {
                const fileName = `${userId}_${formattedDate}_${file.name}`;
                const formData = new FormData();
                formData.append('file', file, fileName);

                try {
                    await axios.post(`/backend/uploadfile`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    });
                    otherFiles.push(fileName);
                } catch (error) {
                    console.error('File upload failed:', error);
                }
            }
        }
        //DBにコメントを登録
        console.log('コメントを送信します...');
        setLoadingMessage("コメントをデータベースに登録中...");
        try {
            //ここで実際にDBから取得する
            const res = await axios.post(`/backend/comment`,
                {
                    userId: userId,
                    question_id: questionId,
                    replied_comment_id: replied_comment_id,
                    answer_id: answer_id,
                    comment: plainTextContent,
                    markup: editorContent,
                    fileName: FileName,
                    imageFiles: imageFiles,
                    otherFiles: otherFiles,
                    isAnonymous: isAnonymous,
                    withCredentials: true
                })
            console.log(res)
            // 画面を更新
            window.location.reload();
        } catch (error) {
            console.error('データ送信中にエラーが発生しました:', error);
            setErrorMessage("データ送信中にエラーが発生しました。再度試してください。");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            {isLoading ? (
                // ローディング中はこの部分を表示
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 2,
                    padding: '30px',
                    backgroundColor: '#f8f8f8',
                    borderRadius: '12px',
                    boxShadow: '0px 0px 15px rgba(0, 0, 0, 0.2)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <CircularProgress style={{ animationDuration: '550ms' }} />
                    <p style={{
                        marginTop: '15px',
                        fontSize: '18px',
                        fontWeight: '500',
                        color: '#333',
                        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                    }}>
                        {loadingMessage}
                    </p>
                </Box>

            ) : (
                <div>
                    <Box
                        sx={{
                            backgroundColor: 'white',
                            borderRadius: '12px',
                            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                            padding: '16px',
                            border: '1px solid #e0e0e0',
                            mt: 3,
                        }}
                    >
                        <InputLabel htmlFor="editor-content" sx={{ mb: 1, color: '#333', fontWeight: 'bold' }}>
                            コメント
                        </InputLabel>
                        <Typography variant="subtitle2" sx={{ mb: 3, color: '#555' }}>
                            コメントを記入してください。できるだけ詳しく書くことで、他のユーザーがより理解しやすくなります。
                        </Typography>
                        <SunEditor
                            setOptions={{
                                height: 200,
                                buttonList: customButtonList,
                            }}
                            onChange={handleEditorChangeComments}
                        />

                        <InputLabel htmlFor="file-upload" sx={{ mb: 1, mt: 3, color: '#333', fontWeight: 'bold' }}>
                            ファイルを選択
                        </InputLabel>
                        <Typography variant="subtitle2" sx={{ mb: 3, color: '#555' }}>
                            コメントに関連するファイルがある場合は、ここから選択してアップロードしてください。
                        </Typography>
                        <input
                            ref={fileInputRef}
                            type="file"
                            multiple
                            onChange={handleFileSelect}
                            accept=".pdf,.xls,.xlsx,.gif,.jpeg,.jpg,.png,.svg,.dwf,.dwg,.dxf,.doc,.docx,.ppt,.pptx,.txt"
                            style={{ display: 'none' }}
                        />
                        <Button
                            onClick={handleFileInputClick}
                            sx={{ mt: 2 }}
                        >
                            ファイルを選択
                        </Button>
                        <div sx={{ mt: 3 }}>{renderFileList()}</div>

                        <InputLabel htmlFor="tags" sx={{ mb: 1, mt: 3, color: '#333', fontWeight: 'bold' }}>
                            匿名でコメントする
                        </InputLabel>
                        <Typography variant="subtitle2" sx={{ mb: 3, color: '#555' }}>
                            匿名でコメントする場合はこちらにチェックを入れてください。匿名でコメントすると、あなたの名前は他のユーザーには表示されません。
                        </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={isAnonymous}
                                    onChange={(e) => setIsAnonymous(e.target.checked)}
                                    color="primary"
                                />
                            }
                            label="匿名でコメントする"
                        />

                        <Button
                            fullWidth
                            type="submit"
                            color="primary"
                            variant="contained"
                            size="large"
                            sx={{ my: 4 }}
                            onClick={() => PostComments(editorStateComments)}
                            disabled={isLoading || !isTextLongEnough}
                        >
                            {isLoading ? "送信中..." : "送信する"}
                        </Button>

                        {errorMessage && (
                            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                                {errorMessage}
                            </Typography>
                        )}

                        {!isTextLongEnough && (
                            <Typography color="error" variant="body2" sx={{ my: 2 }}>
                                本文を10文字以上入力してください。
                            </Typography>
                        )}
                    </Box>
                </div>
            )}
        </div>
    );
}

export default CommentEditor;
