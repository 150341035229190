import React, { useEffect, useState } from 'react';
import { useCookies } from "react-cookie";
import { BrowserRouter, Navigate, Route, Routes, useLocation } from "react-router-dom";
import Answer from './answer/Answer';
import Answers from './answers/Answers';
import AuthFailure from './authfailure/authfailure';
import Consent from './consent/Consent';
import Login from './login/Login';
import Maintenance from './maintenance/Maintenance';
import Management from './management/Management';
import Profile from './profile/Profile';
import Question from './question/Question';
import Questions from './questions/Questions';
import Register from './register/Register';
import Resolved from './resolved/Resolved';
import Searched from './searched/Searched';
import Solution from './solution/Solution';
import Top from './top/Top';
import User from './user/User';
import ChatWindow from './chatbot/ChatWindow';
import StartChat from'./top/StartChat';

const CheckMaintenance = ({ children }) => {
  const [isMaintenance, setIsMaintenance] = useState(false);
  const location = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    const checkMaintenanceStatus = async () => {
      try {
        const response = await fetch(`/backend/maintenance_status`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + (cookies.token || ''),
          },
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setIsMaintenance(data.maintenance);
      } catch (error) {
        console.error("There was a problem with the fetch operation:", error.message);
      }
    };
    checkMaintenanceStatus();
    const intervalId = setInterval(checkMaintenanceStatus, 3600000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  if (isMaintenance && location.pathname !== "/maintenance") {
    return <Navigate to="/maintenance" />;
  }

  return children;
};

const RedirectToGoogle = () => {
  useEffect(() => {
    window.location.href = 'https://www.google.com';
  }, []);

  return null;
};

const App = () => {
  return (
    <BrowserRouter>
      <CheckMaintenance>
        <Routes>
          <Route path="/" element={<RedirectToGoogle />} />
          <Route path="user" element={<User />} />
          <Route path="profile" element={<Profile />} />
          <Route path="top" element={<Top />} />
          <Route path="question" element={<Question />} />
          <Route path="questions" element={<Questions />} />
          <Route path="answer" element={<Answer />} />
          <Route path="answers" element={<Answers />} />
          <Route path="solution" element={<Solution />} />
          <Route path="resolved" element={<Resolved />} />
          <Route path="searched" element={<Searched />} />
          <Route path="maintenance" element={<Maintenance />} />
          <Route path="register" element={<Register />} />
          <Route path="authfailure" element={<AuthFailure />} />
          <Route path="management" element={<Management />} />
          <Route path="consent" element={<Consent />} />
          <Route path="chat" element={<ChatWindow />} />
          <Route path="startchat" element={<StartChat />} />
        </Routes>
      </CheckMaintenance>
    </BrowserRouter>
  );
};

export default App;
