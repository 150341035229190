import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import axios from 'axios';
import { React, useEffect, useState } from 'react';
import QuestionListItem from './QuestionListItem';

function QuestionsList({
    userId,
    selectedTab,
    setSelectedTab,
    questions,
    userData,
    notificationCount,
    notifications,
    selectedTags,
    category
}) {
    // ページネーションのためのステート
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // タブが変更されたら、選択されたタブを更新する
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    // タグに基づいてフィルタリング
    const [filteredQuestionsWithTags, setFilteredQuestionsWithTags] = useState(null);

    // タグを取得する
    useEffect(() => {
        if (!questions) {
            console.log("質問が取得されていません");
            return;
        }
        console.log("タグを取得します");

        async function fetchAndSetQuestionsWithTags() {
            // 質問のタグを取得
            console.log("質問のタグを取得します");
            const questionIds = questions.map(q => q.id);
            const res = await axios.post(`/backend/getbulktags`, {
                questionIds: questionIds,
                withCredentials: true
            });

            // 質問のタグをマップに変換
            const tagsMap = res.data;

            // 質問にタグを追加
            const resDataWithTags = questions.map(question => ({
                ...question,
                tags: tagsMap[question.id] || []
            }));

            // タグに基づいてフィルタリング
            let filteredResData;
            if (selectedTags.length === 0) {
                filteredResData = resDataWithTags;
            } else {
                filteredResData = resDataWithTags.filter(question =>
                    selectedTags.every(tag => question.tags && question.tags.includes(tag))
                );
            }

            setFilteredQuestionsWithTags(filteredResData);
        }

        fetchAndSetQuestionsWithTags();

        console.log("タグを取得しました");
        console.log(selectedTags);
    }, [questions, selectedTags]);

    // タブに基づいて質問をレンダリング
    function renderTabContent() {
        let filteredQuestions = filteredQuestionsWithTags || [];

        // タブに基づいてフィルタリング
        switch (selectedTab) {
            case 0:
                // すべての質問
                filteredQuestions = filteredQuestions.filter(
                    (question) => (
                        userData.role === 'admin' ||
                        question.is_deleted === false ||
                        question.user_id === userData.id
                    )
                );
                break;
            case 1:
                // 回答募集中
                filteredQuestions = filteredQuestions.filter(
                    (question) => question.resolved === false && (
                        userData.role === 'admin' ||
                        question.is_deleted === false ||
                        question.user_id === userData.id
                    )
                );
                break;
            case 2:
                // 最新の質問
                filteredQuestions = [...filteredQuestions]
                    .filter(question =>
                        userData.role === 'admin' ||
                        question.is_deleted === false ||
                        question.user_id === userData.id
                    )
                    .sort(
                        (a, b) => new Date(b.created_at) - new Date(a.created_at)
                    );
                break;
            default:
                return null;
        }

        // ページネーションのためのコンポーネント
        function PaginationControls() {
            const totalPages = Math.ceil(filteredQuestions.length / itemsPerPage);

            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', width: '100%' }}>

                    {currentPage > 1 &&
                        <button
                            style={{
                                backgroundColor: '#1976d2',
                                color: 'white',
                                padding: '10px 20px',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: '600',
                                transition: 'background-color 0.3s',
                                margin: '5px'
                            }}
                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#1565c0'}
                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#1976d2'}
                            onClick={() => setCurrentPage(currentPage - 1)}
                        >
                            前へ
                        </button>
                    }

                    {Array.from({ length: totalPages }).map((_, index) => (
                        <button
                            key={index}
                            style={{
                                backgroundColor: currentPage === (index + 1) ? '#1565c0' : '#1976d2',
                                color: 'white',
                                padding: '10px 20px',
                                border: currentPage === (index + 1) ? '2px solid #ffffff' : 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                fontSize: currentPage === (index + 1) ? '18px' : '16px',
                                fontWeight: currentPage === (index + 1) ? '700' : '600',
                                transition: 'background-color 0.3s',
                                margin: '5px',
                                boxShadow: currentPage === (index + 1) ? '0px 4px 6px rgba(0, 0, 0, 0.1)' : 'none'
                            }}
                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#1565c0'}
                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = currentPage === (index + 1) ? '#1565c0' : '#1976d2'}
                            onClick={() => setCurrentPage(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}

                    {currentPage < totalPages &&
                        <button
                            style={{
                                backgroundColor: '#1976d2',
                                color: 'white',
                                padding: '10px 20px',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: '600',
                                transition: 'background-color 0.3s',
                                margin: '5px'
                            }}
                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#1565c0'}
                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#1976d2'}
                            onClick={() => setCurrentPage(currentPage + 1)}
                        >
                            次へ
                        </button>
                    }
                </div>
            );
        }

        // 現在のページに応じてデータを分割
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = filteredQuestions.slice(indexOfFirstItem, indexOfLastItem);

        // 質問をレンダリング
        function renderQuestions() {
            if (currentItems) {
                return currentItems.map((question) => (
                    <QuestionListItem
                        key={question.id}
                        userId={userId}
                        question_id={question.id}
                        title={question.title}
                        is_deleted={question.is_deleted}
                        date={question.created_at}
                        userName={question.user_id}
                        resolved={question.resolved}
                        userData={userData}
                        notificationCount={notificationCount}
                        notifications={notifications}
                        category={category}
                        is_anonymous={question.is_anonymous}
                        tags={question.tags}
                    />
                ));
            } else {
                return null;
            }
        }
        return (
            <>
                {renderQuestions()}
                <PaginationControls />
            </>
        );
    }
    return (
        <>
            <Box sx={{ m: 0, mx: 'auto', p: 0, width: '100%' }}>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    style={{
                        fontSize: '20px',
                        backgroundColor: '#1976d2',
                        color: 'white',
                        margin: 0
                    }}
                >
                    <Tab label="すべての質問" style={{ fontSize: '90%' }} />
                    <Tab label="回答募集中" style={{ fontSize: '90%' }} />
                    <Tab label="最新の質問" style={{ fontSize: '90%' }} />
                </Tabs>
                <Grid
                    container
                    justifyContent="flex-start"
                    sx={{ width: '100%', margin: 0 }}
                >
                    {renderTabContent()}
                </Grid>
            </Box>
        </>
    );
}

export default QuestionsList;
