import PersonIcon from '@mui/icons-material/Person'; // これを追加
import {
    Avatar,
    Box,
    Chip,
    Grid,
    Typography
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useNavigate } from 'react-router-dom';

const SearchedListItem = ({ item, userData, userId, notificationCount, notifications }) => {
    const navigate = useNavigate();
    const [questioName, setQuestioNname] = useState([]);
    const [questionUserId, setQuestionUserId] = useState([]);
    const [questionUserIcon, setQuestionUserIcon] = useState([]);

    // スタイルを定義する
    const StyledPaper = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(2),
        maxWidth: 600,
        color: theme.palette.text.primary,
    }));
    // 日付をフォーマットする
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString([], options);
    };
    // HTMLタグを削除する
    const stripHtml = (html) => {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    };

    // 画面遷移処理
    const navigateToAnswer = (questionId, userName) => {
        console.log("回答画面に遷移します");
        navigate('/answer', {
            state: {
                userId: userId,
                questionId: questionId,
                notificationCount: notificationCount,
                notifications: notifications,
                userData: userData,
            },
            replace: true,
        });
    };

    // 画面遷移処理
    const navigateToResolved = (questionId, userName) => {
        console.log("解決画面に遷移します");
        navigate('/resolved', {
            state: {
                userId: userId,
                questionUserId: userName,
                questionId: questionId,
                notificationCount: notificationCount,
                notifications: notifications,
                userData: userData,
            },
            replace: true,
        });
    };

    // 　ユーザー情報を取得する
    useEffect(() => {
        console.log('ユーザー情報を取得する')
        async function getusers() {
            console.log(item)
            const res = await axios.post(
                `/backend/getusers`,
                {
                    user_id: item.user_id,
                    withCredentials: true
                }
            );
            console.log(res)
            setQuestioNname(res.data.name);
            setQuestionUserId(res.data.id);
            setQuestionUserIcon(res.data.icon);
        }
        getusers();
        return () => {
            console.log('画面がアンマウントされる、または再描画される前に呼び出される関数');
        };
    }, []);

    return (
        <Box sx={{ mx: 'auto', p: 2 }}>
            <StyledPaper
                sx={{
                    my: 0.5,
                    mx: 'auto',
                    p: 2,
                }}
            >
                <Grid container wrap="nowrap" spacing={1}>
                    <Grid item>
                    </Grid>
                    <Grid item xs zeroMinWidth>
                        <Typography
                            variant="h6"
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                                if (item.resolved) {
                                    navigateToResolved(item.id, item.user_id);
                                } else {
                                    navigateToAnswer(item.id, item.user_id);
                                }
                            }}
                            color="primary"
                        >
                            {item.title}
                            {item.resolved && (
                                <Chip label="解決済み" color="success" size="small" sx={{ ml: 1, mt: -1 }} />
                            )}

                        </Typography>

                        <Typography variant="body1">
                            {stripHtml(item.html_text).substring(0, 50)}...

                        </Typography>


                        <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: 3 }}>
                            <Grid item>
                                <Grid container direction="column" alignItems="flex-end">
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <Avatar sx={{ width: 24, height: 24 }}>
                                            {questionUserIcon !== "null"
                                                ? <img src={questionUserIcon} alt="User icon" style={{ height: 24, width: 24 }} />
                                                : <PersonIcon fontSize="small" />
                                            }
                                        </Avatar>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="body2" sx={{ ml: 1 }}>
                                            {questioName ? questioName : questionUserId}
                                        </Typography>
                                    </Grid>

                                    <Grid item>
                                        <Typography variant="body2" sx={{ ml: 2 }}>
                                            投稿日時: {formatDate(item.created_at)}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </StyledPaper>
        </Box >
    );
};

export default SearchedListItem;
