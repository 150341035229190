import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useState } from 'react';
import QuestionListItem from './QuestionListItem';

function QuestionsList({
    userId,
    selectedTab,
    setSelectedTab,
    questions,
    userData,
    notificationCount,
    notifications,
    category
}) {
    // ページネーションのためのステート
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    // タブが変更されたら、選択されたタブを更新する
    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue);
    };

    // タグに基づいてフィルタリング
    function renderTabContent() {
        let filteredQuestions = [];
        if (questions) {
            switch (selectedTab) {
                case 0:
                    // 回答募集中の質問を抽出
                    filteredQuestions = questions.filter(question =>
                        question.resolved === false &&
                        (userData.role === 'admin' ||
                            question.is_deleted === false ||
                            question.user_id === userData.id
                        )
                    );
                    break;
                case 1:
                    // あなたへの質問
                    const questionIdsInNotifications = notifications.map(notification => notification.question_id);
                    filteredQuestions = questions
                        .filter(question => {
                            // 管理者の場合、通知リストにある質問IDに対応するすべての質問を表示
                            if (userData.role === 'admin') {
                                return questionIdsInNotifications.includes(question.id);
                            }
                            // 一般ユーザーの場合、非表示になっていない質問でかつ通知リストにある質問のみを表示
                            else {
                                return !question.is_deleted && questionIdsInNotifications.includes(question.id);
                            }
                        })
                        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
                    break;
                default:
                    return null;

            }
        }

        // 現在のページに応じてデータを分割
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = filteredQuestions.slice(indexOfFirstItem, indexOfLastItem);

        // ページネーションのためのコンポーネント
        function PaginationControls() {
            const totalPages = Math.ceil(filteredQuestions.length / itemsPerPage);
            console.log(totalPages);

            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', width: '100%' }}>

                    {currentPage > 1 &&
                        <button
                            style={{
                                backgroundColor: '#1976d2',
                                color: 'white',
                                padding: '10px 20px',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: '600',
                                transition: 'background-color 0.3s',
                                margin: '5px'
                            }}
                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#1565c0'}
                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#1976d2'}
                            onClick={() => setCurrentPage(currentPage - 1)}
                        >
                            前へ
                        </button>
                    }

                    {Array.from({ length: totalPages }).map((_, index) => (
                        <button
                            key={index}
                            style={{
                                backgroundColor: currentPage === (index + 1) ? '#1565c0' : '#1976d2',
                                color: 'white',
                                padding: '10px 20px',
                                border: currentPage === (index + 1) ? '2px solid #ffffff' : 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                fontSize: currentPage === (index + 1) ? '18px' : '16px',
                                fontWeight: currentPage === (index + 1) ? '700' : '600',
                                transition: 'background-color 0.3s',
                                margin: '5px',
                                boxShadow: currentPage === (index + 1) ? '0px 4px 6px rgba(0, 0, 0, 0.1)' : 'none'
                            }}
                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#1565c0'}
                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = currentPage === (index + 1) ? '#1565c0' : '#1976d2'}
                            onClick={() => setCurrentPage(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}

                    {currentPage < totalPages &&
                        <button
                            style={{
                                backgroundColor: '#1976d2',
                                color: 'white',
                                padding: '10px 20px',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                fontSize: '16px',
                                fontWeight: '600',
                                transition: 'background-color 0.3s',
                                margin: '5px'
                            }}
                            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#1565c0'}
                            onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#1976d2'}
                            onClick={() => setCurrentPage(currentPage + 1)}
                        >
                            次へ
                        </button>
                    }
                </div>
            );
        }

        return (
            <>
                {currentItems.map((question) => (
                    <QuestionListItem
                        key={question.id}
                        userId={userId}
                        question_id={question.id}
                        title={question.title}
                        is_deleted={question.is_deleted}
                        date={question.created_at}
                        userName={question.user_id}
                        resolved={question.resolved}
                        userData={userData}
                        notificationCount={notificationCount}
                        notifications={notifications}
                        category={category}
                        is_anonymous={question.is_anonymous}
                    />
                ))}
                <PaginationControls />
            </>
        );
    }

    return (
        <>
            <Box sx={{ m: 0, mx: 'auto', p: 0, width: '100%' }}>
                <Tabs
                    value={selectedTab}
                    onChange={handleTabChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    style={{
                        fontSize: '20px',
                        backgroundColor: '#1976d2',
                        color: 'white',
                        margin: 0
                    }}
                >
                    <Tab label="回答募集中" style={{ fontSize: '90%' }} />
                    <Tab label="あなたへの質問" style={{ fontSize: '90%' }} />
                </Tabs>
                <Grid
                    container
                    justifyContent="flex-start"
                    sx={{ width: '100%', margin: 0 }}
                >
                    {renderTabContent()}
                </Grid>
            </Box>
        </>
    );
}

export default QuestionsList;
