import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Typography,
  Box,
  Card,
  CardContent,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';

const Categories = ({ onSelectedTagsChange }) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    async function fetchCategory() {
      const res = await axios.post('/backend/getcategories', {
        withCredentials: true,
      });
      setCategory(res.data);
    }
    fetchCategory();
  }, []);

  useEffect(() => {
    onSelectedTagsChange(selectedTags);
  }, [selectedTags]);

  const handleTagChange = (event) => {
    const tag = event.target.value;
    if (selectedTags.includes(tag)) {
      setSelectedTags((prevTags) => prevTags.filter((s) => s !== tag));
    } else {
      setSelectedTags((prevTags) => [...prevTags, tag]);
    }
  };

  const handleDelete = (tagToDelete) => () => {
    setSelectedTags((tags) => tags.filter((tag) => tag !== tagToDelete));
  };

  return (
    <Card
      variant="outlined"
      sx={{
        border: '1px solid #b0b0b0',
        '&:hover': { boxShadow: 3 }, 
        backgroundColor: '#fafafa', 
      }}
    >
      <CardContent>
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            color: '#444', 
            fontWeight: 'bold',
            borderBottom: '2px solid #b0b0b0', 
            paddingBottom: '5px',
          }}
        >
          カテゴリー
        </Typography>
        <Box sx={{ mb: 2 }}>
          {selectedTags.map((tag, index) => (
            <Chip
              label={tag}
              key={index}
              onDelete={handleDelete(tag)}
              sx={{
                m: 0.5,
                backgroundColor: '#d0d0d0', 
                color: '#222', 
                fontWeight: 'bold',
              }}
            />
          ))}
        </Box>
        {category ? (
          category.map((mainCategory) => (
            <Accordion
              key={mainCategory.main_category}
              defaultExpanded={false}
              sx={{
                border: '1px solid #b0b0b0', 
                backgroundColor: '#f2f2f2', 
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon sx={{ color: '#666' }} />}
                aria-controls={`${mainCategory.main_category}-content`}
                id={`${mainCategory.main_category}-header`}
                sx={{
                  minHeight: '40px',
                  '& .MuiAccordionSummary-content': { margin: 0 },
                  backgroundColor: '#e0e0e0',
                  color: '#333', 
                }}
              >
                <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                  {mainCategory.main_category}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {mainCategory.sub_categories.map((subCategory) => (
                  <Accordion
                    key={subCategory.sub_category}
                    defaultExpanded={false}
                    sx={{
                      border: '1px solid #b0b0b0',
                      backgroundColor: '#f7f7f7', 
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon sx={{ color: '#666' }} />}
                      aria-controls={`${subCategory.sub_category}-content`}
                      id={`${subCategory.sub_category}-header`}
                      sx={{
                        minHeight: '40px',
                        '& .MuiAccordionSummary-content': { margin: 0 },
                        backgroundColor: '#e8e8e8', 
                        color: '#444', 
                      }}
                    >
                      <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                        {subCategory.sub_category}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <FormGroup>
                        {subCategory.tags.map((tag) => (
                          <FormControlLabel
                            key={tag}
                            control={
                              <Checkbox
                                checked={selectedTags.includes(tag)}
                                onChange={handleTagChange}
                                value={tag}
                                color="primary"
                                sx={{ color: '#444' }} 
                              />
                            }
                            label={tag}
                            sx={{
                              color: '#444', 
                              '& .MuiFormControlLabel-label': { fontWeight: 'bold' },
                            }}
                          />
                        ))}
                      </FormGroup>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </AccordionDetails>
            </Accordion>
          ))
        ) : (
          <Typography>読み込み中...</Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default Categories;