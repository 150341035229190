import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Avatar,
  Box,
  Button, Grid, Paper, TextField,
  Typography
} from "@mui/material";
import { teal } from "@mui/material/colors";
import axios from 'axios';
import { default as React, useEffect, useState, } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import CustomLogger from '../logger/Logger';


export default function Login() {
  const navigate = useNavigate();
  const [id, setId] = useState('')
  const [password, setPassword] = useState('')
  const [nouser, setNoUser] = useState('')
  const [cookies, setCookie, removeCookie] = useCookies();
  const logger = new CustomLogger();

  // TextFieldの表示を切り替える
  const handleChangeId = (e) => {
    setId(e.target.value);
  };
  const handleChangePassword = (e) => {
    setPassword(e.target.value);
  };

  // サーバーからユーザー情報の取得
  function GetUserData(id, password) {
    let isNoUser = true
    // Login画面より受け取ったIDとパスワードの確認
    console.log("input id >>>>>>>>>>>>." + id);
    console.log("input password >>>>>>>>>>>>." + password);

    //ここで実際にDBから取得する
    console.log("login")
    axios.post(`/backend/login`,
      {
        id: id,
        password: password,
        withCredentials: true
      })
      .then(res => {
        console.log(res)
        setCookie("token", res.data.access_token);
        setCookie("refresh_token", res.data.refresh_token);

        // ユーザーとパスワードが一致しなかった時にバックエンドからnoneが返ってくる
        if (res.data == "none") {
          console.log("ng")
          isNoUser = true
          removeCookie("token")
          removeCookie("refresh_token")
        }
        else {
          isNoUser = false
        }

        // 描画の切り替え
        if (isNoUser) {
          setNoUser("※ログインに失敗しました");
        }
        else {
          console.log('>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>' + isNoUser)
          // ここで画面遷移
          console.log("TOPに移動します")
          navigate('/top', {
            state: {
              userId: id,
              userPassword: password
            }
          })
        }
      })
  };

  useEffect(() => {
    if (cookies.token != "") {
      axios.get(`/backend/me`, {
        data: {},
        headers: {
          "Content-Type": "application/json",
          "Authorization": 'Bearer ' + cookies.token
        }
      })
        .then(res => {
          if (res.data.login_ok == "login_ok") {
            navigate('/top', {
              state: {
                userId: res.data.id,
              }
            })
          }
        })
        .catch(error => {
          console.error("Error Response Data:", error.response && error.response.data);

          if (error.response && error.response.status === 401) {
            // Token has expired, try to refresh it
            axios.get(`/backend/refresh`, {
              data: {},
              headers: {
                "Content-Type": "application/json",
                "Authorization": 'Bearer ' + cookies.refresh_token
              }
            })
              .then(res => {
                setCookie("token", res.data.access_token);

                return axios.get(`/backend/me`, {
                  data: {},
                  headers: {
                    "Content-Type": "application/json",
                    "Authorization": 'Bearer ' + res.data.access_token
                  }
                });
              })
              .then(res => {
                if (res.data.login_ok == "login_ok") {
                  navigate('/top', {
                    state: {
                      userId: res.data.id,
                    }
                  })
                }
              })
              .catch(error => {
                console.error("Error after refreshing token: ", error);
              });
          } else {
            console.error("Error during the initial request or token refreshment has failed:", error);
          }
        });
    }
  }, [cookies.token, navigate, setCookie]);


  return (
    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "70vh",
          width: "280px",
          m: "20px auto"
        }}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
        >
          <Avatar sx={{ bgcolor: teal[400] }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography variant={"h5"} sx={{ m: "30px" }}>
            Log in
          </Typography>
        </Grid>

        <TextField
          value={id}
          onChange={handleChangeId}
          label="id"
          variant="standard"
          fullWidth required
        />

        <TextField
          value={password}
          onChange={handleChangePassword}
          type="password"
          label="password"
          variant="standard"
          fullWidth
          required
        />
        <Box mt={3}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            fullWidth
            onClick={() => GetUserData(id, password)}
          >
            Log in
          </Button>
          <h4 style={{ color: "red" }}>{nouser}</h4>
        </Box>
      </Paper >
    </Grid >
  );
}
