import Typography from '@mui/material/Typography';
import { React } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import RelatedQuestionsListItem from './RelatedQuestionsListItem';

function RelatedQuestions({ relatedQuestions, userData, userId, notificationCount, notifications, categories }) {

    return (
        <div >
            <h2>関連する質問</h2>
            {relatedQuestions.length > 0 ? (
                relatedQuestions.map((item, index) => (
                    <RelatedQuestionsListItem
                        key={index}
                        item={item}
                        index={index}
                        userData={userData}
                        userId={userId}
                        notificationCount={notificationCount}
                        notifications={notifications}
                        categories={categories}
                    />
                ))
            ) : (
                <Typography variant="h5" color="textSecondary">
                    検索結果はありませんでした。
                </Typography>
            )
            }
        </div >
    );
}

export default RelatedQuestions;
