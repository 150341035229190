import React, { useRef, useState } from "react";
import { useLocation } from "react-router-dom";

// アイコン
import ChatIcon from "@mui/icons-material/Chat";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

// MUIコンポーネント
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

// 各種ダイアログをまとめたファイルからインポート

import Header from "../header/Header";
import ChatHistoryView from "./ChatHistoryView";
import PDFRegistrationView from "./PDFRegistrationView";

export default function Management() {
  const location = useLocation();

  // ルーターから受け取るパラメータ(必要に応じて変更)
  const { userId, notificationCount, notifications, userData, category } =
    location.state || {};

  // 階層データ
  const [hierarchyData, setHierarchyData] = useState([]);
  const [selectedHierarchy, setSelectedHierarchy] = useState(null);

  // PDFアップロード関連
  const fileInputRef = useRef(null);
  const [selectedFiles, setSelectedFiles] = useState([]);

  // ファイルリスト
  const [fileList, setFileList] = useState([]);

  // チェックボックス制御
  const [checkedFiles, setCheckedFiles] = useState(new Set());

  // ローディング状態
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");

  // タブ切り替え
  const [selectedView, setSelectedView] = useState("pdf");

  // サイドメニューの開閉
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  // 検索機能など
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowLeft, setArrowLeft] = useState(0);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const iconRef = useRef(null);

  // レンダリング
  return (
    <div>
      {/* ヘッダー */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 0,
          paddingTop: "4px",
        }}
      >
        <Header
          searchText={searchText}
          notificationCount={notificationCount}
          id={id}
          iconRef={iconRef}
          arrowLeft={arrowLeft}
          notifications={notifications}
          userId={userId}
          userData={userData}
          categories={category}
        />
        <Box
          display="flex"
          justifyContent="flex-start"
          my={0}
          mx="auto"
          width="100%"
          py="35px"
        />
      </Box>

      <Grid
        container
        sx={{ backgroundColor: "#F2F2F2", minHeight: "calc(100vh - 100px)" }}
      >
        {/* サイドメニュー */}
        <Grid
          item
          sx={{
            position: "relative",
            width: isSidebarOpen ? 240 : 60,
            transition: "width 0.3s",
            borderRight: "1px solid #ddd",
            backgroundColor: "white",
            overflow: "hidden",
          }}
        >
          {/* 右上の矢印アイコン */}
          <IconButton
            onClick={() => setIsSidebarOpen((prev) => !prev)}
            sx={{ position: "absolute", top: 8, right: 8 }}
          >
            {isSidebarOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>

          {/* メニューリスト */}
          <Box
            sx={{
              p: 2,
              pt: 6,
              display: "flex",
              flexDirection: "column",
              alignItems: isSidebarOpen ? "flex-start" : "center",
            }}
          >
            <List>
              <ListItem
                button
                selected={selectedView === "pdf"}
                onClick={() => setSelectedView("pdf")}
              >
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <PictureAsPdfIcon />
                </ListItemIcon>
                {isSidebarOpen && (
                  <ListItemText
                    primary="PDFファイル登録"
                    sx={{ whiteSpace: "nowrap", ml: 1 }}
                  />
                )}
              </ListItem>

              <ListItem
                button
                selected={selectedView === "chat"}
                onClick={() => setSelectedView("chat")}
              >
                <ListItemIcon sx={{ minWidth: 40 }}>
                  <ChatIcon />
                </ListItemIcon>
                {isSidebarOpen && (
                  <ListItemText
                    primary="役に立ったチャット"
                    sx={{ whiteSpace: "nowrap", ml: 1 }}
                  />
                )}
              </ListItem>
            </List>
          </Box>
        </Grid>

        {/* メインコンテンツ */}
        <Grid item xs>
          {selectedView === "pdf" ? (
            <PDFRegistrationView
              hierarchyData={hierarchyData}
              selectedHierarchy={selectedHierarchy}
              setSelectedHierarchy={setSelectedHierarchy}
              selectedFiles={selectedFiles}
              setSelectedFiles={setSelectedFiles}
              fileList={fileList}
              setFileList={setFileList}
              checkedFiles={checkedFiles}
              setCheckedFiles={setCheckedFiles}
              fileInputRef={fileInputRef}
              userId={userId}
              setLoading={setLoading}
              setLoadingMessage={setLoadingMessage}
              loading={loading}
              loadingMessage={loadingMessage}
            />
          ) : (
            <ChatHistoryView />
          )}
        </Grid>
      </Grid>
    </div>
  );
}
