import React from 'react';

function AuthFailure() {
    return (
        <div style={{ padding: '50px', textAlign: 'center' }}>
            <h1>認証失敗</h1>
            <p>申し訳ございませんが、認証に失敗しました。</p>
            <p>d'neo内QAシステムアイコンより再度アクセスをお願いします。</p>
        </div>
    );
}

export default AuthFailure;
