import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { React, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../header/Header';
import MenuButtons from '../menubuttons/MenuButtons';
import QuestionsList from './QuestionsList';

export default function Answers() {
    const location = useLocation();
    const { notificationCount, notifications, userData, category } = location.state || {};
    const navigate = useNavigate();
    const { userId } = location.state
    const iconRef = useRef(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const [questions, setQuestions] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [arrowLeft, setArrowLeft] = useState(0);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // 画面が再描画される度に質問を取得するようにする
    useEffect(() => {
        console.log('全ての質問を取得');
        async function fetchQuestion() {
            const res = await axios.post(
                `/backend/getquestions`,
                {
                    withCredentials: true
                }
            );
            console.log(res)
            setQuestions(res.data);
        }
        fetchQuestion();
    }, []);

    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, paddingTop: '10px' }}>
                <Header
                    searchText={searchText}
                    notificationCount={notificationCount}
                    id={id}
                    iconRef={iconRef}
                    arrowLeft={arrowLeft}
                    notifications={notifications}
                    userId={userId}
                    userData={userData}
                    categories={category}
                />
                <MenuButtons
                    navigate={navigate}
                    userId={userId}
                    buttonType={'回答する'}
                    notificationCount={notificationCount}
                    notifications={notifications}
                    userData={userData}
                    categories={category}
                />
            </Box>

            <Grid
                container
                direction="column"
                justifyContent="flex-start"
                alignItems="center"
                spacing={2}
                sx={{ flexGrow: 1, backgroundColor: '#F2F2F2', height: '100vh', overflow: 'auto' }}
            >
                <Grid
                    item xs={12}
                    sx={{ width: '80%', mt: 5 }}
                >
                    <Box sx={{ backgroundColor: 'white', p: 0, borderRadius: 2, border: '1px solid black', m: 0 }}>
                        <QuestionsList
                            userId={userId}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                            questions={questions}
                            userData={userData}
                            notificationCount={notificationCount}
                            notifications={notifications}
                            category={category}
                        />
                    </Box>
                </Grid>
            </Grid>
        </div >
    );
};
