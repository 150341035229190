import DoneOutlineSharpIcon from '@mui/icons-material/DoneOutlineSharp';
import PersonIcon from '@mui/icons-material/Person';
import { Button } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import 'draft-js/dist/Draft.css';
import { React, useEffect, useRef, useState } from 'react';
import { useCookies } from "react-cookie";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation, useNavigate } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import Header from '../header/Header';
import MenuButtons from '../menubuttons/MenuButtons';

// 画面が再描画される度に回答を取得するようにする
function AnswerList({
    questionId,
    userId,
    setAnswerCommentEditorProps,
    handleEditing,
    answer,
    solution_answers,
    userData,
    fileName,
    imagesAnswer,
    filesAnswer,
    notificationCount,
    notifications,
    categories,
}) {
    const navigate = useNavigate();
    const [answerName, setAnswerNname] = useState([]);
    const [answerUserIcon, setAnswerUserIcon] = useState([]);
    const isUserAnonymous = answer.is_anonymous && userData.role !== 'admin';
    const isAdmin = userData.role === 'admin';

    const displayUserName = answer.is_anonymous
        ? isAdmin
            ? `${answerName || answer.user_id} (匿名投稿)`
            : '匿名ユーザー'
        : answerName || answer.user_id;
    // ファイルをダウンロードする関数
    const downloadFile = async () => {
        console.log('ダウンロードするファイル:', fileName);
        try {
            const response = await axios.post(
                `/backend/download`,
                {
                    fileName: fileName
                },
                {
                    withCredentials: true,
                    responseType: 'arraybuffer'
                }
            );
            console.log(response)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', extractFileName(fileName));
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('File download failed:', error);
        }
    };

    // 既存のfile_nameと新しい画像・ファイルリストを表示するロジック
    const renderFileLinks = () => {
        const links = [];

        // 古いfile_nameが存在する場合はリンクを追加
        if (fileName) {
            links.push(
                <div key="old-file">
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(fileName);
                    }}>{extractFileName(fileName)}</a>
                </div>
            );
        }

        // 新しい画像ファイルのリンクを追加
        imagesAnswer.forEach((image, index) => {
            links.push(
                <div key={`image-${index}`}>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(image.file_name);
                    }}>{extractFileName(image.file_name)}</a>
                </div>
            );
        });

        // 新しいその他のファイルのリンクを追加
        filesAnswer.forEach((file, index) => {
            links.push(
                <div key={`file-${index}`}>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(file.file_name);
                    }}>{extractFileName(file.file_name)}</a>
                </div>
            );
        });

        return links;
    };

    // ファイル名を取得する関数
    function extractFileName(str) {
        const regex = /.*?_([^_]+\.\w+)$/;
        const match = str.match(regex);
        return match ? match[1] : str;
    }
    // 受け取ったマークアップをマークアップとして表示する関数
    const DisplayMarkup = ({ html }) => {
        const globalStyle = `
            table {
                border-collapse: collapse;
                width: 100%;
            }

            th, td {
                border: 1px solid black;
                padding: 8px;
                text-align: left;
            }

            pre {
                background-color: #f5f5f5;
                border: 1px solid #ddd;
                padding: 10px;
                white-space: pre-wrap;
            }

            code {
                font-family: 'Courier New', Courier, monospace;
            }

            blockquote {
                border-left: 4px solid #ccc;
                margin: 0 0 0 10px;
                padding: 0 15px;
                color: #666;
                font-style: italic;
            }

            img {
                max-width: 100%; /* 親要素の幅に収まる */
                height: auto;    /* アスペクト比を維持 */
                display: block;  /* ブロック要素にすることで、余分な余白を排除 */
                margin: 0 auto;  /* 中央寄せにしたい場合は有効 */
            }
        `;

        return (
            <>
                <style dangerouslySetInnerHTML={{ __html: globalStyle }} />
                <div dangerouslySetInnerHTML={{ __html: html }} />
            </>
        );
    };

    // 　ユーザー情報を取得する
    useEffect(() => {
        if (isUserAnonymous) {
            // 匿名ユーザーの場合はユーザー情報を取得しない
            return;
        }
        async function getusers() {
            console.log(answer.user_id)
            try {
                const res = await axios.post(
                    `/backend/getusers`,
                    {
                        user_id: answer.user_id,
                        withCredentials: true
                    }
                );
                setAnswerNname(res.data.name);
                setAnswerUserIcon(res.data.icon);
                console.log(res.data)
            } catch (error) {
                console.error('ユーザー情報の取得に失敗しました。', error);
            }
        }
        getusers();
    }, []);

    // ユーザー画面に移動する
    const moveUsers = () => {
        if (isUserAnonymous) {
            // 匿名ユーザーの場合はユーザー情報を取得しない
            return;
        }
        console.log("ユーザー画面に移動する");
        navigate('/user', {
            state: {
                userId: userId,
                notificationCount: notificationCount,
                notifications: notifications,
                category: categories,
                pageId: answer.user_id
            }
        })
    };

    return (
        <div>
            <Grid key={answer.answer_id} container direction="column" spacing={2}>
                <Grid item>
                    <Grid container alignItems="center" wrap="nowrap" spacing={2}>
                        <Grid item>
                            <Avatar onClick={moveUsers} style={{ cursor: isUserAnonymous ? 'default' : 'pointer' }} >
                                {isUserAnonymous ? (
                                    <PersonIcon />
                                ) : answerUserIcon !== "null" ? (
                                    <img src={answerUserIcon} style={{ maxWidth: '100%', height: 'auto' }} alt="user icon" />
                                ) : (
                                    <PersonIcon />
                                )}
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6">
                                {displayUserName}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs>
                    <div
                        key={answer.answer_id}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        {solution_answers && solution_answers.includes(answer.id) && (
                            <Grid item>
                                <Box
                                    sx={{
                                        backgroundColor: '#d4edda',
                                        color: '#155724',
                                        borderRadius: '8px',
                                        padding: '8px',
                                        border: '2px solid #c3e6cb',
                                        display: 'flex',
                                        alignItems: 'center',
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                    }}
                                >
                                    <DoneOutlineSharpIcon sx={{ color: '#155724', fontSize: '20px', marginRight: '8px' }} />
                                    <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                        解決に役立った回答
                                    </Typography>
                                </Box>
                            </Grid>
                        )}
                        <DisplayMarkup html={answer.html_text} />
                    </div>
                    {/* 添付ファイルがある場合のみリンクを表示 */}
                    {<div>
                        {renderFileLinks()}
                    </div>}
                </Grid>
            </Grid>
        </div>
    );
};

// 画面が再描画される度に回答を取得するようにする
function CommentList({
    questionId,
    answerId,
    userId,
    setAnswerCommentEditorProps,
    handleEditing,
    comment,
    solution_comments,
    fileName,
    imagesComment,
    filesComment,
    notificationCount,
    notifications,
    categories,
    userName,
    userData,
}) {
    const navigate = useNavigate();
    const [commentName, setCommentName] = useState([]);
    const [commentUserIcon, setCommentUserIcon] = useState([]);
    const isUserAnonymous = comment.is_anonymous && userData.role !== 'admin';
    const isAdmin = userData.role === 'admin';

    const displayUserName = comment.is_anonymous
        ? isAdmin
            ? `${commentName || comment.user_id} (匿名投稿)`
            : '匿名ユーザー'
        : commentName || comment.user_id;

    console.log(comment)
    console.log(solution_comments)

    // ファイルをダウンロードする関数
    const downloadFile = async () => {
        console.log('ダウンロードするファイル:', fileName);
        try {
            const response = await axios.post(
                `/backend/download`,
                {
                    fileName: fileName
                },
                {
                    withCredentials: true,
                    responseType: 'arraybuffer'
                }
            );
            console.log(response)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', extractFileName(fileName));
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('File download failed:', error);
        }
    };

    const renderFileLinks = () => {
        const links = [];
        // 古いfile_nameが存在する場合はリンクを追加
        if (fileName) {
            links.push(
                <div key="old-file">
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(fileName);
                    }}>{extractFileName(fileName)}</a>
                </div>
            );
        }

        // 新しい画像ファイルのリンクを追加
        imagesComment.forEach((image, index) => {
            links.push(
                <div key={`image-${index}`}>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(image.file_name);
                    }}>{extractFileName(image.file_name)}</a>
                </div>
            );
        });

        // 新しいその他のファイルのリンクを追加
        filesComment.forEach((file, index) => {
            links.push(
                <div key={`file-${index}`}>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(file.file_name);
                    }}>{extractFileName(file.file_name)}</a>
                </div>
            );
        });

        return links;
    };

    // ファイル名を取得する関数
    function extractFileName(str) {
        const regex = /.*?_([^_]+\.\w+)$/;
        const match = str.match(regex);
        return match ? match[1] : str;
    }

    // 　ユーザー情報を取得する
    useEffect(() => {
        if (isUserAnonymous) {
            // 匿名ユーザーの場合はユーザー情報を取得しない
            return;
        }
        async function getusers() {
            console.log(comment.user_id)
            try {
                const res = await axios.post(
                    `/backend/getusers`,
                    {
                        user_id: comment.user_id,
                        withCredentials: true
                    }
                );
                setCommentName(res.data.name);
                setCommentUserIcon(res.data.icon);
                console.log(res.data)
            } catch (error) {
                console.error('ユーザー情報の取得に失敗しました。', error);
            }
        }
        getusers();
    }, []);

    // 受け取ったマークアップをマークアップとして表示する関数
    const DisplayMarkup = ({ html }) => {
        const globalStyle = `
            table {
                border-collapse: collapse;
                width: 100%;
            }

            th, td {
                border: 1px solid black;
                padding: 8px;
                text-align: left;
            }

            pre {
                background-color: #f5f5f5;
                border: 1px solid #ddd;
                padding: 10px;
                white-space: pre-wrap;
            }

            code {
                font-family: 'Courier New', Courier, monospace;
            }

            blockquote {
                border-left: 4px solid #ccc;
                margin: 0 0 0 10px;
                padding: 0 15px;
                color: #666;
                font-style: italic;
            }

            img {
                max-width: 100%; /* 親要素の幅に収まる */
                height: auto;    /* アスペクト比を維持 */
                display: block;  /* ブロック要素にすることで、余分な余白を排除 */
                margin: 0 auto;  /* 中央寄せにしたい場合は有効 */
            }
        `;

        return (
            <>
                <style dangerouslySetInnerHTML={{ __html: globalStyle }} />
                <div dangerouslySetInnerHTML={{ __html: html }} />
            </>
        );
    };

    // ユーザー画面に移動する
    const moveUsers = () => {
        if (isUserAnonymous) {
            // 匿名ユーザーの場合はユーザー情報を取得しない
            return;
        }
        console.log("ユーザー画面に移動する");
        navigate('/user', {
            state: {
                userId: userId,
                notificationCount: notificationCount,
                notifications: notifications,
                category: categories,
                pageId: comment.user_id
            }
        })
    };

    return (
        <div>
            <Grid container spacing={2}>
                <Grid item container alignItems="center" spacing={2}>
                    <Grid item>
                        <Avatar onClick={moveUsers} style={{ cursor: isUserAnonymous ? 'default' : 'pointer' }} >
                            {isUserAnonymous ? (
                                <PersonIcon />
                            ) : commentUserIcon !== "null" ? (
                                <img src={commentUserIcon} style={{ maxWidth: '100%', height: 'auto' }} alt="user icon" />
                            ) : (
                                <PersonIcon />
                            )}
                        </Avatar>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" marginRight="10px">
                            {displayUserName}
                        </Typography>
                    </Grid>
                </Grid>
                {solution_comments && solution_comments.includes(comment.id) && (
                    <Grid item>
                        <Box
                            sx={{
                                backgroundColor: '#d4edda',
                                color: '#155724',
                                borderRadius: '8px',
                                padding: '8px',
                                border: '2px solid #c3e6cb',
                                display: 'flex',
                                alignItems: 'center',
                                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            <DoneOutlineSharpIcon sx={{ color: '#155724', fontSize: '20px', marginRight: '8px' }} />
                            <Typography variant="body1" style={{ fontWeight: 'bold', fontSize: '16px' }}>
                                解決に役立ったコメント
                            </Typography>
                        </Box>
                    </Grid>
                )}
                <Grid item container xs={12} alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <div key={comment.id}>
                            <DisplayMarkup html={comment.html_text} />
                        </div>
                        {/* 添付ファイルがある場合のみリンクを表示 */}
                        {<div>
                            {renderFileLinks()}
                        </div>}
                    </Grid>
                </Grid>
            </Grid>
        </div>

    );
};


export default function Resolved() {
    const navigate = useNavigate();
    const location = useLocation()
    const [question, setQuestion] = useState();
    const [solution, setSolution] = useState();
    const [solution_answers, setSolutionAnswers] = useState([]);
    const [solution_comments, setSolutionComments] = useState([]);
    const { questionId, questionUserId, notificationCount, notifications, userData, userId, category } = location.state || {};
    const [title, setTitle] = useState('')
    const [answerCommentEditorProps, setAnswerCommentEditorProps] = useState({});
    const [editing, setEditing] = useState(true)
    const [answers, setAnswers] = useState([]);
    const [answerId, setAnswerId] = useState([]);
    const [comments, setComments] = useState([]);
    const [replycomments, setReplyComments] = useState([]);
    const [repliedCommentId, setRepliedCommentId] = useState([]);
    const [editingAnswerId, setEditingAnswerId] = useState(null);
    const [editingAnswer, setEditingAnswer] = useState({ id: null, isEditing: false });
    const [showCommentsForAnswer, setShowCommentsForAnswer] = useState({});
    const [cookies, setCookie, removeCookie] = useCookies();
    const [newToken, setNewToken] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [arrowLeft, setArrowLeft] = useState(0);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const iconRef = useRef(null);
    const [tags, setTags] = useState([]);
    const [questionUserIcon, setQuestionUserIcon] = useState([]);
    const [questioName, setQuestioNname] = useState([]);
    const [fileName, setFileName] = useState('');
    const [fileNameAnswer, setFileNameAnswer] = useState('');
    const [fileNameComment, setFileNameComment] = useState('');
    const [imagesQuestion, setImagesQuestion] = useState([]);
    const [filesQuestion, setFilesQuestion] = useState([]);
    const [imagesAnswer, setImagesAnswer] = useState([]);
    const [filesAnswer, setFilesAnswer] = useState([]);
    const [imagesComment, setImagesComment] = useState([]);
    const [filesComment, setFilesComment] = useState([]);
    const isUserAnonymous = question && question.is_anonymous && userData.role !== 'admin';
    const isAdmin = userData.role === 'admin';
    const displayUserName = question && question.is_anonymous
        ? isAdmin
            ? `${questioName || questionUserId} (匿名投稿)`
            : '匿名ユーザー'
        : questioName || questionUserId;

    // ファイルをダウンロードする関数
    const downloadFile = async () => {
        console.log('ダウンロードするファイル:', fileName);
        try {
            const response = await axios.post(
                `/backend/download`,
                {
                    fileName: fileName
                },
                {
                    withCredentials: true,
                    responseType: 'arraybuffer'
                }
            );
            console.log(response)
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', extractFileName(fileName));
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('File download failed:', error);
        }
    };

    // 既存のfile_nameと新しい画像・ファイルリストを表示するロジック
    const renderFileLinks = () => {
        const links = [];

        // 古いfile_nameが存在する場合はリンクを追加
        if (fileName) {
            links.push(
                <div key="old-file">
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(fileName);
                    }}>{extractFileName(fileName)}</a>
                </div>
            );
        }

        // 新しい画像ファイルのリンクを追加
        imagesQuestion.forEach((image, index) => {
            links.push(
                <div key={`image-${index}`}>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(image.file_name);
                    }}>{extractFileName(image.file_name)}</a>
                </div>
            );
        });

        // 新しいその他のファイルのリンクを追加
        filesQuestion.forEach((file, index) => {
            links.push(
                <div key={`file-${index}`}>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(file.file_name);
                    }}>{extractFileName(file.file_name)}</a>
                </div>
            );
        });
        return links;
    };

    // ファイル名を取得する関数
    function extractFileName(str) {
        const regex = /.*?_([^_]+\.\w+)$/;
        const match = str.match(regex);
        return match ? match[1] : str;
    }

    // コメントの編集を切り替える関数
    const handleEditingComments = (answerId) => {
        if (editingAnswer.id === answerId) {
            setEditingAnswer({ id: answerId, isEditing: !editingAnswer.isEditing });
        } else {
            setEditingAnswer({ id: answerId, isEditing: true });
        }
    };

    // スタイルペーパの設定などの関数
    const StyledPaper = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        color: theme.palette.text.primary,
    }));

    // 受け取ったマークアップをマークアップとして表示する関数
    const DisplayMarkup = ({ html }) => {
        const globalStyle = `
            table {
                border-collapse: collapse;
                width: 100%;
            }

            th, td {
                border: 1px solid black;
                padding: 8px;
                text-align: left;
            }

            pre {
                background-color: #f5f5f5;
                border: 1px solid #ddd;
                padding: 10px;
                white-space: pre-wrap;
            }

            code {
                font-family: 'Courier New', Courier, monospace;
            }

            blockquote {
                border-left: 4px solid #ccc;
                margin: 0 0 0 10px;
                padding: 0 15px;
                color: #666;
                font-style: italic;
            }

            img {
                max-width: 100%; /* 親要素の幅に収まる */
                height: auto;    /* アスペクト比を維持 */
                display: block;  /* ブロック要素にすることで、余分な余白を排除 */
                margin: 0 auto;  /* 中央寄せにしたい場合は有効 */
            }
        `;

        return (
            <>
                <style dangerouslySetInnerHTML={{ __html: globalStyle }} />
                <div dangerouslySetInnerHTML={{ __html: html }} />
            </>
        );
    };

    // 　ユーザー情報を取得する
    useEffect(() => {
        if (isUserAnonymous) {
            // 匿名ユーザーの場合はユーザー情報を取得しない
            return;
        }
        console.log('ユーザー情報を取得します')
        async function getusers() {
            const res = await axios.post(
                `/backend/getusers`,
                {
                    user_id: questionUserId,
                    withCredentials: true
                }
            );
            console.log(res)
            setQuestioNname(res.data.name);
            setQuestionUserIcon(res.data.icon);
        }
        if (questionUserId) {
            getusers();
        }
    }, [questionUserId, isUserAnonymous]);

    // 画面が再描画される度に質問を取得するようにする
    useEffect(() => {
        console.log('質問を取得します');
        // questionIdを使ってバックエンドから回答を複数取得
        async function fetchQuestion() {
            const res = await axios.post(
                `/backend/getquestion`,
                {
                    question_id: questionId,
                    withCredentials: true
                }
            );
            console.log(res)
            setQuestion(res.data);
            setFileName(res.data.file_name);
            setImagesQuestion(res.data.images);
            setFilesQuestion(res.data.files);
            setTitle(res.data.title);
        }
        if (questionId) {
            fetchQuestion();
        }
    }, [userId, questionId]);

    // 画面が再描画される度に回答を取得するようにする
    useEffect(() => {
        console.log('回答を取得します');
        async function fetchAnswers() {
            const res = await axios.post(
                `/backend/getanswer`,
                {
                    question_id: questionId,
                    withCredentials: true
                }
            );
            console.log(res)
            setAnswers(res.data);
            setAnswerId(res.data.map(answer => answer.id));
            setFileNameAnswer(res.data.map(answer => answer.file_name));
            setImagesAnswer(res.data.map(answer => answer.images).flat());
            setFilesAnswer(res.data.map(answer => answer.files).flat());
        }
        if (questionId) {
            fetchAnswers();
        }
    }, [questionId]);

    // 画面が再描画される度にコメントを取得するようにする
    useEffect(() => {
        async function fetchComments() {
            console.log('コメントを取得します');
            console.log(answerId)
            try {
                const promises = answerId.map(async (id) => {
                    const res = await axios.post(`/backend/getcomment`, {
                        answer_id: id,
                        withCredentials: true
                    });
                    console.log(res);
                    return res.data;
                });

                // すべてのPromiseが完了するまで待機し、結果をまとめる
                const results = await Promise.all(promises);
                const flattenedResults = results.flat();  // 結果をフラットにする
                console.log(flattenedResults);
                setComments(prevComments => [...prevComments, ...flattenedResults]);

                // imagesとfilesを抽出し、状態を更新
                const allImages = flattenedResults.map(comment => comment.images).flat();
                const allFiles = flattenedResults.map(comment => comment.files).flat();
                setImagesComment(allImages);
                setFilesComment(allFiles);

            } catch (error) {
                console.error("コメントの取得中にエラーが発生しました:", error);
            }
        }
        if (answerId.length) {
            fetchComments();
        }
    }, [answerId]);

    // 画面が再描画される度に返信コメントを取得するようにする
    useEffect(() => {
        console.log('返信コメントを取得します');
        async function fetchReplyComments() {
            const promises = repliedCommentId.map(async (id) => {
                const res = await axios.post(`/backend/getreplycomment`, {
                    replied_comment_id: id,
                    withCredentials: true
                });
                console.log(res)
                return res.data
            });

            // すべてのPromiseが完了するまで待機し、結果をまとめる
            const results = await Promise.all(promises);
            setReplyComments([...replycomments, ...results]);
            console.log(replycomments)
            if (replycomments == []) {
                setReplyComments([...replycomments, ...[0, 0, 0]]);
            }
        };
        if (repliedCommentId) {
            fetchReplyComments();
        }
    }, [repliedCommentId]);

    // 画面が再描画される度に質問を取得するようにする
    useEffect(() => {
        console.log('解決方法を取得します');
        // questionIdを使ってバックエンドから回答を複数取得
        async function fetchSolution() {
            const res = await axios.post(
                `/backend/getsolution`,
                {
                    question_id: questionId,
                    withCredentials: true
                }
            );
            console.log(res)
            setSolution(res.data.self_solution_text);
            setSolutionAnswers(res.data.answer_id);
            setSolutionComments(res.data.comment_id);
        }
        if (questionId) {
            fetchSolution();
        }
    }, [userId, questionId]);

    // 画面が再描画される度にタグを取得するようにする
    useEffect(() => {
        console.log('タグを取得します');
        async function getTags() {
            const res = await axios.post(
                `/backend/gettags`,
                {
                    questionId: questionId,
                    withCredentials: true
                }
            );
            console.log(res)
            setTags(res.data);
        }
        getTags();
    }, [answerId]);

    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, paddingTop: '4px' }}>
                <Header searchText={searchText} notificationCount={notificationCount} id={id} iconRef={iconRef} arrowLeft={arrowLeft} notifications={notifications} userId={userId} userData={userData} categories={category} />
                <Box display="flex" justifyContent="flex-start" my={0} mx="auto" width="100%" padding="35px"></Box>
            </Box>

            <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: "100vh", backgroundColor: '#f2f2f2', padding: '16px' }}>
                <Grid item xs={12} lg={8}>
                    {question && (
                        <Box bgcolor="white" p={3} borderRadius={3} boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)" mb={4}>
                            <Typography variant="h4" fontWeight="bold">質問</Typography>
                            <StyledPaper sx={{ mt: 2, p: 2 }}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Avatar>
                                            {isUserAnonymous ? <PersonIcon /> : (
                                                questionUserIcon !== "null" ? (
                                                    <img src={questionUserIcon} style={{ width: '100%', height: 'auto' }} alt="user icon" />
                                                ) : <PersonIcon />
                                            )}
                                        </Avatar>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h6">{displayUserName}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} sx={{ marginTop: '20px' }}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" fontWeight="bold">{question.title}</Typography>
                                        <DisplayMarkup html={question.html_question} />
                                        {tags && tags.map((tag, index) => (
                                            <Chip key={index} label={tag} sx={{ ml: 1, mt: 1 }} />
                                        ))}
                                        {renderFileLinks()}
                                    </Grid>
                                </Grid>
                            </StyledPaper>
                        </Box>
                    )}

                    <Box bgcolor="white" p={3} borderRadius={3} boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)" mb={4}>
                        <Typography variant="h4" fontWeight="bold">回答</Typography>
                        {answers.map((answer, index) => (
                            <StyledPaper key={index} sx={{ mt: 2, p: 2 }}>
                                <AnswerList
                                    questionId={questionId}
                                    userId={userId}
                                    handleEditing={handleEditingComments}
                                    answer={answer}
                                    solution_answers={solution_answers}
                                    userData={userData}
                                    fileName={fileNameAnswer[index]}
                                    downloadFile={downloadFile}
                                    extractFileName={extractFileName}
                                    imagesAnswer={imagesAnswer}
                                    filesAnswer={filesAnswer}
                                    notificationCount={notificationCount}
                                    notifications={notifications}
                                    categories={category}
                                />
                                <Button
                                    onClick={() => { setShowCommentsForAnswer((prevState) => ({ ...prevState, [answer.id]: !prevState[answer.id], })); }}
                                    sx={{ mt: 2, textTransform: 'none', backgroundColor: '#f5f5f5', color: '#333', borderColor: '#ddd', '&:hover': { backgroundColor: '#e0e0e0', }, borderRadius: '12px', fontWeight: '500', padding: '8px 16px', fontSize: '14px', }}>
                                    {`コメント ${comments.filter(comment => comment.answer_id === answer.id).length} 件`}
                                </Button>
                                <Collapse in={showCommentsForAnswer[answer.id] || false}>
                                    {comments.filter((comment) => comment.answer_id === answer.id).map((filteredComment, index) => (
                                        <StyledPaper key={index} sx={{ my: 1, mx: 'auto', p: 2 }}>
                                            <CommentList
                                                questionId={questionId}
                                                userId={userId}
                                                handleEditing={handleEditingComments}
                                                comment={filteredComment}
                                                solution_comments={solution_comments}
                                                userData={userData}
                                                fileName={fileNameComment[index]}
                                                downloadFile={downloadFile}
                                                extractFileName={extractFileName}
                                                imagesComment={imagesComment}
                                                filesComment={filesComment}
                                                notificationCount={notificationCount}
                                                notifications={notifications}
                                                categories={category}
                                            />
                                        </StyledPaper>
                                    ))}
                                </Collapse>
                            </StyledPaper>
                        ))}
                    </Box>

                    {solution && (
                        <Box bgcolor="white" p={3} borderRadius={3} boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)" mb={4}>
                            <Typography variant="h4" fontWeight="bold">解決方法</Typography>
                            <StyledPaper sx={{ mt: 2, p: 2 }}>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item>
                                        <Avatar>
                                            {isUserAnonymous ? <PersonIcon /> : (
                                                questionUserIcon !== "null" ? (
                                                    <img src={questionUserIcon} style={{ width: '100%', height: 'auto' }} alt="user icon" />
                                                ) : <PersonIcon />
                                            )}
                                        </Avatar>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h6">{displayUserName}</Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3} sx={{ marginTop: '20px' }}>
                                    <Grid item xs={12}>
                                        <DisplayMarkup html={solution} />
                                    </Grid>
                                </Grid>
                            </StyledPaper>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </div>
    );
};
