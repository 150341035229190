import { DriveFileMove as MoveIcon } from "@mui/icons-material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import RefreshIcon from "@mui/icons-material/Refresh";
import UpdateIcon from "@mui/icons-material/Update";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  AddHierarchyDialog,
  ConfirmDeleteDialog,
  ConfirmDeleteHierarchyDialog,
  ConfirmRetrieveChromaDialog,
  ConfirmUpdateDialog,
  DeleteSuccessDialog,
  EditSuccessDialog,
  MoveFileDialog,
  MoveSuccessDialog,
  RenameFileDialog,
  RenameHierarchyDialog,
  RetrieveChromaSuccessDialog,
  UpdateSuccessDialog,
} from "./DialogComponents";
import HierarchyTreeView from "./HierarchyTreeView";

/**
 * PDFファイル登録画面
 */
export default function PDFRegistrationView() {
  const [hierarchyData, setHierarchyData] = useState([]);
  const [selectedHierarchy, setSelectedHierarchy] = useState(null);
  const fileInputRef = useRef(null);
  const location = useLocation();

  // location.state から必要なデータを取得
  const { userId, notificationCount, notifications, userData, category } =
    location.state || {};

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [fileList, setFileList] = useState([]);
  const [hierarchyMap, setHierarchyMap] = useState({});
  const [moveDialogOpen, setMoveDialogOpen] = useState(false);
  const [renameDialogOpen, setRenameDialogOpen] = useState(false);
  const [currentFile, setCurrentFile] = useState(null);
  const [checkedFiles, setCheckedFiles] = useState(new Set());
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [selectedHierarchyId, setSelectedHierarchyId] = useState(null);
  const [newHierarchyName, setNewHierarchyName] = useState("");
  const [parentId, setParentId] = useState(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [deleteTargetId, setDeleteTargetId] = useState(null);
  const [currentNode, setCurrentNode] = useState(null);
  const [openRenameDialog, setOpenRenameDialog] = useState(false);
  const [renameValue, setRenameValue] = useState("");
  const [moveSuccessOpen, setMoveSuccessOpen] = useState(false);
  const [updateSuccessOpen, setUpdateSuccessOpen] = useState(false);
  const [editSuccessOpen, setEditSuccessOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [retrieveChromaDialogOpen, setRetrieveChromaDialogOpen] =
    useState(false);
  const [retrieveChromaSuccessOpen, setRetrieveChromaSuccessOpen] =
    useState(false);

  const isIndeterminate =
    checkedFiles.size > 0 && checkedFiles.size < fileList.length;
  const isAllSelected =
    fileList.length > 0 && checkedFiles.size === fileList.length;

  useEffect(() => {
    fetchHierarchyData();
    // 初期選択等が必要ならここで行う
  }, []);

  // 階層削除ダイアログのクローズ
  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
    setNewHierarchyName("");
    setParentId(null);
  };

  // 階層クリック時
  const handleSelectHierarchy = (hierarchy) => {
    if (selectedHierarchyId === hierarchy.id) {
      // 同じ階層を再度クリック → 選択解除
      setSelectedHierarchyId(null);
      setSelectedHierarchy(null);
      setFileList([]);
    } else {
      setSelectedHierarchyId(hierarchy.id);
      setSelectedHierarchy(hierarchy);
      if (hierarchy && hierarchy.name) {
        fetchFileList(hierarchy.name);
      }
    }
  };

  // ファイル選択トグル
  const toggleFileSelection = (fileId) => {
    const newCheckedFiles = new Set(checkedFiles);
    if (newCheckedFiles.has(fileId)) {
      newCheckedFiles.delete(fileId);
    } else {
      newCheckedFiles.add(fileId);
    }
    setCheckedFiles(newCheckedFiles);
  };

  // 全選択・全解除
  const toggleSelectAll = () => {
    if (checkedFiles.size === fileList.length) {
      setCheckedFiles(new Set());
    } else {
      setCheckedFiles(new Set(fileList.map((file) => file.file_name)));
    }
  };

  // ファイル名変更ダイアログを開く
  const handleOpenRenameDialog = () => {
    if (checkedFiles.size === 1) {
      const fileId = Array.from(checkedFiles)[0];
      const file = fileList.find((f) => f.file_name === fileId);
      setCurrentFile(file);
      setRenameDialogOpen(true);
    } else {
      alert("ファイルを一つ選択してください。");
    }
  };
  const handleCloseRenameDialog = () => {
    setRenameDialogOpen(false);
  };

  // ファイル名変更処理
  const handleRenameFile = ({ fileId, newName }) => {
    axios
      .post("/backend/renameFile", { fileId, newName })
      .then(() => {
        setEditSuccessOpen(true);
        // いま選択中の階層で再取得
        if (selectedHierarchy?.name) {
          fetchFileList(selectedHierarchy.name);
        }
      })
      .catch((error) => {
        console.error("Rename error:", error);
        alert(`エラーが発生しました: ${error.message}`);
      });
  };

  // 階層名変更ダイアログを開く
  const handleOpenRenameDialogForHierarchy = () => {
    if (selectedHierarchy) {
      setCurrentNode(selectedHierarchy);
      setRenameValue(selectedHierarchy.name);
      setOpenRenameDialog(true);
    } else {
      alert("階層を選択してください。");
    }
  };

  const handleRename = () => {
    if (!currentNode || !renameValue.trim()) {
      alert("階層名を入力してください。");
      return;
    }
    axios
      .post("/backend/renameHierarchy", {
        id: currentNode.id,
        newName: renameValue,
      })
      .then(() => {
        alert("階層名が更新されました。");
        fetchHierarchyData();
        setOpenRenameDialog(false);
      })
      .catch((error) => {
        console.error("階層名の更新に失敗しました:", error);
        alert("階層名の更新に失敗しました。詳細: " + error.message);
      });
  };

  // 新しい階層追加ダイアログを開く
  const handleOpenDialog = () => {
    if (selectedHierarchy) {
      setParentId(selectedHierarchy.id);
    } else {
      setParentId(null);
    }
    setOpenAddDialog(true);
  };

  // 階層削除
  const handleOpenConfirmDialog = (id) => {
    setDeleteTargetId(id);
    setOpenConfirmDialog(true);
  };
  const handleDeleteHierarchy = async (id) => {
    try {
      await axios.delete(`/backend/deleteHierarchy/${id}`);
      alert("階層とその内容が削除されました。");
      fetchHierarchyData();
      setOpenConfirmDialog(false);
      setSelectedHierarchyId(null);
      setSelectedHierarchy(null);
      setFileList([]);
    } catch (error) {
      console.error("階層の削除に失敗しました:", error);
      alert("階層の削除に失敗しました。");
    }
  };

  // 階層データ取得
  const fetchHierarchyData = async () => {
    try {
      const response = await axios.get("/backend/gethierarchy");
      setHierarchyData(response.data);
    } catch (error) {
      console.error("階層データの取得に失敗", error);
    }
  };

  // ファイルリストを取得
  const fetchFileList = async (hierarchyName) => {
    if (!hierarchyName) return;
    try {
      const response = await axios.get(
        `/backend/getfiles?hierarchyName=${encodeURIComponent(hierarchyName)}`
      );
      setFileList(response.data);
    } catch (error) {
      console.error("ファイルリストの取得に失敗", error);
    }
  };

  // 階層を追加
  const handleAddHierarchy = async (parentId, name) => {
    try {
      await axios.post("/backend/addhierarchy", { parentId, name });
      fetchHierarchyData();
    } catch (error) {
      console.error("階層の追加に失敗", error);
    }
  };

  // ファイルアップロード
  const handleFileUpload = async () => {
    setLoading(true);
    setLoadingMessage("pdfをデータベースに登録中...");

    if (!fileInputRef.current.files.length || !selectedHierarchy) {
      alert("ファイルが選択されていないか、階層が選択されていません。");
      setLoading(false);
      setLoadingMessage("");
      return;
    }

    const formData = new FormData();
    Array.from(fileInputRef.current.files).forEach((file) => {
      formData.append("files", file);
    });
    formData.append("hierarchyName", selectedHierarchy.name);
    formData.append("userId", userId);

    try {
      const response = await axios.post("/backend/uploadpdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      const data = response.data;
      let message = "";
      if (data.success_files.length > 0) {
        message +=
          "以下のファイルが正常にアップロードされました:\n" +
          data.success_files.join("\n") +
          "\n";
      }
      if (data.error_files.length > 0) {
        message += "以下のファイルのアップロードに失敗しました:\n";
        data.error_files.forEach((err) => {
          message += `- ${err.filename}: ${err.error}\n`;
        });
      }
      alert(message);

      fetchFileList(selectedHierarchy.name);
      setSelectedFiles([]);
    } catch (error) {
      console.error("ファイルのアップロードに失敗", error);
      alert("ファイルのアップロードに失敗しました。");
    } finally {
      setLoading(false);
      setLoadingMessage("");
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    }
  };

  // ファイルダウンロード
  const handleDownload = async (uniqueFileName, hierarchyName) => {
    try {
      const response = await axios.post(
        "/backend/pdfdownload",
        {
          uniqueFileName,
          hierarchyName,
        },
        {
          responseType: "blob",
        }
      );

      // Content-Dispositionからファイル名を取得
      const contentDisposition = response.headers["content-disposition"];
      if (contentDisposition) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", extractFileName(contentDisposition));
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } else {
        console.error("Content-Disposition header is missing");
        alert("ファイル名を取得できませんでした。");
      }
    } catch (error) {
      console.error("ファイルダウンロードエラー", error);
      alert("ファイルのダウンロードに失敗しました。");
    }
  };

  function extractFileName(contentDisposition) {
    // 1) まず filename*=UTF-8'' の形式を探す
    const fnStarRegex = /filename\*=UTF-8''([^;\n]+)/i;
    const starMatch = fnStarRegex.exec(contentDisposition);
    if (starMatch) {
      // URLエンコードされたファイル名を decode して返す
      return decodeURIComponent(starMatch[1]);
    }
  
    // 2) なければ従来の filename="..." 部分を探す
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i;
    const matches = filenameRegex.exec(contentDisposition);
    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, "");
    }
    return "downloaded_file";
  };

  // ファイル選択
  const handleFileChange = (event) => {
    const files = event.target.files;
    setSelectedFiles(Array.from(files));
  };

  // ファイル削除UI
  function FileDeleteButton({ fetchFileList }) {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [deleteSuccessOpen, setDeleteSuccessOpen] = useState(false);

    const handleDelete = async () => {
      Promise.all(
        Array.from(checkedFiles).map((fileId) =>
          axios.post(`/backend/deletefile`, { fileId })
        )
      )
        .then(() => {
          setDeleteSuccessOpen(true);
          fetchFileList();
          setCheckedFiles(new Set());
        })
        .catch((error) => {
          console.error("Delete error:", error);
          alert("ファイルの削除に失敗しました。");
        })
        .finally(() => {
          setDialogOpen(false);
        });
    };
    return (
      <>
        <Button
          variant="contained"
          color="error"
          startIcon={<DeleteIcon />}
          onClick={() => setDialogOpen(true)}
          disabled={checkedFiles.size === 0}
          sx={{ mr: 1 }}
        >
          削除
        </Button>
        <ConfirmDeleteDialog
          open={dialogOpen}
          onClose={() => setDialogOpen(false)}
          onConfirm={handleDelete}
        />
        <DeleteSuccessDialog
          open={deleteSuccessOpen}
          onClose={() => setDeleteSuccessOpen(false)}
          fetchFileList={fetchFileList}
        />
      </>
    );
  }

  // ファイル更新UI
  function FileUpdateButton({
    selectedHierarchy,
    userId,
    fetchFileList,
    fileList,
    checkedFiles,
  }) {
    const fileUpdateInputRef = useRef(null);
    const [confirmOpen, setConfirmOpen] = useState(false);

    const handleFileUpdate = async () => {
      const files = fileUpdateInputRef.current.files;
      const file = files[0];
      const selectedFileId = Array.from(checkedFiles)[0];
      const selectedFile = fileList.find((f) => f.file_name === selectedFileId);

      if (files.length === 0) {
        alert("ファイルが選択されていません。");
        return;
      }

      if (!selectedFile || file.name !== selectedFile.title) {
        alert(
          "同名のファイルが存在しません。更新するには、同名のファイルを選択してください。"
        );
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("fileName", file.name);
      formData.append("hierarchyName", selectedHierarchy.name);

      try {
        const response = await axios.post("/backend/updatefile", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (response.status === 200) {
          setUpdateSuccessOpen(true);
          fetchFileList(selectedHierarchy.name);
          setCheckedFiles(new Set());
        }
      } catch (error) {
        console.error("Update error:", error);
        alert("ファイルの更新に失敗しました。");
      }
    };

    return (
      <>
        <Button
          variant="contained"
          color="warning"
          startIcon={<UpdateIcon />}
          onClick={() => setConfirmOpen(true)}
          disabled={checkedFiles.size !== 1}
          sx={{ mr: 1 }}
        >
          ファイルを更新
        </Button>
        <input
          type="file"
          ref={fileUpdateInputRef}
          onChange={handleFileUpdate}
          style={{ display: "none" }}
          accept=".pdf"
        />
        <ConfirmUpdateDialog
          open={confirmOpen}
          onClose={() => setConfirmOpen(false)}
          onConfirm={() => {
            setConfirmOpen(false);
            fileUpdateInputRef.current.click();
          }}
        />
      </>
    );
  }

  // Moveファイル
  const handleOpenMoveDialog = () => {
    setMoveDialogOpen(true);
  };
  const handleCloseMoveDialog = () => {
    setMoveDialogOpen(false);
  };
  const handleMoveFile = async (filesToMove, newHierarchyId) => {
    if (!filesToMove || !newHierarchyId) {
      alert("ファイルが選択されていないか、移動先の階層が指定されていません。");
      return;
    }
    try {
      const movePromises = filesToMove.map((file) => {
        return axios.post("/backend/movefile", {
          fileId: file.file_name,
          newHierarchyId: newHierarchyId,
        });
      });
      const results = await Promise.allSettled(movePromises);
      const successCount = results.filter(
        (result) => result.status === "fulfilled"
      ).length;

      if (successCount > 0) {
        setMoveSuccessOpen(true);
      } else {
        alert(
          "ファイルの移動に失敗しました。詳細はコンソールを確認してください。"
        );
      }
      if (selectedHierarchy?.name) {
        fetchFileList(selectedHierarchy.name);
      }
    } catch (error) {
      console.error("ファイルの移動に失敗:", error);
      alert("ファイルの移動に失敗しました。");
    }
    setMoveDialogOpen(false);
  };

  // Chroma再取得
  const handleOpenRetrieveChromaDialog = () => {
    setRetrieveChromaDialogOpen(true);
  };
  const handleCloseRetrieveChromaDialog = () => {
    setRetrieveChromaDialogOpen(false);
  };
  const handleCloseRetrieveChromaSuccessDialog = () => {
    setRetrieveChromaSuccessOpen(false);
  };
  const handleRetrieveChroma = async () => {
    try {
      const fileIds = Array.from(checkedFiles).map((id) => id.toString());
      const response = await axios.post("/backend/retrieve-chroma", {
        fileIds,
      });
      if (response.status === 200) {
        setRetrieveChromaSuccessOpen(true);
      } else {
        console.error("Chroma再取得失敗:", response.data.error);
      }
    } catch (error) {
      console.error("Chroma再取得失敗:", error);
    } finally {
      handleCloseRetrieveChromaDialog();
    }
  };

  // 初回レンダー時、階層データを取得
  useEffect(() => {
    fetchHierarchyData();
  }, []);

  // 階層ツリーマップを作る (不要なら削除)
  useEffect(() => {
    const buildHierarchyMap = (hierarchyList) => {
      let map = {};
      const buildMap = (list, parentId = null) => {
        list.forEach((item) => {
          map[item.id] = { ...item, parentId };
          if (item.children) {
            buildMap(item.children, item.id);
          }
        });
      };
      buildMap(hierarchyData);
      return map;
    };
    setHierarchyMap(buildHierarchyMap(hierarchyData));
  }, [hierarchyData]);

  return (
    <div style={{ backgroundColor: "#F2F2F2" }}>
      <Box sx={{ p: 3 }}>
        {/* タイトル */}
        <Box
          sx={{
            p: 3,
            mb: 3,
            position: "relative",
            backgroundColor: "#F2F2F2", // 淡いグレー
            color: "#1976d2", // 文字色を青系に
            overflow: "hidden",
          }}
        >
          {/* 右上にうっすら表示するアイコン */}
          <Box
            sx={{
              position: "absolute",
              top: "16px",
              right: "16px",
              color: "#1976d2",
              opacity: 0.7,
            }}
          >
            <InsertDriveFileIcon sx={{ fontSize: 120 }} />
          </Box>

          {/* タイトル文字 */}
          <Typography
            variant="h4"
            component="h1"
            sx={{
              position: "relative",
              zIndex: 1,
              fontWeight: "bold",
            }}
          >
            PDFファイルの登録
          </Typography>
        </Box>

        <Grid container spacing={3}>
          {/* フォルダ管理 */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                p: 3,
                backgroundColor: "#fff",
                borderRadius: 2,
                boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
                mb: 4,
              }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                mb={2}
              >
                <Box>
                  <Tooltip title="新しい階層を追加">
                    <IconButton
                      onClick={handleOpenDialog}
                      color="primary"
                      sx={{
                        ml: 1,
                        transition: "transform 0.2s",
                        "&:hover": { transform: "scale(1.1)" },
                      }}
                    >
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="階層名を変更">
                    <IconButton
                      onClick={handleOpenRenameDialogForHierarchy}
                      color="secondary"
                      sx={{
                        ml: 1,
                        transition: "transform 0.2s",
                        "&:hover": { transform: "scale(1.1)" },
                      }}
                      disabled={!selectedHierarchy}
                    >
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="階層を削除">
                    <IconButton
                      onClick={() =>
                        handleOpenConfirmDialog(selectedHierarchy?.id)
                      }
                      color="error"
                      sx={{
                        ml: 1,
                        transition: "transform 0.2s",
                        "&:hover": { transform: "scale(1.1)" },
                      }}
                      disabled={!selectedHierarchy}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>

              {/* 階層ツリービュー */}
              <HierarchyTreeView
                hierarchyData={hierarchyData}
                selectedHierarchyId={selectedHierarchyId}
                handleSelectHierarchy={handleSelectHierarchy}
                fetchHierarchyData={fetchHierarchyData}
              />
            </Box>
          </Grid>

          {/* ファイルリスト  */}
          <Grid item xs={12} md={8}>
            <Card variant="outlined">
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    onClick={toggleSelectAll}
                    variant="outlined"
                    sx={{ mr: 1 }}
                  >
                    {checkedFiles.size === fileList.length
                      ? "全解除"
                      : "全選択"}
                  </Button>
                  {/* 削除 */}
                  <FileDeleteButton
                    fetchFileList={() => fetchFileList(selectedHierarchy?.name)}
                  />
                  {/* 編集(ファイル名変更) */}
                  <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<EditIcon />}
                    onClick={handleOpenRenameDialog}
                    disabled={checkedFiles.size !== 1}
                    sx={{ mr: 1 }}
                  >
                    編集
                  </Button>
                  {/* 移動 */}
                  <Button
                    variant="contained"
                    color="info"
                    startIcon={<MoveIcon />}
                    onClick={handleOpenMoveDialog}
                    disabled={checkedFiles.size < 1}
                    sx={{ mr: 1 }}
                  >
                    移動
                  </Button>
                  {/* 更新 */}
                  <FileUpdateButton
                    selectedHierarchy={selectedHierarchy}
                    userId={userId}
                    fetchFileList={() => fetchFileList(selectedHierarchy?.name)}
                    fileList={fileList}
                    checkedFiles={checkedFiles}
                  />
                  {/* Chroma再取得 */}
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={<RefreshIcon />}
                    onClick={handleOpenRetrieveChromaDialog}
                    disabled={checkedFiles.size < 1}
                  >
                    Chroma再取得
                  </Button>
                </Box>

                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            indeterminate={isIndeterminate}
                            checked={isAllSelected}
                            onChange={toggleSelectAll}
                          />
                        </TableCell>
                        <TableCell>ファイル名</TableCell>
                        <TableCell align="right">登録者</TableCell>
                        <TableCell align="right">登録日時</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fileList.map((file) => (
                        <TableRow
                          key={file.file_name}
                          selected={checkedFiles.has(file.file_name)}
                          hover
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={checkedFiles.has(file.file_name)}
                              onChange={() =>
                                toggleFileSelection(file.file_name)
                              }
                              color="primary"
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Typography
                              style={{
                                cursor: "pointer",
                                color: "#1976d2",
                                textDecoration: "underline",
                              }}
                              onClick={() =>
                                handleDownload(
                                  file.file_name,
                                  selectedHierarchy?.name
                                )
                              }
                            >
                              {file.title}
                            </Typography>
                          </TableCell>
                          <TableCell align="right">{file.uploader}</TableCell>
                          <TableCell align="right">{file.uploadDate}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* アップロードボタン & ファイル選択 */}
                <Box
                  sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<UpdateIcon />}
                    onClick={() => fileInputRef.current.click()}
                    sx={{ mr: 1 }}
                  >
                    ファイルを選択
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleFileUpload}
                    disabled={!selectedFiles.length}
                  >
                    アップロード
                  </Button>
                </Box>
                <input
                  type="file"
                  ref={fileInputRef}
                  multiple
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  accept=".pdf"
                />

                {/* 選択されたファイルの表示 */}
                {selectedFiles.map((file, index) => (
                  <Box
                    key={index}
                    sx={{ mt: 1, display: "flex", alignItems: "center" }}
                  >
                    <Typography variant="body2" sx={{ flexGrow: 1 }}>
                      {file.name}
                    </Typography>
                    <IconButton
                      onClick={() =>
                        setSelectedFiles((prev) =>
                          prev.filter((f) => f.name !== file.name)
                        )
                      }
                      color="error"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                ))}

                {loading && (
                  <Backdrop
                    open={loading}
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                  >
                    <CircularProgress color="inherit" />
                    <Typography variant="h6" sx={{ ml: 2 }}>
                      {loadingMessage}
                    </Typography>
                  </Backdrop>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* ダイアログ群 */}
      <MoveFileDialog
        open={moveDialogOpen}
        onClose={handleCloseMoveDialog}
        hierarchyData={hierarchyData}
        onMove={handleMoveFile}
        fileList={fileList}
        checkedFiles={checkedFiles}
      />
      <RenameFileDialog
        open={renameDialogOpen}
        onClose={handleCloseRenameDialog}
        file={currentFile}
        onRename={handleRenameFile}
      />
      <AddHierarchyDialog
        open={openAddDialog}
        onClose={handleCloseAddDialog}
        onAdd={handleAddHierarchy}
        parentId={parentId}
      />
      <MoveSuccessDialog
        open={moveSuccessOpen}
        onClose={() => setMoveSuccessOpen(false)}
        fetchFileList={() => fetchFileList(selectedHierarchy?.name)}
      />
      <UpdateSuccessDialog
        open={updateSuccessOpen}
        onClose={() => setUpdateSuccessOpen(false)}
        fetchFileList={() => fetchFileList(selectedHierarchy?.name)}
      />
      <EditSuccessDialog
        open={editSuccessOpen}
        onClose={() => setEditSuccessOpen(false)}
        fetchFileList={() => fetchFileList(selectedHierarchy?.name)}
      />
      <ConfirmRetrieveChromaDialog
        open={retrieveChromaDialogOpen}
        onClose={handleCloseRetrieveChromaDialog}
        onConfirm={handleRetrieveChroma}
      />
      <RetrieveChromaSuccessDialog
        open={retrieveChromaSuccessOpen}
        onClose={handleCloseRetrieveChromaSuccessDialog}
      />
      <ConfirmDeleteHierarchyDialog
        open={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        targetName={selectedHierarchy?.name}
        onConfirm={() => handleDeleteHierarchy(deleteTargetId)}
      />
      <RenameHierarchyDialog
        open={openRenameDialog}
        onClose={() => setOpenRenameDialog(false)}
        renameValue={renameValue}
        setRenameValue={setRenameValue}
        onConfirm={(val) => handleRename(val)}
      />
    </div>
  );
}
