import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FolderIcon from '@mui/icons-material/Folder';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import {
    Box,
    IconButton, List, ListItem,
    Typography
} from '@mui/material';
import React, { useState } from 'react';

function HierarchyTreeView({
    hierarchyData,
    selectedHierarchyId,
    handleSelectHierarchy
}) {
    const [expanded, setExpanded] = useState({});

    // 階層展開のトグル
    const toggleExpand = (id) => {
        setExpanded(prev => ({ ...prev, [id]: !prev[id] }));
    };

    // 階層とその子要素を表示
    const renderHierarchy = (nodes, level = 0) => (
        <React.Fragment key={nodes.id}>
            <ListItem
                sx={{
                    pl: `${level * 2}rem`,
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: 'action.hover' },
                    backgroundColor: selectedHierarchyId === nodes.id ? '#b2fab4' : 'inherit',
                }}
                onClick={() => handleSelectHierarchy(nodes)}
            >
                <IconButton onClick={(e) => { e.stopPropagation(); toggleExpand(nodes.id); }}>
                    {expanded[nodes.id] ? <ExpandMoreIcon /> : <ChevronRightIcon />}
                </IconButton>
                {expanded[nodes.id] ? <FolderOpenIcon sx={{ color: 'grey.700', mr: 1 }} /> : <FolderIcon sx={{ color: 'grey.700', mr: 1 }} />}
                <Typography color="primary.main">{nodes.name}</Typography>
            </ListItem>
            {expanded[nodes.id] && Array.isArray(nodes.children) && nodes.children.map((node) => renderHierarchy(node, level + 1))}
        </React.Fragment>
    );
    return (
        <Box>
            <List id="hierarchy-list" sx={{ width: '100%' }}>
                {hierarchyData.map((data) => renderHierarchy(data))}
            </List>

        </Box>
    );
}

export default HierarchyTreeView;
