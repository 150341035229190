import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';

function Consent({ onConsent }) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                backgroundColor: 'white',
                padding: 4,
            }}
        >
            <Box
                sx={{
                    maxWidth: '600px',
                    backgroundColor: 'white',
                    border: '1px solid black',
                    borderRadius: 2,
                    padding: 3,
                    textAlign: 'left',
                }}
            >
                <Typography variant="h4" gutterBottom>
                    QAシステムの利用について
                </Typography>
                <Typography variant="h6" gutterBottom>
                    目的の遵守
                </Typography>
                <Typography paragraph>
                    サイトの利用は技術情報、業務上の困ったことなどの問い合わせに限定し、目的に沿った活動を心掛けること。
                    投稿されたデータは蓄積されていき、最終的にはボットが自動で回答させることを目指します。
                </Typography>
                <Typography variant="h6" gutterBottom>
                    積極的な利用
                </Typography>
                <Typography paragraph>
                    積極的に質問を投稿し、他の投稿に対して有益な回答を提供すること。
                    投稿する情報の正確さを確保し、確認が必要な事実には極力ソースなど
                    根拠を明示すること。
                    表面的な回答に留めず、実用的な情報を提供するよう努めること。
                </Typography>
                <Typography variant="h6" gutterBottom>
                    秘密保持
                </Typography>
                <Typography paragraph>
                    客先で入手した機密情報については共有せず、社外への漏洩を避けること。
                    機密情報に該当すると判断した投稿は、削除する可能性があります。
                    本システム内で知りえた情報やノウハウは他に転用・転記しない事。
                    本システムの利用範囲は自身やチームの業務遂行のみとする。
                </Typography>
                <Button variant="contained" color="primary" onClick={onConsent}>
                    承諾する
                </Button>
            </Box>
        </Box>
    );
}

export default Consent;
