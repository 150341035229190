import PersonIcon from '@mui/icons-material/Person'; // これを追加
import {
    Avatar,
    Chip,
    Grid,
    Typography
} from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useNavigate } from 'react-router-dom';


const RelatedQuestionsListItem = ({
    item,
    index,
    userData,
    userId,
    notificationCount,
    notifications,
    categories
}) => {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [arrowLeft, setArrowLeft] = useState(0);
    const open = Boolean(anchorEl);
    const [questioName, setQuestioNname] = useState([]);
    const [questionUserId, setQuestionUserId] = useState([]);
    const [questionUserIcon, setQuestionUserIcon] = useState([]);

    // スタイルを定義する
    const StyledPaper = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(2),
        maxWidth: 2000,
        color: theme.palette.text.primary,
    }));
    // 日付をフォーマットする
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString([], options);
    };
    // HTMLタグを削除する
    const stripHtml = (html) => {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    };

    // 画面遷移処理
    const navigateToAnswer = (questionId, userName) => {
        console.log("回答画面に遷移します");
        navigate('/answer', {
            state: {
                userId: userId,
                questionId: questionId,
                notificationCount: notificationCount,
                notifications: notifications,
                userData: userData,
            },
            replace: true,
        });
    };

    // 画面遷移処理
    const navigateToResolved = (questionId, userName) => {
        console.log("解決画面に遷移します");
        navigate('/resolved', {
            state: {
                userId: userId,
                questionUserId: userName,
                questionId: questionId,
                notificationCount: notificationCount,
                notifications: notifications,
                userData: userData,
            },
            replace: true,
        });
    };

    // 　ユーザー情報を取得する
    useEffect(() => {
        console.log('ユーザー情報を取得する')
        async function getusers() {
            console.log(item)
            const res = await axios.post(
                `/backend/getusers`,
                {
                    user_id: item.user_id,
                    withCredentials: true
                }
            );
            console.log(res)
            setQuestioNname(res.data.name);
            setQuestionUserId(res.data.id);
            setQuestionUserIcon(res.data.icon);
        }
        getusers();
        return () => {
            console.log('画面がアンマウントされる、または再描画される前に呼び出される関数');
        };
    }, []);

    // ユーザー画面に移動する
    const moveUsers = () => {
        console.log("ユーザー画面に移動する");
        navigate('/user', {
            state: {
                userId: userId,
                notificationCount: notificationCount,
                notifications: notifications,
                category: categories,
                pageId: questionUserId,
            }
        })
    };
    return (
        <StyledPaper
            sx={{
                my: 1,
                mx: 'auto',
                p: 2,
            }}
        >
            <Grid key={index} container direction="column" style={{ width: '100%' }}>
                <Grid item>
                    <Typography
                        variant="h6"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            if (item.resolved) {
                                navigateToResolved(item.id, item.user_id);
                            } else {
                                navigateToAnswer(item.id, item.user_id);
                            }
                        }}
                        color="primary"
                    >
                        {item.title}
                    </Typography>
                    <Typography variant="body1">
                        {stripHtml(item.html_text).substring(0, 50)}...
                    </Typography>
                </Grid>

                <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: 5 }} style={{ width: '100%' }}>
                    <Grid item>
                        <Grid container direction="column">
                            {item.resolved && (
                                <Chip label="解決済み" color="success" size="small" sx={{ ml: 1, mt: -1 }} />
                            )}
                        </Grid>
                    </Grid>

                    <Grid item>
                        <Grid container alignItems="center">
                            <Grid item>
                                <Avatar onClick={moveUsers} style={{ cursor: 'pointer' }}>
                                    {questionUserIcon !== "null"
                                        ? <img src={questionUserIcon} alt="User icon" style={{ height: 24, width: 24 }} />
                                        : <PersonIcon fontSize="small" />
                                    }
                                </Avatar>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" sx={{ ml: 1 }}>
                                    {questioName ? questioName : questionUserId}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2" sx={{ ml: 2 }}>
                                    投稿日時: {formatDate(item.created_at)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid >
        </StyledPaper>
    );
};

export default RelatedQuestionsListItem;
