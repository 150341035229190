import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PersonIcon from '@mui/icons-material/Person';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Avatar, Button, Card, CardContent, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import { React, useEffect, useState } from 'react';

// 日付をフォーマットする
function formatDate(dateString) {
    // GMT を +0900 に置き換える
    const adjustedDateString = dateString.replace('GMT', '+0900');

    // 日付を解釈
    const date = new Date(adjustedDateString);

    // 日本のフォーマットで表示
    return date.toLocaleString('ja-JP', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
    });
}

const QuestionItem = ({
    question,
    userData,
    pageUserData,
    navigateToAnswer
}) => {
    const [tags, setTags] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [openToggleVisibilityDialog, setOpenToggleVisibilityDialog] = useState(false);

    // ダイアログ表示状態
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    // 削除ダイアログを開く
    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };

    // 削除ダイアログを閉じる
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    // 削除処理
    const handleDelete = () => {
        handleDeleteQuestion();
        handleCloseDeleteDialog();
    };

    // 表示/非表示切り替えダイアログを開く
    const handleOpenToggleVisibilityDialog = () => {
        setOpenToggleVisibilityDialog(true);
    };

    // 表示/非表示切り替えダイアログを閉じる
    const handleCloseToggleVisibilityDialog = () => {
        setOpenToggleVisibilityDialog(false);
    };

    // 表示/非表示切り替え処理
    const toggleVisibility = async (questionId, isDeleted) => {
        try {
            // 表示/非表示切り替えのAPIリクエストを送信
            await handleToggleVisibility(questionId, isDeleted);
            console.log("質問の表示状態が切り替わりました");
        } catch (error) {
            console.error("表示/非表示の切り替え中にエラーが発生しました:", error);
        } finally {
            // 処理が終了したらダイアログを閉じる
            handleCloseToggleVisibilityDialog();
            window.location.reload();
        }
    };

    // 質問を削除する関数
    const handleDeleteQuestion = async () => {
        setIsDeleting(true);
        setDeleteMessage('');

        try {
            const res = await axios.post(`/backend/deletequestion`, {
                questionId: question.id,
                withCredentials: true
            });

            if (res.status === 200) {
                setDeleteMessage('質問が削除されました');
                // 削除成功後にページをリロード
                window.location.reload();
            }
        } catch (error) {
            console.error('質問の削除中にエラーが発生しました:', error);
            setDeleteMessage('質問の削除中にエラーが発生しました');
        } finally {
            setIsDeleting(false);
        }
    };

    // 質問の表示/非表示を切り替える関数
    const handleToggleVisibility = async (questionId, isDeleted) => {
        try {
            // APIリクエストを送信
            const response = await axios.post('/backend/toggle-visibility-question', {
                questionId,
                isHidden: isDeleted
            });

            // レスポンスが成功した場合
            if (response.status === 200) {
                console.log('質問の表示状態が切り替わりました');
            }
        } catch (error) {
            console.error('表示/非表示の切り替え中にエラーが発生しました', error);
        }
    };

    // 　タグ情報を取得する
    useEffect(() => {
        if (question) {
            console.log('タグ情報を取得する');
            async function getTags() {
                try {
                    const res = await axios.post(`/backend/gettags`, {
                        questionId: question.id,
                        withCredentials: true
                    });
                    console.log(res);
                    setTags(res.data);
                } catch (error) {
                    console.error('タグ情報の取得に失敗しました:', error);
                }
            }
            getTags();
        }
    }, [question]);

    return (
        <Grid item xs={12}>
            <Card elevation={3} sx={{ mb: 2, backgroundColor: 'white' }}>
                <CardContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs>
                            <Typography
                                variant="h6"
                                color="primary"
                                sx={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}
                                onClick={() => navigateToAnswer(question.id)}>
                                {question.title}
                                {question.resolved && (
                                    <Chip label="解決済み" color="success" size="small" sx={{ ml: 1 }} />
                                )}
                            </Typography>
                        </Grid>

                        {/* 削除ボタン（管理者のみ表示） */}
                        {userData.role === 'admin' && (
                            <Grid item>
                                <Tooltip title="質問を削除">
                                    <IconButton onClick={handleOpenDeleteDialog} sx={{ color: 'error.main' }}>
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )}
                        {isDeleting && <CircularProgress size={24} />}

                        {/* 削除確認ダイアログ */}
                        <Dialog
                            open={openDeleteDialog}
                            onClose={handleCloseDeleteDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"本当に削除しますか？"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    この操作は元に戻せません。選択した質問を削除してもよろしいですか？
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseDeleteDialog} color="primary">
                                    キャンセル
                                </Button>
                                <Button onClick={handleDelete} color="primary" autoFocus>
                                    はい
                                </Button>
                            </DialogActions>
                        </Dialog>


                        {/* 表示/非表示切り替えボタン（管理者または未解決の質問の投稿者のみ表示） */}
                        {(userData.role === 'admin' || (question.user_id === userData.id && !question.resolved)) && (
                            <Grid item>
                                <Tooltip title={question.is_deleted ? "質問を表示" : "質問を非表示"}>
                                    <IconButton onClick={handleOpenToggleVisibilityDialog} sx={{ color: question.is_deleted ? 'action.disabled' : 'primary.main' }}>
                                        {question.is_deleted ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )}

                        {/* 表示/非表示切り替え確認ダイアログ */}
                        <Dialog
                            open={openToggleVisibilityDialog}
                            onClose={handleCloseToggleVisibilityDialog}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">{"質問の表示状態を変更しますか？"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    この操作により、質問の表示状態が変更されます。続行してもよろしいですか？
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleCloseToggleVisibilityDialog} color="primary">
                                    キャンセル
                                </Button>
                                <Button onClick={() => toggleVisibility(question.id, question.is_deleted)} color="primary" autoFocus>
                                    はい
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Grid>

                    {/* タグ表示 */}
                    <Grid item xs={12}>
                        {tags && tags.map((tag, index) => (
                            <Chip key={index} label={tag} sx={{ mx: 0.5, my: 1 }} />
                        ))}
                    </Grid>

                    {/* 質問の投稿者と投稿日時 */}
                    <Grid container justifyContent="space-between" alignItems="center" sx={{ mt: 1 }}>
                        <Grid item>
                            <Avatar sx={{ width: 24, height: 24, mr: 1 }}>
                                {pageUserData.icon && pageUserData.icon !== "null"
                                    ? <img src={pageUserData.icon} alt="User icon" style={{ width: '100%', height: '100%' }} />
                                    : <PersonIcon fontSize="small" />
                                }
                            </Avatar>
                            <Typography variant="body2">{pageUserData.name || "不明なユーザー"}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2">投稿日時: {formatDate(question.created_at)}</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default QuestionItem;
