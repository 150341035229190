import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { React, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../header/Header';
import MenuButtons from '../menubuttons/MenuButtons';
import Categories from './Categories';
import QuestionsList from './QuestionsList';

export default function Questions() {
    const location = useLocation();
    const { notificationCount, notifications, userData, category } = location.state || {};
    const navigate = useNavigate();
    const { userId } = location.state
    const iconRef = useRef(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const [questions, setQuestions] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [arrowLeft, setArrowLeft] = useState(0);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [selectedTags, setSelectedTags] = useState([]);

    // 画面が再描画される度に質問を取得するようにする
    useEffect(() => {
        console.log("質問を取得します");
        async function fetchQuestion() {
            const res = await axios.post(
                `/backend/getquestions`,
                {
                    withCredentials: true
                }
            );
            console.log(res)
            console.log(res.data)
            setQuestions(res.data);
        }
        fetchQuestion();

    }, []);

    // タグが選択されたら質問を絞り込む
    useEffect(() => {
        console.log(selectedTags);
    }, [questions, selectedTags]);

    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, paddingTop: '10px' }}>
                <Header
                    searchText={searchText}
                    notificationCount={notificationCount}
                    id={id}
                    iconRef={iconRef}
                    arrowLeft={arrowLeft}
                    notifications={notifications}
                    userId={userId}
                    userData={userData}
                    categories={category}
                />
                <MenuButtons
                    navigate={navigate}
                    userId={userId}
                    buttonType={'質問一覧'}
                    notificationCount={notificationCount}
                    notifications={notifications}
                    userData={userData}
                    categories={category}
                />
            </Box>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                spacing={2}
                sx={{ flexGrow: 1, backgroundColor: '#F2F2F2' }}
            >
                <Grid
                    item xs={12} md={3}
                    sx={{ mt: 5, order: { xs: 0, md: 1 } }}
                >
                    <Box sx={{ backgroundColor: '#F2F2F2', p: 0, borderRadius: 2, m: 0, ml: 2, mr: 2 }}>
                        <Categories onSelectedTagsChange={setSelectedTags} />
                    </Box>
                </Grid>

                <Grid
                    item xs={12} md={9}
                    sx={{ mt: 5, order: { xs: 1, md: 0 } }}
                >
                    <Box sx={{ backgroundColor: 'white', p: 0, borderRadius: 2, border: '1px solid black', m: 2, mr: 2 }}>
                        <QuestionsList
                            userId={userId}
                            selectedTab={selectedTab}
                            setSelectedTab={setSelectedTab}
                            questions={questions}
                            userData={userData}
                            notificationCount={notificationCount}
                            notifications={notifications}
                            selectedTags={selectedTags}
                            category={category}
                        />
                    </Box>
                </Grid>
            </Grid>
        </div >
    );
};
