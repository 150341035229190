import ChatIcon from "@mui/icons-material/Chat";
import CloseIcon from "@mui/icons-material/Close";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import axios from "axios";
import { marked } from "marked";
import React, { useEffect, useState } from "react";

function ChatBubble({ message, isUser }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: isUser ? "flex-end" : "flex-start",
        mb: 2,
      }}
    >
      <Box
        sx={{
          maxWidth: "70%",
          p: 2,
          borderRadius: 2,
          color: isUser ? "white" : "black",
          bgcolor: isUser ? "primary.main" : "grey.300",
        }}
      >
        <Typography
          variant="body1"
          component="div"
          // ↓ 改行周りを必要最小限にする
          sx={{
            whiteSpace: "normal",
            // Markdown内で生成される要素の余白を調整
            "& p": {
              margin: 0, // 段落の余白を打ち消し
            },
            "& ul, & ol": {
              margin: 0,
              paddingLeft: "1.5em", // 箇条書きのインデント
            },
            "& h1, & h2, & h3, & h4, & h5, & h6": {
              margin: 0, // 見出しの余白を打ち消し
            },
          }}
          dangerouslySetInnerHTML={{ __html: marked(message.trim()) }}
        />
      </Box>
    </Box>
  );
}

function ChatDetailDialog({ open, onClose, chatDetail, loading }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      PaperProps={{
        sx: {
          borderRadius: 2,
          boxShadow: "0 4px 10px rgba(0,0,0,0.1)",
          overflow: "hidden",
        },
      }}
    >
      {/* ダイアログのヘッダー */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          p: 2,
          borderBottom: "1px solid #eee",
        }}
      >
        <Box display="flex" alignItems="center" gap={1} flexGrow={1}>
          <ChatIcon color="primary" />
          <Typography variant="h6" component="div" sx={{ fontWeight: 600 }}>
            {chatDetail?.title ?? "チャット詳細"}
          </Typography>
        </Box>
        <IconButton onClick={onClose} sx={{ color: "#666" }}>
          <CloseIcon />
        </IconButton>
      </Box>

      {/* ローディング */}
      {loading && (
        <Backdrop
          open={loading}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {/* メッセージ本体 */}
      <Box sx={{ p: 2, maxHeight: "60vh", overflowY: "auto" }}>
        {!loading &&
          chatDetail?.questions?.map((q) => (
            <Box key={`question-${q.id}`} sx={{ mb: 3 }}>
              {/* 質問 (ユーザー) */}
              <ChatBubble message={q.text} isUser={true} />

              {/* 回答 (ChatGPT) */}
              {q.answers?.map((ans) => (
                <ChatBubble
                  key={`answer-${ans.id}`}
                  message={ans.text}
                  isUser={false}
                />
              ))}
            </Box>
          ))}
      </Box>
    </Dialog>
  );
}

export default function ChatHistoryView() {
  // チャット一覧データ
  const [chatHistories, setChatHistories] = useState([]);
  // 詳細ダイアログ
  const [currentChatDetail, setCurrentChatDetail] = useState(null);
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  // ローディング
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchChatHistories();
  }, []);

  // チャット履歴を取得
  const fetchChatHistories = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/backend/getValidChats");
      setChatHistories(response.data);
    } catch (error) {
      console.error("チャット履歴の取得に失敗しました:", error);
      alert("チャット履歴の取得に失敗しました。");
    } finally {
      setLoading(false);
    }
  };

  /**
   * 詳細表示
   */
  const handleShowDetail = async (chatId) => {
    setLoading(true);
    setDetailDialogOpen(true);
    try {
      // 詳細を取得するエンドポイント: 例 /backend/getChatDetail?id=xxx
      const response = await axios.get(`/backend/getChatDetail?id=${chatId}`);
      setCurrentChatDetail(response.data);
    } catch (error) {
      console.error("チャット詳細の取得に失敗:", error);
      alert("チャット詳細の取得に失敗しました。");
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDetailDialog = () => {
    setDetailDialogOpen(false);
    setCurrentChatDetail(null);
  };

  return (
    <Box sx={{ p: 2 }}>
      {/* タイトル部分 */}
      <Box
        sx={{
          p: 3,
          mb: 3,
          position: "relative",
          backgroundColor: "#F2F2F2",
          color: "#1976d2",
          overflow: "hidden",
        }}
      >
        {/* 背景アイコン */}
        <Box
          sx={{
            position: "absolute",
            top: "16px",
            right: "16px",
            color: "#1976d2",
            opacity: 0.7,
          }}
        >
          <InsertCommentIcon sx={{ fontSize: 120 }} />
        </Box>

        <Typography
          variant="h4"
          component="h1"
          sx={{
            position: "relative",
            zIndex: 1,
            fontWeight: "bold",
          }}
        >
          役に立ったチャット一覧
        </Typography>
      </Box>

      {/* ローディング */}
      {loading && (
        <Backdrop
          open={loading}
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      )}

      {/* チャット履歴がない場合 */}
      {!loading && chatHistories.length === 0 && (
        <Typography variant="body1" sx={{ mt: 2 }}>
          現在、有効なチャット履歴はありません。
        </Typography>
      )}

      {/* チャット履歴テーブル */}
      {!loading && chatHistories.length > 0 && (
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{ fontWeight: "bold", backgroundColor: "#f9fafb" }}
                >
                  タイトル
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", backgroundColor: "#f9fafb" }}
                >
                  回答（冒頭のみ）
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", backgroundColor: "#f9fafb" }}
                >
                  モデル
                </TableCell>
                <TableCell
                  sx={{ fontWeight: "bold", backgroundColor: "#f9fafb" }}
                >
                  作成日時
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontWeight: "bold", backgroundColor: "#f9fafb" }}
                >
                  詳細
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {chatHistories.map((chat) => {
                // 先頭10文字を取り出し、10文字を超えていれば末尾に「…」を追加
                const shortAnswer = chat.first_answer
                  ? chat.first_answer.slice(0, 10) +
                    (chat.first_answer.length > 10 ? "…" : "")
                  : "";

                return (
                  <TableRow key={chat.id} hover>
                    {/* タイトル */}
                    <TableCell>{chat.title}</TableCell>

                    {/* 回答（冒頭のみ） */}
                    <TableCell>{shortAnswer || "回答なし"}</TableCell>

                    {/* モデル名を表示 */}
                    <TableCell>
                      {chat.model_used === "standard" ? "通常モデル" : (chat.model_used || "不明")}
                    </TableCell>

                    {/* 作成日時 */}
                    <TableCell>{chat.created_at}</TableCell>

                    {/* 詳細ボタン */}
                    <TableCell align="center">
                      <Button
                        variant="contained"
                        size="small"
                        onClick={() => handleShowDetail(chat.id)}
                      >
                        詳細
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* 詳細ダイアログ */}
      <ChatDetailDialog
        open={detailDialogOpen}
        onClose={handleCloseDetailDialog}
        chatDetail={currentChatDetail}
        loading={loading}
      />
    </Box>
  );
}
