import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { React, useEffect, useRef, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../header/Header';
import MenuButtons from '../menubuttons/MenuButtons';
import SearchedListItem from './SearchedListItem';


export default function Searched() {
    //　Log　in画面から必要情報を受け取る
    const location = useLocation()
    const { userId } = location.state
    const { searchText } = location.state
    const { questions } = location.state
    const { notificationCount, notifications, userData, category } = location.state || {};
    const navigate = useNavigate();
    const iconRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [arrowLeft, setArrowLeft] = useState(0);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [filter, setFilter] = useState('all');
    const [sort, setSort] = useState('default');
    const [displayQuestions, setDisplayQuestions] = useState(questions);
    const [errorMessage, setErrorMessage] = useState(location.state?.errorMessage || '');

    // 画面遷移処理
    useEffect(() => {
        let filteredQuestions = questions;
        if (filter === 'open') {
            filteredQuestions = questions.filter(q => !q.resolved);
        } else if (filter === 'resolved') {
            filteredQuestions = questions.filter(q => q.resolved);
        }

        let sortedQuestions = [...filteredQuestions];
        if (sort === 'newest') {
            sortedQuestions.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        } else if (sort === 'oldest') {
            sortedQuestions.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        }
        setDisplayQuestions(sortedQuestions);
    }, [filter, sort, questions]);

    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, paddingTop: '10px' }}>
                <Header
                    searchText={searchText}
                    notificationCount={notificationCount}
                    id={id}
                    iconRef={iconRef}
                    arrowLeft={arrowLeft}
                    notifications={notifications}
                    userId={userId}
                    userData={userData}
                    categories={category}
                />
                <MenuButtons
                    navigate={navigate}
                    userId={userId}
                    buttonType={'質問一覧'}
                    notificationCount={notificationCount}
                    notifications={notifications}
                    userData={userData}
                    categories={category}
                />
            </Box>
            <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                sx={{ flexGrow: 1, backgroundColor: '#F2F2F2', height: '100vh', overflow: 'auto' }}
            >
                <Grid
                    item xs={9}
                    justifyContent="center"
                    alignItems="flex-start"
                    sx={{ mt: 2 }}
                >
                    <Box
                        sx={
                            {
                                backgroundColor: 'white',
                                p: 2,
                                borderRadius: 2,
                                border: '1px solid black',
                                m: 0,
                                display: 'flex',
                                flexDirection: 'column',
                                height: '200px'
                            }}
                    >
                        <FormControl sx={{ m: 1 }}>
                            <InputLabel
                                sx={{
                                    fontWeight: 'bold',
                                    transform: 'translate(8px, -6px) scale(1)'
                                }}
                            >
                                検索対象
                            </InputLabel>
                            <Select value={filter} onChange={(event) => setFilter(event.target.value)} sx={{ mt: 3 }}>
                                <MenuItem value={'all'}>すべて（{questions.length}件）</MenuItem>
                                <MenuItem value={'open'}>回答受付中（{questions.filter(q => !q.resolved).length}件）</MenuItem>
                                <MenuItem value={'resolved'}>解決済み（{questions.filter(q => q.resolved).length}件）</MenuItem>
                            </Select>
                        </FormControl>

                        <FormControl sx={{ m: 1 }}>
                            <InputLabel
                                sx={{
                                    fontWeight: 'bold',
                                    transform: 'translate(8px, -6px) scale(1)'
                                }}
                            >
                                表示順
                            </InputLabel>
                            <Select value={sort} onChange={(event) => setSort(event.target.value)} sx={{ mt: 3 }}>
                                <MenuItem value={'default'}>デフォルト順</MenuItem>
                                <MenuItem value={'newest'}>最新順</MenuItem>
                                <MenuItem value={'oldest'}>古い順</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Grid>
                <Grid
                    item xs={9}
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: '70%', mt: 20, minHeight: '100vh' }}
                >
                    <Box
                        sx={
                            {
                                backgroundColor: 'white',
                                p: 0,
                                borderRadius: 2,
                                border: '1px solid black',
                                m: 0,
                                mr: 40
                            }
                        }
                    >
                        {displayQuestions.length > 0 ? (
                            displayQuestions.map((item, index) => (
                                <SearchedListItem
                                    item={item}
                                    userData={userData}
                                    userId={userId}
                                    notificationCount={notificationCount}
                                    notifications={notifications}
                                />
                            ))
                        ) : errorMessage ? (
                            <Typography variant="h5" style={{ color: 'red' }} dangerouslySetInnerHTML={{ __html: errorMessage.replace(/\n/g, '<br />') }} />
                        ) : (
                            <Typography variant="h5" color="textSecondary">
                                検索結果はありませんでした。
                            </Typography>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </div >
    );
};
