import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PersonIcon from '@mui/icons-material/Person';
import ReplyIcon from '@mui/icons-material/Reply';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Avatar, Button, Card, CardContent, Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import axios from 'axios';
import { React, useEffect, useState } from 'react';

// スタイル
const AnswerTextStyle = {
    backgroundColor: '#e0e0e0',
    borderRadius: '10px',
    padding: '10px',
    color: '#333',
    boxShadow: '0 2px 4px rgba(0,0,0,0.18)',
    display: 'flex',
    alignItems: 'center',
};
const IconStyle = {
    marginRight: '8px',
    color: '#757575',
};

const AnswerItem = ({
    answer,
    userData,
    pageUserData,
    navigateToAnswer
}) => {
    const [tags, setTags] = useState([]);
    const [question, setQuestion] = useState(null);
    const [loading, setLoading] = useState(true);
    const [questionName, setQuestionName] = useState('');
    const [questionUserIcon, setQuestionUserIcon] = useState([]);
    const [isDeleting, setIsDeleting] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [openToggleVisibilityDialog, setOpenToggleVisibilityDialog] = useState(false);

    // ダイアログ表示状態
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    // 削除ダイアログを開く
    const handleOpenDeleteDialog = () => {
        setOpenDeleteDialog(true);
    };

    // 削除ダイアログを閉じる
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
    };

    // 削除処理
    const handleDelete = () => {
        handleDeleteAnswer();
        handleCloseDeleteDialog();
    };

    // 表示/非表示切り替えダイアログを開く
    const handleOpenToggleVisibilityDialog = () => {
        setOpenToggleVisibilityDialog(true);
    };

    // 表示/非表示切り替えダイアログを閉じる
    const handleCloseToggleVisibilityDialog = () => {
        setOpenToggleVisibilityDialog(false);
    };

    // 表示/非表示切り替え処理
    const toggleVisibility = async (answerId, isDeleted) => {
        try {
            // 表示/非表示切り替えのAPIリクエストを送信
            await handleToggleVisibility(answerId, isDeleted);
            console.log("質問の表示状態が切り替わりました");
        } catch (error) {
            console.error("表示/非表示の切り替え中にエラーが発生しました:", error);
        } finally {
            // 処理が終了したらダイアログを閉じる
            handleCloseToggleVisibilityDialog();
            window.location.reload();
        }
    };
    // 回答を削除する関数
    const handleDeleteAnswer = async () => {
        setIsDeleting(true);
        setDeleteMessage('');

        try {
            const res = await axios.post(`/backend/deleteanswer`, {
                answerId: answer.id,
                withCredentials: true
            });

            if (res.status === 200) {
                setDeleteMessage('回答が削除されました');
                window.location.reload();
            }
        } catch (error) {
            console.error('回答の削除中にエラーが発生しました:', error);
            setDeleteMessage('回答の削除中にエラーが発生しました');
        } finally {
            setIsDeleting(false);
        }
    };

    // 質問の表示/非表示を切り替える関数
    const handleToggleVisibility = async (answerId, isDeleted) => {
        try {
            // APIリクエストを送信
            const response = await axios.post('/backend/toggle-visibility-answer', {
                answerId,
                isHidden: isDeleted
            });

            // レスポンスが成功した場合
            if (response.status === 200) {
                console.log('回答の表示状態が切り替わりました');
            }
        } catch (error) {
            console.error('表示/非表示の切り替え中にエラーが発生しました', error);
        }
    };


    // 質問データを取得する
    useEffect(() => {
        const fetchQuestion = async () => {
            setLoading(true);
            try {
                const response = await axios.post('/backend/getquestion', {
                    question_id: answer.question_id
                });
                setQuestion(response.data);
            } catch (error) {
                console.error('Error fetching question data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchQuestion();
    }, [answer.question_id]);

    // 　ユーザー情報を取得する
    useEffect(() => {
        if (question) {
            console.log('ユーザー情報を取得する');
            async function getusers() {
                try {
                    const res = await axios.post(`/backend/getusers`, {
                        user_id: question.user_id,
                        withCredentials: true
                    });
                    console.log(res);
                    setQuestionName(res.data.name);
                    setQuestionUserIcon(res.data.icon);
                } catch (error) {
                    console.error('ユーザー情報の取得に失敗しました:', error);
                }
            }
            getusers();
        }
    }, [question]);

    // 　タグ情報を取得する
    useEffect(() => {
        if (answer) {
            console.log('タグ情報を取得する');
            async function getTags() {
                try {
                    const res = await axios.post(`/backend/gettags`, {
                        questionId: answer.question_id,
                        withCredentials: true
                    });
                    console.log(res);
                    setTags(res.data);
                } catch (error) {
                    console.error('タグ情報の取得に失敗しました:', error);
                }
            }
            getTags();
        }
    }, [answer]);


    // 日付をフォーマットする関数を修正
    function formatDate(dateString) {
        if (!dateString) {
            return '日付がありません';
        }

        // GMT を +0900 に置き換える
        const adjustedDateString = dateString.replace('GMT', '+0900');

        // 日付を解釈
        const date = new Date(adjustedDateString);

        // 日本のフォーマットで表示
        return date.toLocaleString('ja-JP', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    }

    // ローディング中はローディング表示
    if (loading) {
        return <CircularProgress size={24} />;
    }

    // 質問が見つからない場合はエラーメッセージを表示
    if (!question) {
        return <div>質問が見つかりません。</div>;
    }

    return (
        <Grid item xs={12}>
            <Card elevation={3} sx={{ mb: 2, backgroundColor: 'white' }}>
                <CardContent>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs>
                            <Typography
                                variant="h6"
                                color="primary"
                                sx={{ cursor: 'pointer' }}
                                onClick={() => navigateToAnswer(answer.question_id)}
                            >
                                {question.title}
                                {question.resolved && (
                                    <Chip label="解決済み" color="success" size="small" sx={{ ml: 1, mt: -1 }} />
                                )}
                            </Typography>
                        </Grid>

                        {/* 回答を右寄せに表示 */}
                        <Grid item xs={12} container justifyContent="flex-end">
                            <Paper sx={AnswerTextStyle} style={{ maxWidth: "80%" }}>
                                <ReplyIcon sx={IconStyle} />
                                <Typography variant="body1" style={{ lineHeight: '1.5' }}>
                                    {answer.text}
                                </Typography>
                                {/* 削除ボタン（管理者のみ） */}
                                {userData.role === 'admin' && (
                                    <Tooltip title="回答を削除">
                                        <IconButton onClick={handleOpenDeleteDialog} disabled={isDeleting} sx={{ color: 'red', '&:hover': { backgroundColor: 'rgba(255, 0, 0, 0.1)', color: 'darkred', } }}>
                                            <DeleteForeverIcon />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {isDeleting && <CircularProgress size={24} />}

                                {/* 削除確認ダイアログ */}
                                <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                                    <DialogTitle>{"本当に削除しますか？"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>この操作は元に戻せません。選択した回答を削除してもよろしいですか？</DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseDeleteDialog} color="primary">キャンセル</Button>
                                        <Button onClick={handleDelete} color="primary" autoFocus>はい</Button>
                                    </DialogActions>
                                </Dialog>

                                {/* 表示/非表示切り替えボタン */}
                                {(userData.role === 'admin' || (answer.user_id === userData.id && !question.resolved)) && (
                                    <Tooltip title={answer.is_deleted ? "回答を表示" : "回答を非表示"}>
                                        <IconButton
                                            onClick={handleOpenToggleVisibilityDialog}
                                            sx={{ color: answer.is_deleted ? 'gray' : 'blue', '&:hover': { backgroundColor: answer.is_deleted ? 'rgba(128, 128, 128, 0.1)' : 'rgba(0, 0, 255, 0.1)', color: answer.is_deleted ? 'darkgray' : 'darkblue', } }}>
                                            {answer.is_deleted ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                        </IconButton>
                                    </Tooltip>
                                )}

                                {/* 表示/非表示切り替え確認ダイアログ */}
                                <Dialog open={openToggleVisibilityDialog} onClose={handleCloseToggleVisibilityDialog}>
                                    <DialogTitle>{"回答の表示状態を変更しますか？"}</DialogTitle>
                                    <DialogContent>
                                        <DialogContentText>この操作により、回答の表示状態が変更されます。続行してもよろしいですか？</DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                        <Button onClick={handleCloseToggleVisibilityDialog} color="primary">キャンセル</Button>
                                        <Button onClick={() => toggleVisibility(answer.id, answer.is_deleted)} color="primary" autoFocus>はい</Button>
                                    </DialogActions>
                                </Dialog>
                            </Paper>
                        </Grid>

                        {/* タグ表示 */}
                        <Grid item xs={12}>
                            {tags && tags.map((tag, index) => (
                                <Chip key={index} label={tag} sx={{ mx: 0.5, my: 1 }} />
                            ))}
                        </Grid>

                        {/* 質問の投稿者情報を表示 */}
                        <Grid container item xs={12} justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Avatar sx={{ width: 24, height: 24, mr: 1 }}>
                                    {questionUserIcon && questionUserIcon !== "null"
                                        ? <img src={questionUserIcon} alt="User icon" style={{ width: '100%', height: '100%' }} />
                                        : <PersonIcon fontSize="small" />
                                    }
                                </Avatar>
                                <Typography variant="body2">
                                    {questionName || "不明なユーザー"}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body2">
                                    投稿日時: {formatDate(question.created_at)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default AnswerItem;
