import React, { useEffect, useState } from 'react';
import {
  Box,
  Tab,
  Tabs,
  Card,
  CardContent,
  Pagination,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';
import axios from 'axios';
import QuestionListItem from './QuestionListItem';
import Categories from './Categories';

function QuestionsList({
  userId,
  selectedTab,
  setSelectedTab,
  questions,
  userData,
  notificationCount,
  notifications,
  category,
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [filteredQuestionsWithTags, setFilteredQuestionsWithTags] = useState([]);
  const [sortOrder, setSortOrder] = useState('newest');
  const [selectedTags, setSelectedTags] = useState([]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (!questions) return;

    async function fetchAndSetQuestionsWithTags() {
      const questionIds = questions.map((q) => q.id);
      const res = await axios.post('/backend/getbulktags', {
        questionIds: questionIds,
        withCredentials: true,
      });

      const tagsMap = res.data;
      const resDataWithTags = questions.map((question) => ({
        ...question,
        tags: tagsMap[question.id] || [],
      }));

      let filteredResData;
      if (selectedTags.length === 0) {
        filteredResData = resDataWithTags;
      } else {
        filteredResData = resDataWithTags.filter((question) =>
          selectedTags.every((tag) => question.tags && question.tags.includes(tag))
        );
      }

      setFilteredQuestionsWithTags(filteredResData);
    }

    fetchAndSetQuestionsWithTags();
  }, [questions, selectedTags]);

  function renderTabContent() {
    let filteredQuestions = filteredQuestionsWithTags || [];

    // タブに基づいてフィルタリング
    switch (selectedTab) {
      case 0:
        filteredQuestions = filteredQuestions.filter(
          (question) =>
            question.resolved === false &&
            (userData.role === 'admin' ||
              question.is_deleted === false ||
              question.user_id === userData.id)
        );
        break;
      case 1:
        filteredQuestions = filteredQuestions.filter(
          (question) =>
            question.resolved === true &&
            (userData.role === 'admin' ||
              question.is_deleted === false ||
              question.user_id === userData.id)
        );
        break;
      case 2:
        filteredQuestions = filteredQuestions.filter(
          (question) =>
            userData.role === 'admin' ||
            question.is_deleted === false ||
            question.user_id === userData.id
        );
        break;
      // case 3:
      //   const questionIdsInNotifications = notifications.map(
      //     (notification) => notification.question_id
      //   );
      //   filteredQuestions = questions.filter((question) => {
      //     if (userData.role === 'admin') {
      //       return questionIdsInNotifications.includes(question.id);
      //     } else {
      //       return (
      //         !question.is_deleted &&
      //         questionIdsInNotifications.includes(question.id)
      //       );
      //     }
      //   });
      //   break;
      default:
        return null;
    }

    // ソート順に基づいて質問をソート
    filteredQuestions.sort((a, b) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      if (sortOrder === 'newest') {
        return dateB - dateA; // 最新順
      } else {
        return dateA - dateB; // 古い順
      }
    });

    // ページネーション
    const totalPages = Math.ceil(filteredQuestions.length / itemsPerPage);
    const currentItems = filteredQuestions.slice(
      (currentPage - 1) * itemsPerPage,
      currentPage * itemsPerPage
    );

    // 質問リストのレンダリング
    return (
      <>
        {currentItems.map((question) => (
          <QuestionListItem
            key={question.id}
            userId={userId}
            question_id={question.id}
            title={question.title}
            is_deleted={question.is_deleted}
            date={question.created_at}
            userName={question.user_id}
            resolved={question.resolved}
            userData={userData}
            notificationCount={notificationCount}
            notifications={notifications}
            categories={category}
            is_anonymous={question.is_anonymous}
          />
        ))}
        <Box display="flex" justifyContent="center" mt={2}>
          <Pagination
            count={totalPages}
            page={currentPage}
            onChange={(e, value) => setCurrentPage(value)}
            color="primary"
          />
        </Box>
      </>
    );
  }

  return (
    <Box sx={{ width: '100%', backgroundColor: '#f9f9f9' }}>
      {/* タブ */}
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="fullWidth"
        sx={{
          backgroundColor: '#f9f9f9',
          borderRadius: 2,
          boxShadow: '0 3px 6px rgba(0, 0, 0, 0.1)',
          '& .MuiTabs-indicator': {
            backgroundColor: '#000000',
            height: '4px',
            borderRadius: '4px',
          },
          '& .Mui-selected': {
            color: '#333333 !important',
          },
        }}
      >
        <Tab
          label="回答募集中"
          sx={{
            fontWeight: 'bold',
            padding: '12px 16px',
            color: selectedTab === 0 ? '#333333' : '#888888',
            backgroundColor: selectedTab === 0 ? '#f0f0f0' : '#f9f9f9',
            transition: 'color 0.3s ease, background-color 0.3s ease',
            '&:hover': {
              color: '#333333',
              backgroundColor: '#ffffff',
            },
          }}
        />
        <Tab
          label="解決済み"
          sx={{
            fontWeight: 'bold',
            padding: '12px 16px',
            color: selectedTab === 1 ? '#333333' : '#888888',
            backgroundColor: selectedTab === 1 ? '#f0f0f0' : '#f9f9f9',
            transition: 'color 0.3s ease, background-color 0.3s ease',
            '&:hover': {
              color: '#333333',
              backgroundColor: '#ffffff',
            },
          }}
        />
        <Tab
          label="すべて"
          sx={{
            fontWeight: 'bold',
            padding: '12px 16px',
            color: selectedTab === 2 ? '#333333' : '#888888',
            backgroundColor: selectedTab === 2 ? '#f0f0f0' : '#f9f9f9',
            transition: 'color 0.3s ease, background-color 0.3s ease',
            '&:hover': {
              color: '#333333',
              backgroundColor: '#ffffff',
            },
          }}
        />
        {/* <Tab
          label="あなたへの質問"
          sx={{
            fontWeight: 'bold',
            padding: '12px 16px',
            color: selectedTab === 3 ? '#333333' : '#888888',
            backgroundColor: selectedTab === 3 ? '#f0f0f0' : '#f9f9f9',
            transition: 'color 0.3s ease, background-color 0.3s ease',
            '&:hover': {
              color: '#333333',
              backgroundColor: '#ffffff',
            },
          }}
        /> */}
      </Tabs>

      {/* ソート順選択のトグルボタン */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          mt: 2,
          mr: 2,
          mb: 2,
        }}
      >
        <Typography variant="subtitle1" sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
          <SortIcon sx={{ mr: 0.5 }} />
          並べ替え
        </Typography>
        <ToggleButtonGroup
          value={sortOrder}
          exclusive
          onChange={(event, newValue) => {
            if (newValue !== null) {
              setSortOrder(newValue);
              setCurrentPage(1);
            }
          }}
          aria-label="Sort Order"
        >
          <ToggleButton value="newest" aria-label="Newest First">
            新しい順
          </ToggleButton>
          <ToggleButton value="oldest" aria-label="Oldest First">
            古い順
          </ToggleButton>
          <ToggleButton value="category" aria-label="Category">
            カテゴリー順
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      {/* カテゴリー表示欄 */}
      {sortOrder === 'category' && (
        <Box sx={{ mt: 2, mx: 2, mb: 4 }}>
          <Categories onSelectedTagsChange={setSelectedTags} />
        </Box>
      )}

      {/* 質問リスト */}
      {renderTabContent()}
    </Box>
  );
}

export default QuestionsList;