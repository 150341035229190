import React, { useEffect, useRef, useState } from 'react';
import Header from '../header/Header';
import {
  Box,
  Typography,
  IconButton,
  List,
  ListItem,
  Button,
  Drawer,
  FormControl,
  Select,
  MenuItem,
  Slider,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Avatar,
} from '@mui/material';
import {
  Delete as DeleteIcon,
  Send as SendIcon,
  Settings as SettingsIcon
} from '@mui/icons-material';
import axios from 'axios';
import CustomLogger from '../logger/Logger';
import { useLocation, useNavigate } from 'react-router-dom';
import { Person as PersonIcon } from '@mui/icons-material';
import { marked } from 'marked';
import { TextField, InputAdornment } from '@mui/material';


const ChatWindow = () => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([{ isUser: false, text: "以下から選択してください" }]);
  const [choices, setChoices] = useState(["チャットで聞きたい", "マニュアルをもとに聞きたい", "蓄積されたQAをもとに聞きたい"]);
  const [additionalChoices, setAdditionalChoices] = useState([]);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [historyCount, setHistoryCount] = useState(0);
  const [model, setModel] = useState("gpt-4o-mini");
  const [temperature, setTemperature] = useState(1.0);
  const [hasHistory, setHasHistory] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const logger = new CustomLogger();
  const messagesEndRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState("");
  const location = useLocation();
  const { notificationCount, notifications, userData, category } = location.state || {};
  const navigate = useNavigate();
  const { userId } = location.state
  const iconRef = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [arrowLeft, setArrowLeft] = useState(0);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [showResolutionOptions, setShowResolutionOptions] = useState(false);
  const [showFurtherOptions, setShowFurtherOptions] = useState(false);
  const [isQuestionInput, setIsQuestionInput] = useState(false);

  // 問題が解決したかの選択肢に応じた処理を行う関数
  const handleResolutionChoice = (choice) => {
    console.log(`Resolution choice: ${choice}`);
    setShowResolutionOptions(false);
  };

  // 次の行動を選択するための関数
  const handleFurtherChoice = (choice) => {
    console.log(`Further choice: ${choice}`);
    setShowFurtherOptions(false);
  };

  // 設定画面の設定を読み込む
  useEffect(() => {
    console.log('設定を読み込みます');
    fetchSettings();
  }, []);

  // メッセージの履歴を読み込む
  useEffect(() => {
    // 初期化時に実行
    // Cookieから取得
    const storedMessages = getCookie('messages');
    if (storedMessages) {
      // 取得したデータをパースしてstateにセット
      setMessages(JSON.parse(storedMessages));
      setHasHistory(true);
    }
  }, []);

  // メッセージが更新されたらスクロールを最下部に移動
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // ユーザーIDが取得できない場合の処理をフック内で行う
  useEffect(() => {
    if (!userId) {
      // 必要に応じてログインページにリダイレクト
      navigate('/login');
    }
  }, [userId, navigate]);

  // 削除ボタンをクリックしたときに確認ダイアログを開く
  const handleDeleteClick = () => {
    setConfirmDeleteOpen(true);
  };

  // 削除確認ダイアログを閉じる
  const handleConfirmDeleteClose = () => {
    setConfirmDeleteOpen(false);
  };

  // 確認ダイアログで「はい」を押したときの処理
  const handleConfirmDelete = () => {
    handleDeleteHistory(); // 履歴を削除する
    setConfirmDeleteOpen(false); // ダイアログを閉じる
  };

  // 設定ボタンをクリックしたときの処理
  const handleSettingsClick = () => {
    setSettingsOpen(!settingsOpen);
  };

  // モデルの変更時の処理
  const handleHistoryCountChange = (event, newValue) => {
    setHistoryCount(newValue);
  };

  // 温度の変更時の処理
  const handleTemperatureChange = (event, newValue) => {
    setTemperature(newValue);
  };

  // メッセージを送信したときの処理
  const handleSendMessage = async () => {
    // ローディング開始
    setIsLoading(true);

    // 質問IDを初期化
    let questionId = null;

    // メッセージが空の場合は何もしない
    if (message.trim() !== '') {
      // メッセージリストに追加
      const updatedMessages = [...messages, { isUser: true, text: message }];
      setMessages(updatedMessages);
      setHasHistory(true);

      try {
        // メッセージが選択肢でないときだけ、質問IDを更新
        if (messages[1] && (messages[1].text === '通常のChatGPTに質問する' || messages[1].text === '社内文書用ChatGPTに質問する')) {
          console.log('メッセージをサーバーに送信して保存:', message);
          const res = await axios.post(`/backend/save_chat_question`, {
            userId: userId,
            text: message,
            withCredentials: true
          });
          questionId = res.data.id;
        }

        // 新たなリクエストを作成して、設定やメッセージをバックエンドに送る
        let chatRes;

        // 最新の特定のメッセージを探す
        const specialMessages = ["通常のChatGPTに質問する", "社内文書用ChatGPTに質問する"];
        let latestMessage = messages.slice().reverse().find(msg => specialMessages.includes(msg.text));

        // メッセージが見つからない場合、デフォルトの処理を行う
        if (!latestMessage) {
          latestMessage = { text: '' };
        } else {
          latestMessage = latestMessage.text;
        }
        const messageType = latestMessage === '社内文書用ChatGPTに質問する' ? 'internal' : 'standard';

        // ChatGPTへ質問を送信
        if (latestMessage === '通常のChatGPTに質問する') {
          console.log('通常のChatGPTに質問します');

          // メッセージリストから特定のメッセージと最新のメッセージを除外
          const filteredMessages = updatedMessages.filter((msg, index) => {
            return msg.text !== "以下から選択してください" &&
              msg.text !== "通常のChatGPTに質問する" &&
              index !== updatedMessages.length - 1;
          });
          console.log(filteredMessages.slice(-15));

          // 通常のChatGPTへ質問を送信
          chatRes = await axios.post(`/backend/send_chat_question`, {
            userId: userId,
            questionId: questionId,
            settings: { model, historyCount, temperature },
            text: message,
            messages: filteredMessages.slice(-15),
            withCredentials: true
          });
        } else if (latestMessage === '社内文書用ChatGPTに質問する') {
          console.log('社内文書用ChatGPTに質問します');

          // メッセージリストから特定のメッセージと最新のメッセージを除外
          const filteredMessages = updatedMessages.filter((msg, index) => {
            return msg.text !== "以下から選択してください" &&
              msg.text !== "社内文書用ChatGPTに質問する" &&
              index !== updatedMessages.length - 1;
          });
          console.log(filteredMessages.slice(-15));

          // 社内文書用ChatGPTへ質問を送信
          chatRes = await axios.post(`/backend/send_chat_question_internal_documents`, {
            userId: userId,
            questionId: questionId,
            settings: { model, historyCount, temperature },
            text: message,
            messages: filteredMessages.slice(-15),
            withCredentials: true
          });
        }

        // レスポンスをチェックして、メッセージリストとCookieに追加
        if (chatRes.status === 200) {
          // レスポンスからメッセージを取得
          const newMessage = { isUser: false, text: chatRes.data, type: messageType };

          // メッセージリストに追加
          setMessages(prevMessages => [...prevMessages, newMessage]);

          // 最新の15メッセージのみを保持する
          const messagesToStore = [...updatedMessages, newMessage].slice(-15);

          // メッセージをCookieに保存
          document.cookie = `messages=${JSON.stringify(messagesToStore)}; path=/`;
        } else {
          setErrorMessage('エラーが発生しました。もう一度質問してください。');
          console.error('メッセージの受信中にエラーが発生しました:', chatRes.status);
        }
      } catch (error) {
        if (error.response && error.response.status === 504) {
          setErrorMessage("サーバーからの応答がタイムアウトしました。しばらくしてから再試行してください。");
        } else {
          setErrorMessage("メッセージの送信中に未知のエラーが発生しました。再試行してください。");
        }
        logger.error('メッセージの送信中にエラーが発生しました:', error);
      }
      // 入力フィールドをクリア
      setMessage('');
      setIsLoading(false); // ローディング終了
    }
  };

  // DBに初期設定を挿入
  const insertDefaultSettings = async () => {
    try {
      console.log('chatGPTの初期設定をDBに挿入します');
      const res = await axios.post(`/backend/save_user_settings`,
        {
          userId: userId,
          model: model,
          historyCount: historyCount,
          temperature: temperature,
          withCredentials: true
        }
      );
      console.log(res);
    } catch (error) {
      console.error('初期設定の挿入中にエラーが発生しました:', error);
    }
  }


  // ファイルをダウンロード
  const handleFileDownload = async (filename) => {
    console.log(`Attempting to download file: ${filename}`);
    try {
      const res = await axios.post('/backend/download_pdf_file', {
        uniqueFileName: filename
      }, { responseType: 'blob' });

      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Download error:', error);
      alert('ファイルのダウンロードに失敗しました。');
    }
  };

  // コンテンツを描画
  const renderMessageContent = (text) => {
    const sourcePrefix = "ソース:";
    const pagePrefix = "ページ:";
    let formattedText = text;
    let lastIndex = 0;
    const elements = [];

    // 複数の参照を処理するためのループ
    while (formattedText.includes(sourcePrefix, lastIndex) && formattedText.includes(pagePrefix, lastIndex)) {
      const sourceIndex = formattedText.indexOf(sourcePrefix, lastIndex) + sourcePrefix.length;
      const pageIndex = formattedText.indexOf(pagePrefix, lastIndex);
      const filename = formattedText.substring(sourceIndex, pageIndex).trim();

      // ソースまでのテキストを追加
      elements.push(formattedText.substring(lastIndex, formattedText.indexOf(sourcePrefix, lastIndex)));

      // リンク部分を追加
      if (/^[\w,\s-]+\.[A-Za-z]{3}$/.test(filename)) {
        // ファイル名が `test_stres.pdf` のような形式の場合はリンクとして扱う
        elements.push(
          <a href="#" onClick={(e) => {
            e.preventDefault();
            handleFileDownload(filename);
          }} style={{ color: 'blue', textDecoration: 'underline' }}>
            {filename}
          </a>
        );
      } else {
        // それ以外の形式の場合は普通のテキストとして扱う
        elements.push(
          <span>
            {filename}
          </span>
        );
      }

      // ページ情報を次の行に表示
      elements.push(
        <br />,
        formattedText.substring(pageIndex, formattedText.indexOf('\n', pageIndex) + 1)
      );
      lastIndex = formattedText.indexOf('\n', pageIndex) + 1;  // 次の行から再開
    }

    // 最後のテキスト部分を追加
    elements.push(formattedText.substring(lastIndex));

    return <>{elements}</>;
  };


  // バックエンドから設定データを取得
  const fetchSettings = async () => {
    try {
      console.log('chatGPTの設定を取得します');
      const res = await axios.post(`/backend/get_user_settings`,
        {
          userId: userId,
          withCredentials: true
        });

      const { model, historyCount, temperature } = res.data;

      if (res.status === 200 && model && historyCount && temperature) {
        // 設定が存在すれば状態に保存
        setModel(model);
        setHistoryCount(historyCount);
        setTemperature(temperature);
      } else {
        // 設定が存在しなければ初期値をDBに挿入
        insertDefaultSettings();
      }
    } catch (error) {
      console.error('設定の取得中にエラーが発生しました:', error);
      // エラーが発生した場合でも初期設定を挿入
      insertDefaultSettings();
    }
  }

  // chatgptの設定を保存する
  function handleSaveSettings() {
    console.log('chatGPTの設定を保存します');
    axios.post(`/backend/save_user_settings`,
      {
        userId: userId,
        model: model,
        historyCount: historyCount,
        temperature: temperature,
        withCredentials: true
      }
    )
      .then((response) => {
        console.log('設定を保存しました:', response);
      })
      .catch((error) => {
        logger.error('設定の保存中にエラーが発生しました:', error);
      });
  }

  // クッキーから設定を読み込む
  function getCookie(name) {
    // "name=value"形式の文字列の配列を取得
    const cookieArray = document.cookie.split('; ');
    // 指定した名前のクッキーを探す
    for (let cookieString of cookieArray) {
      const [cookieName, cookieValue] = cookieString.split('=');
      if (cookieName === name) {
        return cookieValue;
      }
    }
    return null;
  }

  // 履歴を削除したときの処理
  function handleDeleteHistory() {
    // メッセージリストをクリア
    setMessages([]);
    // 履死を削除されたので、hasHistoryをfalseに設定
    setHasHistory(false);
    // Cookieからメッセージの履歴を削除
    document.cookie = "messages=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // ChatWindowを初期状態に戻す
    setMessages([{ isUser: false, text: "以下から選択してください" }]);
    setChoices([]);
    setAdditionalChoices([]);
    setChoices(["チャットで聞きたい", "マニュアルをもとに聞きたい", "蓄積されたQAをもとに聞きたい"]);
  };

  // 選択肢を選択したときの処理
  function handleChoice(choice) {
    setMessages([...messages, { isUser: true, text: choice }]);
    if (choice === "チャットで聞きたい") {
      // 選択肢を更新
      setAdditionalChoices(["通常のChatGPTに質問する", "社内文書用ChatGPTに質問する"]);
      setChoices([]);
    } else {
      // 選択肢をクリア
      setAdditionalChoices([]);
      setChoices(null);
    }
  }

  // 設定画面の設定を読み込む
  useEffect(() => {
    console.log('設定を読み込みます');
    fetchSettings();
  }, []);

  // メッセージの履歴を読み込む
  useEffect(() => {
    // 初期化時に実行
    // Cookieから取得
    const storedMessages = getCookie('messages');
    if (storedMessages) {
      // 取得したデータをパースしてstateにセット
      setMessages(JSON.parse(storedMessages));
      setHasHistory(true);
    }
  }, []);

  // メッセージが更新されたらスクロールを最下部に移動
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  return (
    <div>
      {/* ヘッダー */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, paddingTop: '10px' }}>
        <Header
          searchText={searchText}
          notificationCount={notificationCount}
          id={id}
          iconRef={iconRef}
          arrowLeft={arrowLeft}
          notifications={notifications}
          userId={userId}
          userData={userData}
          categories={category}
        />
        <Box
          display="flex"
          justifyContent="flex-start"
          my={0}
          mx="auto"
          width="100%"
          py="35px"
        ></Box>
      </Box>

      {/* チャットウィンドウ */}
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        height: 'calc(100vh - 120px)',
        marginTop: '10px',
      }}>
        {/* ヘッダー */}
        <Box
          className="chat-header"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            bgcolor: '#f0f0f0',
            px: 3,
            py: 2,
            borderBottom: '2px solid #e0e0e0',
            boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.1)',
          }}
        >
          {/* 設定ボタン */}
          <IconButton onClick={handleSettingsClick}>
            <SettingsIcon
              sx={{
                color: '#555555',
                fontSize: '28px',
                transition: 'color 0.3s ease',
                '&:hover': {
                  color: '#000000',
                },
              }}
            />
          </IconButton>

          <Typography
            variant="h6"
            sx={{
              color: '#333333',
              fontWeight: 'bold',
              fontSize: '1.25rem',
              textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)',
            }}
          >
            チャットで質問
          </Typography>

          <Box>
            <IconButton onClick={handleDeleteClick} disabled={!hasHistory}>
              <DeleteIcon
                sx={{
                  color: hasHistory ? '#555555' : '#cccccc',
                  fontSize: '28px',
                  transition: 'color 0.3s ease',
                  '&:hover': {
                    color: hasHistory ? '#000000' : '#cccccc',
                  },
                }}
              />
            </IconButton>
          </Box>
        </Box>

        {/* 設定ドロワーを追加 */}
        <Drawer anchor="right" open={settingsOpen} onClose={handleSettingsClick}>
          <Box sx={{ width: 250, p: 2 }}>
            <Typography variant="h6">ChatGPT設定</Typography>

            <Box mb={4}>
              <Typography variant="subtitle1">モデル: {model}</Typography>
              <FormControl fullWidth>
                <Select
                  value={model}
                  onChange={(e) => setModel(e.target.value)}
                >
                  <MenuItem value="gpt-3.5-turbo" disabled>gpt-3.5-turbo</MenuItem>
                  <MenuItem value="gpt-3.5-turbo-16k" disabled>gpt-3.5-turbo-16k</MenuItem>
                  <MenuItem value="gpt-4o-mini">gpt-4.0-mini</MenuItem>
                  <MenuItem value="gpt-4" disabled>gpt-4</MenuItem>
                  <MenuItem value="gpt-4-32k" disabled>gpt-4-32k</MenuItem>
                </Select>
              </FormControl>
            </Box>

            <Box mb={4}>
              <Typography variant="subtitle1">履歴数: {historyCount}</Typography>
              <Slider
                value={historyCount}
                min={0}
                max={5}
                step={1}
                onChange={handleHistoryCountChange}
                valueLabelDisplay="auto"
              />
            </Box>

            <Box mb={2}>
              <Typography variant="subtitle1">Temperature: {temperature.toFixed(1)}</Typography>
              <Slider
                value={temperature}
                min={0}
                max={2}
                step={0.1}
                onChange={handleTemperatureChange}
                valueLabelDisplay="auto"
              />
            </Box>

            <Button
              variant="contained"
              color="primary"
              onClick={handleSaveSettings}
            >
              設定を保存
            </Button>
          </Box>
        </Drawer>

        {/* メッセージ表示エリア */}
        <Box
          sx={{
            flex: 1,
            overflow: 'auto',
            position: 'relative',
            px: 2,
            py: 2,
            backgroundColor: '#f5f5f5',
          }}
        >
          {isLoading && (
            <Box
              sx={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                zIndex: 9999,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                borderRadius: '10px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
              }}
            >
              <CircularProgress size={60} />
              <Typography variant="subtitle1" sx={{ marginTop: '20px', fontWeight: 'bold' }}>
                処理中です、しばらくお待ちください...
              </Typography>
            </Box>
          )}
          <List>
            {messages.map((msg, index) => (
              <ListItem
                key={index}
                sx={{
                  justifyContent: msg.isUser ? 'flex-end' : 'flex-start',
                  marginBottom: '8px',
                  alignItems: 'flex-start',
                }}
              >
                {!msg.isUser && (
                  <img
                    src="character.png"
                    alt="character"
                    style={{
                      width: '40px',
                      height: 'auto',
                      marginRight: '8px',
                      cursor: 'pointer',
                      transition: 'transform 0.3s ease',
                      objectFit: 'contain',
                      objectPosition: 'center',
                    }}
                    onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.05)')}
                    onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
                  />
                )}
                <Box
                  sx={{
                    position: 'relative',
                    backgroundColor: msg.isUser ? '#81c784' : '#ffffff',
                    borderRadius: '12px',
                    maxWidth: '60%',
                    padding: '8px 16px',
                    boxShadow: '0 2px 6px rgba(0,0,0,0.1)',
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    display: 'inline-block',
                  }}
                >
                  {msg.type === 'internal' ? (
                    <div style={{ whiteSpace: 'pre-wrap', lineHeight: '1.2' }}>
                      {renderMessageContent(msg.text)}
                    </div>
                  ) : (
                    <div
                      style={{
                        whiteSpace: 'pre-wrap',
                        lineHeight: '1.2',
                        margin: 0,
                        padding: '4px 0',
                        display: 'table',
                        verticalAlign: 'middle',
                      }}
                      dangerouslySetInnerHTML={{
                        __html: marked(msg.text.trim()),
                      }}
                    />
                  )}
                </Box>
                {msg.isUser && (
                  <Avatar
                    alt="User"
                    sx={{ width: 40, height: '40', marginLeft: '8px', backgroundColor: '#2196f3' }}
                  >
                    <PersonIcon style={{ color: '#ffffff' }} />
                  </Avatar>
                )}
              </ListItem>
            ))}
            <div ref={messagesEndRef} />
          </List>
        </Box>

        {/* メッセージ入力エリアまたは選択肢を表示 */}
        {(!choices && !showResolutionOptions && !showFurtherOptions) || isQuestionInput ? (
          // メッセージ入力エリア
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: 2,
              backgroundColor: 'rgba(255, 255, 255, 0.9)', // 背景を半透明に調整
              borderTop: '1px solid #ccc',
            }}
          >
            <TextField
              variant="outlined"
              placeholder="メッセージを入力してください"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              fullWidth
              multiline
              maxRows={4}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleSendMessage}
                      sx={{
                        bgcolor: '#03a9f4',
                        '&:hover': { bgcolor: '#0288d1' },
                        color: '#ffffff',
                        marginLeft: '8px',
                      }}
                    >
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                sx: {
                  borderRadius: '30px',
                },
              }}
            />
          </Box>
        ) : showResolutionOptions ? (
          // 「問題は解決しましたか？」の選択肢を表示
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 2,
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              borderTop: '1px solid #ccc',
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              問題は解決しましたか？
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <Button
                variant="contained"
                onClick={() => handleResolutionChoice('はい')}
                sx={{
                  m: 1,
                  textTransform: 'none',
                  fontWeight: 'bold',
                  borderRadius: '30px',
                  backgroundColor: '#4caf50',
                  color: '#ffffff',
                  '&:hover': {
                    backgroundColor: '#388e3c',
                  },
                }}
              >
                はい
              </Button>
              <Button
                variant="contained"
                onClick={() => handleResolutionChoice('いいえ')}
                sx={{
                  m: 1,
                  textTransform: 'none',
                  fontWeight: 'bold',
                  borderRadius: '30px',
                  backgroundColor: '#f44336',
                  color: '#ffffff',
                  '&:hover': {
                    backgroundColor: '#d32f2f',
                  },
                }}
              >
                いいえ
              </Button>
            </Box>
          </Box>
        ) : showFurtherOptions ? (
          // 「どうしますか？」の選択肢を表示
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 2,
              backgroundColor: 'rgba(255, 255, 255, 0.9)',
              borderTop: '1px solid #ccc',
            }}
          >
            <Typography variant="h6" sx={{ mb: 2 }}>
              どうしますか？
            </Typography>
            <Box sx={{ display: 'flex' }}>
              <Button
                variant="contained"
                onClick={() => handleFurtherChoice('チャットで質問する')}
                sx={{
                  m: 1,
                  textTransform: 'none',
                  fontWeight: 'bold',
                  borderRadius: '30px',
                  backgroundColor: '#03a9f4',
                  color: '#ffffff',
                  '&:hover': {
                    backgroundColor: '#0288d1',
                  },
                }}
              >
                チャットで質問する
              </Button>
              <Button
                variant="contained"
                onClick={() => handleFurtherChoice('他の人に質問する')}
                sx={{
                  m: 1,
                  textTransform: 'none',
                  fontWeight: 'bold',
                  borderRadius: '30px',
                  backgroundColor: '#ff9800',
                  color: '#ffffff',
                  '&:hover': {
                    backgroundColor: '#fb8c00',
                  },
                }}
              >
                他の人に質問する
              </Button>
            </Box>
          </Box>
        ) : (
          // 選択肢を表示
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 2,
              backgroundColor: 'rgba(255, 255, 255, 0.9)', // 背景を半透明に調整
              borderTop: '1px solid #ccc',
            }}
          >
            {choices.map((choice, index) => (
              <Button
                key={index}
                variant="contained"
                onClick={() => handleChoice(choice)}
                disabled={choice === "蓄積されたQAをもとに聞きたい" || choice === "マニュアルをもとに聞きたい"}
                sx={{
                  m: 1,
                  p: 1,
                  textTransform: 'none',
                  height: '50px',
                  fontWeight: 'bold',
                  borderRadius: '30px',
                  backgroundColor: '#03a9f4',
                  color: '#ffffff',
                  '&:hover': {
                    backgroundColor: '#0288d1',
                  },
                  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                }}
              >
                {choice}
              </Button>
            ))}
            {additionalChoices.map((choice, index) => (
              <Button
                key={index}
                variant="contained"
                onClick={() => handleChoice(choice)}
                sx={{
                  m: 1,
                  p: 1,
                  textTransform: 'none',
                  height: '50px',
                  fontWeight: 'bold',
                  borderRadius: '30px',
                  backgroundColor: '#03a9f4',
                  color: '#ffffff',
                  '&:hover': {
                    backgroundColor: '#0288d1',
                  },
                  boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)',
                }}
              >
                {choice}
              </Button>
            ))}
          </Box>
        )}
        {errorMessage && (
          <Typography color="error" sx={{ px: 2, py: 1 }}>
            {errorMessage}
          </Typography>
        )}
      </Box>

      {/* 削除確認ダイアログ */}
      <Dialog
        open={confirmDeleteOpen}
        onClose={handleConfirmDeleteClose}
        aria-labelledby="confirm-delete-dialog-title"
        aria-describedby="confirm-delete-dialog-description"
      >
        <DialogTitle id="confirm-delete-dialog-title">削除の確認</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-delete-dialog-description">
            本当に削除してもよろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmDeleteClose} color="primary">
            いいえ
          </Button>
          <Button onClick={handleConfirmDelete} color="primary" autoFocus>
            はい
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ChatWindow;