import PersonIcon from '@mui/icons-material/Person';
import { Button } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import 'draft-js/dist/Draft.css';
import { React, useEffect, useRef, useState } from 'react';
import { useCookies } from "react-cookie";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../header/Header';
import CustomLogger from '../logger/Logger';
import MenuButtons from '../menubuttons/MenuButtons';
import RelatedQuestions from '../relatedquestions/RelatedQuestions';
import AnswerEditor from './AnswerEditor';
import AnswerForm from './AnswerForm';
import AnswerList from './AnswerList';
import CommentEditor from './CommentEditor';
import CommentList from './CommentList';


export default function Answer() {
    const navigate = useNavigate();
    const location = useLocation();
    const { notificationCount, notifications, userData, category } = location.state || {};
    const [userId, setUserId] = useState(null);
    const [question, setQuestion] = useState();
    const [title, setTitle] = useState('')
    const [fileName, setFileName] = useState('');
    const [questionId, setQuestionId] = useState('');
    const [answerCommentEditorProps, setAnswerCommentEditorProps] = useState({});
    const [editing, setEditing] = useState(true)
    const [answers, setAnswers] = useState([]);
    const [answerId, setAnswerId] = useState([]);
    const [comments, setComments] = useState([]);
    const [replycomments, setReplyComments] = useState([]);
    const [repliedCommentId, setRepliedCommentId] = useState([]);
    const [editingAnswerId, setEditingAnswerId] = useState(null);
    const [editingAnswer, setEditingAnswer] = useState({ id: null, isEditing: false });
    const [showCommentsForAnswer, setShowCommentsForAnswer] = useState({});
    const [cookies, setCookie, removeCookie] = useCookies();
    const [newToken, setNewToken] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [arrowLeft, setArrowLeft] = useState(0);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const iconRef = useRef(null);
    const [tags, setTags] = useState([]);
    const [lastRepliedCommentId, setLastRepliedCommentId] = useState(null);
    const [relatedQuestionsData, setRelatedQuestionsData] = useState([]);
    const logger = new CustomLogger();
    const [questionName, setQuestioName] = useState([]);
    const [questionUserId, setQuestionUserId] = useState();
    const [questionUserIcon, setQuestionUserIcon] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [resolved, setResolved] = useState(false);
    const [isAnonymous, setIsAnonymous] = useState(false);
    const isUserAnonymous = isAnonymous && userData.role !== 'admin';
    const isAdmin = userData.role === 'admin';
    const displayUserName = isAnonymous
        ? isAdmin
            ? `${questionName || questionUserId} (匿名投稿)`
            : '匿名ユーザー'
        : questionName || questionUserId;

    // 画像とファイルの状態を定義
    const [images, setImages] = useState([]);
    const [files, setFiles] = useState([]);
    const handleEditing = (e) => {
        setEditing(!editing);
    };
    const handleEditingComments = (answerId) => {
        if (editingAnswer.id === answerId) {
            setEditingAnswer({ id: answerId, isEditing: !editingAnswer.isEditing });
        } else {
            setEditingAnswer({ id: answerId, isEditing: true });
        }
    };
    // スタイルペーパの設定などの関数
    const StyledPaper = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        color: theme.palette.text.primary,
    }));

    // 受け取ったマークアップをマークアップとして表示する関数
    const DisplayMarkup = ({ html }) => {
        const globalStyle = `
            table {
                border-collapse: collapse;
                width: 100%;
            }

            th, td {
                border: 1px solid black;
                padding: 8px;
                text-align: left;
            }

            pre {
                background-color: #f5f5f5;
                border: 1px solid #ddd;
                padding: 10px;
                white-space: pre-wrap;
            }

            code {
                font-family: 'Courier New', Courier, monospace;
            }

            blockquote {
                border-left: 4px solid #ccc;
                margin: 0 0 0 10px;
                padding: 0 15px;
                color: #666;
                font-style: italic;
            }

            img {
                max-width: 100%; /* 親要素の幅に収まる */
                height: auto;    /* アスペクト比を維持 */
                display: block;  /* ブロック要素にすることで、余分な余白を排除 */
                margin: 0 auto;  /* 中央寄せにしたい場合は有効 */
            }
        `;

        return (
            <>
                <style dangerouslySetInnerHTML={{ __html: globalStyle }} />
                <div dangerouslySetInnerHTML={{ __html: html }} />
            </>
        );
    };

    // ユーザー画面に移動する
    const moveUsers = () => {
        if (isUserAnonymous) {
            // 匿名ユーザーの場合は何もしない
            return;
        }
        console.log("ユーザー画面に移動する");
        navigate('/user', {
            state: {
                userId: userId,
                notificationCount: notificationCount,
                notifications: notifications,
                category: category,
                pageId: questionUserId
            }
        })
    };

    // 解決ページに遷移する関数
    const navigateToSolution = (
        questionId,
        userName,
        notificationCount,
        notifications,
        userData
    ) => {
        console.log('解決ページに遷移');
        navigate('/Solution', {
            state: {
                userId: userId,
                questionUserId: userName,
                questionId: questionId,
                notificationCount: notificationCount,
                notifications: notifications,
                userData: userData,
            },
            replace: true,
        });
    };

    // ファイルをダウンロードする関数
    const downloadFile = async (fileIdentifier) => {
        console.log('ダウンロードするファイル:', fileIdentifier);
        try {
            const response = await axios.post(
                `/backend/download`,
                {
                    fileName: fileIdentifier
                },
                {
                    withCredentials: true,
                    responseType: 'arraybuffer'
                }
            );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', extractFileName(fileIdentifier));
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        } catch (error) {
            console.error('File download failed:', error);
        }
    };

    // ファイル名を取得する関数
    function extractFileName(str) {
        const regex = /.*?_([^_]+\.\w+)$/;
        const match = str.match(regex);
        return match ? match[1] : str;
    }

    // 画面遷移時にstateを取得する
    useEffect(() => {
        console.log('画面遷移時にset:', location.state);
        if (location.state) {
            setUserId(location.state.userId);
            setQuestionUserId(location.state.questionUserId)
            setQuestionId(location.state.questionId);
        }
    }, [location.state]);

    // 画面が再描画される度に質問を取得するようにする
    useEffect(() => {
        async function fetchQuestion(question_id) {
            const res = await axios.post(`/backend/getquestion`, { question_id, withCredentials: true });
            console.log(res);
            setQuestion(res.data.html_question);
            setTitle(res.data.title);
            setFileName(res.data.file_name);
            setImages(res.data.images);
            setFiles(res.data.files);
            setQuestionUserId(res.data.user_id);
            setResolved(res.data.is_deleted);
            setIsAnonymous(res.data.is_anonymous);
        }

        if (questionId) {
            fetchQuestion(questionId);
        }
    }, [questionId]);

    // 既存のfile_nameと新しい画像・ファイルリストを表示するロジック
    const renderFileLinks = () => {
        const links = [];

        // 古いfile_nameが存在する場合はリンクを追加
        if (fileName) {
            links.push(
                <div key="old-file">
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(fileName);
                    }}>{extractFileName(fileName)}</a>
                </div>
            );
        }

        // 新しい画像ファイルのリンクを追加
        images.forEach((image, index) => {
            links.push(
                <div key={`image-${index}`}>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(image.file_name);
                    }}>{extractFileName(image.file_name)}</a>
                </div>
            );
        });

        // 新しいその他のファイルのリンクを追加
        files.forEach((file, index) => {
            links.push(
                <div key={`file-${index}`}>
                    <a href="#" onClick={(e) => {
                        e.preventDefault();
                        downloadFile(file.file_name);
                    }}>{extractFileName(file.file_name)}</a>
                </div>
            );
        });

        return links;
    };

    // 画面が再描画される度に回答を取得するようにする
    useEffect(() => {
        console.log('質問IDより回答取得:', questionId);
        async function fetchAnswers() {
            const res = await axios.post(
                `/backend/getanswer`,
                {
                    question_id: questionId,
                    withCredentials: true
                }
            );
            console.log(res)
            setAnswers(res.data);
            console.log(res.data)

            setAnswerId(res.data.map(answer => answer.id));
            console.log(res.data.map(answer => answer.id))
        }
        if (questionId) {
            fetchAnswers();
        }
    }, [questionId]);


    // 画面が再描画される度にコメントを取得するようにする
    useEffect(() => {
        console.log('回答IDでコメントを取得:', answerId);
        async function fetchComments() {
            // answerIdでループ処理
            const promises = answerId.map(async (id) => {
                console.log(id)
                const res = await axios.post(
                    `/backend/getcomment`, {
                    answer_id: id,
                    withCredentials: true
                });
                console.log(id)
                console.log(res);
                console.log(res.data);
                res.data.forEach((comment) => {
                    setRepliedCommentId((prevRepliedCommentIds) => [
                        ...prevRepliedCommentIds,
                        comment.replied_comment_id,
                    ]);
                });
                return res.data;
            });

            // すべてのPromiseが完了するまで待機し、結果をまとめる
            const results = await Promise.all(promises);
            console.log(results)
            setComments([...comments, ...results.flat()]);
        }
        if (answerId.length) {
            fetchComments();
        }
    }, [answerId]);

    // 画面が再描画される度に返信コメントを取得するようにする
    useEffect(() => {
        console.log('コメントIDでその返信のコメントを取得:', repliedCommentId);

        async function fetchReplyComments() {
            const promises = repliedCommentId.map(async (id) => {
                try {
                    const res = await axios.post(
                        `/backend/getreplycomment`,
                        {
                            replied_comment_id: id,
                            withCredentials: true
                        }
                    );
                    if (res.data && res.data.length === 0) {
                        console.log("返信コメントは存在しません");
                    }
                    return res.data;
                } catch (error) {
                    console.error("Error fetching reply comments:", error.response.data);
                    return [];
                }
            });

            const results = await Promise.all(promises);
            setReplyComments([...replycomments, ...results.flat()]);
            if (replycomments.length === 0) {
                setReplyComments([...replycomments, ...[0, 0, 0]]);
            }
        };

        if (repliedCommentId.length) {
            fetchReplyComments();
        }
    }, [repliedCommentId]);


    // 画面が再描画される度にタグを取得するようにする
    useEffect(() => {
        console.log('質問IDでタグを取得:', questionId);
        async function getTags() {
            const res = await axios.post(
                `/backend/gettags`,
                {
                    questionId: questionId,
                    withCredentials: true
                }
            );
            console.log(res)
            setTags(res.data);
        }
        if (questionId) {
            getTags();
        }
    }, [questionId]);

    // 　ユーザー情報を取得する
    useEffect(() => {
        if (isUserAnonymous) {
            // 匿名ユーザーの場合はユーザー情報を取得しない
            return;
        }
        console.log('ユーザー情報を取得します')
        async function getusers() {
            const res = await axios.post(
                `/backend/getusers`,
                {
                    user_id: questionUserId,
                    withCredentials: true
                }
            );
            console.log(res)
            setQuestioName(res.data.name);
            setQuestionUserIcon(res.data.icon);
        }
        if (questionUserId) {
            getusers();
        }
    }, [questionUserId, isUserAnonymous]);

    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, paddingTop: '10px' }}>
                <Header
                    searchText={searchText}
                    notificationCount={notificationCount}
                    id={id}
                    iconRef={iconRef}
                    arrowLeft={arrowLeft}
                    notifications={notifications}
                    userId={userId}
                    userData={userData}
                    categories={category}
                />
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    my={0}
                    mx="auto"
                    width="100%"
                    py="35px"
                ></Box>
            </Box>

            <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: "100vh", backgroundColor: '#f2f2f2', padding: '16px', overflowX: 'hidden' }}>
                <Grid item xs={12}>
                    {/* 質問ボックス */}
                    <Box bgcolor="white" p={3} borderRadius={3} boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)" mb={4}
                        sx={{
                            padding: '16px',
                        }}>
                        <Typography variant="h4" fontWeight="bold">質問</Typography>
                        <StyledPaper sx={{ mt: 2, p: 2 }}>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item>
                                    <Avatar onClick={moveUsers} sx={{ cursor: isUserAnonymous ? 'default' : 'pointer' }}>
                                        {isUserAnonymous ? <PersonIcon /> : questionUserIcon !== "null" ? (
                                            <img src={questionUserIcon} style={{ maxWidth: '100%', height: 'auto' }} alt="user icon" />
                                        ) : (
                                            <PersonIcon />
                                        )}
                                    </Avatar>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6">{displayUserName}</Typography>
                                </Grid>
                            </Grid>
                            <Typography variant="h5" fontWeight="bold" sx={{ mt: 2 }}>{title}</Typography>
                            <DisplayMarkup html={question} />
                            {tags && tags.map((tag, index) => (
                                <Chip key={index} label={tag} sx={{ ml: 1, mt: 1 }} />
                            ))}
                            {renderFileLinks && (
                                <Box mt={2}>
                                    {renderFileLinks()}
                                </Box>
                            )}
                        </StyledPaper>
                    </Box>

                    {/* 回答セクション */}
                    <Box bgcolor="white" p={3} borderRadius={3} boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)" sx={{
                        padding: '16px',
                    }}>
                        <Typography variant="h4" fontWeight="bold">回答 ({answers ? answers.length : 0}件)</Typography>
                        {answers.map((answer, index) => {
                            const shouldDisplayAnswer = userData.role === 'admin' || answer.is_deleted === false || answer.user_id === userData.id;
                            if (!shouldDisplayAnswer) return null;

                            return (
                                <StyledPaper key={index} sx={{ mt: 2, p: 2 }}>
                                    <AnswerList
                                        questionId={questionId}
                                        userId={userId}
                                        setAnswerCommentEditorProps={setAnswerCommentEditorProps}
                                        handleEditing={handleEditingComments}
                                        answer={answer}
                                        userData={userData}
                                        fileName={answer.file_name}
                                        answerUserId={answer.user_id}
                                        is_deleted={answer.is_deleted}
                                        resolved={resolved}
                                        notificationCount={notificationCount}
                                        notifications={notifications}
                                        categories={category}
                                    />
                                    <Button
                                        onClick={() => {
                                            setShowCommentsForAnswer(prev => ({
                                                ...prev,
                                                [answer.id]: !prev[answer.id],
                                            }));
                                        }}
                                    >
                                        {`コメント ${comments.flat().filter(comment => comment.answer_id === answer.id).length} 件`}
                                    </Button>
                                    <Collapse in={showCommentsForAnswer[answer.id] || false}>
                                        {comments.filter(comment => comment.answer_id === answer.id).map((filteredComment, commentIndex) => {
                                            const shouldDisplayComment = userData.role === 'admin' || filteredComment.is_deleted === false || filteredComment.user_id === userData.id;
                                            if (!shouldDisplayComment) return null;

                                            return (
                                                <StyledPaper key={commentIndex} sx={{ my: 1, mx: 'auto', p: 2 }}>
                                                    <CommentList
                                                        handleEditing={handleEditingComments}
                                                        comment={filteredComment}
                                                        userData={userData}
                                                        setLastRepliedCommentId={setLastRepliedCommentId}
                                                        questionId={questionId}
                                                        fileName={filteredComment.file_name}
                                                        commentUserId={filteredComment.user_id}
                                                        is_deleted={filteredComment.is_deleted}
                                                        resolved={resolved}
                                                        notificationCount={notificationCount}
                                                        notifications={notifications}
                                                        categories={category}
                                                        userId={userId}
                                                    />
                                                </StyledPaper>
                                            );
                                        })}
                                    </Collapse>
                                    {editingAnswer.id === answer.id && editingAnswer.isEditing && (
                                        <CommentEditor
                                            userId={userId}
                                            questionId={questionId}
                                            answer_id={answer.id}
                                            repliedCommentId={lastRepliedCommentId}
                                        />
                                    )}
                                </StyledPaper>
                            );
                        })}
                        {/* 回答の入力フォーム */}
                        <Box mt={2}>
                            <AnswerForm handleEditing={handleEditing} />
                            {!editing && (
                                <AnswerEditor
                                    userId={userId}
                                    questionId={questionId}
                                />
                            )}
                        </Box>
                    </Box>

                    {/* 解決ボタン */}
                    {parseInt(location.state.userId) === parseInt(location.state.questionUserId) && (
                        <Box mt={4} width="100%">
                            <Button
                                variant="contained"
                                color="success"
                                fullWidth
                                onClick={() => {
                                    navigateToSolution(
                                        questionId,
                                        questionUserId,
                                        notificationCount,
                                        notifications,
                                        userData,
                                        userId,
                                        category
                                    );
                                }}
                                sx={{
                                    height: '60px',
                                    fontSize: '20px',
                                    fontWeight: 'bold',
                                    background: 'linear-gradient(90deg, #43a047, #66bb6a)',
                                    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)',
                                    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s',
                                    '&:hover': {
                                        background: 'linear-gradient(90deg, #388e3c, #43a047)',
                                        transform: 'scale(1.05)',
                                        boxShadow: '0px 6px 12px rgba(0, 0, 0, 0.3)',
                                    },
                                }}
                            >
                                解決
                            </Button>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </div >
    );
};
