import PersonIcon from '@mui/icons-material/Person';
import { Button, Checkbox } from "@mui/material";
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import 'draft-js/dist/Draft.css';
import { React, useEffect, useRef, useState } from 'react';
import { useCookies } from "react-cookie";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation, useNavigate } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import Header from '../header/Header';
import CustomLogger from '../logger/Logger';
import MenuButtons from '../menubuttons/MenuButtons';


// 遷移してきたとき以外の画面更新でuserId, questionIdがnullにならないようにする
function ChildComponent({
    userId,
    questionId
}) {
    // 画面が再描画される度にユーザーIDと質問IDをセットする
    useEffect(() => {
        if (userId && questionId) {
            console.log(userId)
            console.log(questionId)
        }
    }, [userId, questionId]);
}

// 画面が再描画される度に回答を取得するようにする
function AnswerList({
    questionId,
    userId,
    handleEditing,
    answer,
    checkedAnswerId,
    setCheckedAnswerId,
    userData,
    notificationCount,
    notifications,
    categories,
    userName,
}) {
    const navigate = useNavigate();
    const [answerName, setAnswerNname] = useState([]);
    const [answerUserIcon, setAnswerUserIcon] = useState([]);
    const isUserAnonymous = answer.is_anonymous && userData.role !== 'admin';
    const isAdmin = userData.role === 'admin';
    const displayUserName = answer.is_anonymous
        ? isAdmin
            ? `${answerName || answer.user_id} (匿名投稿)`
            : '匿名ユーザー'
        : answerName || answer.user_id;
    // 受け取ったマークアップをマークアップとして表示する関数
    const DisplayMarkup = ({ html }) => {
        const globalStyle = `
            table {
                border-collapse: collapse;
                width: 100%;
            }

            th, td {
                border: 1px solid black;
                padding: 8px;
                text-align: left;
            }

            pre {
                background-color: #f5f5f5;
                border: 1px solid #ddd;
                padding: 10px;
                white-space: pre-wrap;
            }

            code {
                font-family: 'Courier New', Courier, monospace;
            }

            blockquote {
                border-left: 4px solid #ccc;
                margin: 0 0 0 10px;
                padding: 0 15px;
                color: #666;
                font-style: italic;
            }

            img {
                max-width: 100%; /* 親要素の幅に収まる */
                height: auto;    /* アスペクト比を維持 */
                display: block;  /* ブロック要素にすることで、余分な余白を排除 */
                margin: 0 auto;  /* 中央寄せにしたい場合は有効 */
            }
        `;

        return (
            <>
                <style dangerouslySetInnerHTML={{ __html: globalStyle }} />
                <div dangerouslySetInnerHTML={{ __html: html }} />
            </>
        );
    };


    // 　ユーザー情報を取得する
    useEffect(() => {
        if (isUserAnonymous) {
            // 匿名ユーザーの場合は何もしない
            return;
        }
        async function getusers() {
            console.log(answer.user_id)
            try {
                const res = await axios.post(
                    `/backend/getusers`,
                    {
                        user_id: answer.user_id,
                        withCredentials: true
                    }
                );
                setAnswerNname(res.data.name);
                setAnswerUserIcon(res.data.icon);
                console.log(res.data)
            } catch (error) {
                console.error('ユーザー情報の取得に失敗しました。', error);
            }
        }
        getusers();
    }, []);

    // ユーザー画面に移動する
    const moveUsers = () => {
        if (isUserAnonymous) {
            // 匿名ユーザーの場合は何もしない
            return;
        }
        console.log("ユーザー画面に移動する");
        navigate('/user', {
            state: {
                userId: userId,
                notificationCount: notificationCount,
                notifications: notifications,
                category: categories,
                pageId: answer.user_id
            }
        })
    };

    return (
        <div>
            <Grid key={answer.id} container direction="column" wrap="nowrap" spacing={2}>
                <Grid item>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <Avatar onClick={moveUsers} style={{ cursor: isUserAnonymous ? 'default' : 'pointer' }}>
                                {isUserAnonymous ? (
                                    <PersonIcon />
                                ) : answerUserIcon !== "null" ? (
                                    <img src={answerUserIcon} style={{ maxWidth: '100%', height: 'auto' }} alt="user icon" />
                                ) : (
                                    <PersonIcon />
                                )}
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" marginRight="10px">
                                {displayUserName}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs zeroMinWidth>
                    <div
                        key={answer.id}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            width: '100%',
                        }}
                    >
                        <DisplayMarkup html={answer.html_text} />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Checkbox
                                color="primary"
                                checked={checkedAnswerId.includes(answer.id)}
                                onChange={(event) => {
                                    if (event.target.checked) {
                                        setCheckedAnswerId((prevCheckedAnswerId) => [
                                            ...prevCheckedAnswerId,
                                            answer.id,
                                        ]);
                                    } else {
                                        setCheckedAnswerId((prevCheckedAnswerId) =>
                                            prevCheckedAnswerId.filter((id) => id !== answer.id)
                                        );
                                    }
                                }}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div >
    );
};

// 画面が再描画される度に回答を取得するようにする
function CommentList({
    questionId,
    answerId,
    userId,
    handleEditing,
    comment,
    checkedCommentId,
    setCheckedCommentId,
    userData,
    notificationCount,
    notifications,
    categories,
    userName,
}) {
    const navigate = useNavigate();
    const [commentName, setCommentName] = useState([]);
    const [commentUserIcon, setCommentUserIcon] = useState([]);
    const isUserAnonymous = comment.is_anonymous && userData.role !== 'admin';
    const isAdmin = userData.role === 'admin';

    const displayUserName = comment.is_anonymous
        ? isAdmin
            ? `${commentName || comment.user_id} (匿名投稿)`
            : '匿名ユーザー'
        : commentName || comment.user_id;
    console.log(comment)

    // 　ユーザー情報を取得する
    useEffect(() => {
        if (isUserAnonymous) {
            // 匿名ユーザーの場合はユーザー情報を取得しない
            return;
        }
        async function getusers() {
            console.log(comment.user_id)
            try {
                const res = await axios.post(
                    `/backend/getusers`,
                    {
                        user_id: comment.user_id,
                        withCredentials: true
                    }
                );
                setCommentName(res.data.name);
                setCommentUserIcon(res.data.icon);
                console.log(res.data)
            } catch (error) {
                console.error('ユーザー情報の取得に失敗しました。', error);
            }
        }
        getusers();
    }, []);

    // 受け取ったマークアップをマークアップとして表示する関数
    const DisplayMarkup = ({ html }) => {
        const globalStyle = `
            table {
                border-collapse: collapse;
                width: 100%;
            }

            th, td {
                border: 1px solid black;
                padding: 8px;
                text-align: left;
            }

            pre {
                background-color: #f5f5f5;
                border: 1px solid #ddd;
                padding: 10px;
                white-space: pre-wrap;
            }

            code {
                font-family: 'Courier New', Courier, monospace;
            }

            blockquote {
                border-left: 4px solid #ccc;
                margin: 0 0 0 10px;
                padding: 0 15px;
                color: #666;
                font-style: italic;
            }

            img {
                max-width: 100%; /* 親要素の幅に収まる */
                height: auto;    /* アスペクト比を維持 */
                display: block;  /* ブロック要素にすることで、余分な余白を排除 */
                margin: 0 auto;  /* 中央寄せにしたい場合は有効 */
            }
        `;

        return (
            <>
                <style dangerouslySetInnerHTML={{ __html: globalStyle }} />
                <div dangerouslySetInnerHTML={{ __html: html }} />
            </>
        );
    };

    // ユーザー画面に移動する
    const moveUsers = () => {
        if (isUserAnonymous) {
            // 匿名ユーザーの場合は何もしない
            return;
        }
        console.log("ユーザー画面に移動する");
        navigate('/user', {
            state: {
                userId: userId,
                notificationCount: notificationCount,
                notifications: notifications,
                category: categories,
                pageId: comment.user_id
            }
        })
    };

    return (
        <div>
            <Grid key={comment.answer_id} container direction="column" wrap="nowrap" spacing={2}>
                <Grid item>
                    <Grid container alignItems="center" spacing={2}>
                        <Grid item>
                            <Avatar onClick={moveUsers} style={{ cursor: isUserAnonymous ? 'default' : 'pointer' }}>
                                {isUserAnonymous ? (
                                    <PersonIcon />
                                ) : commentUserIcon !== "null" ? (
                                    <img src={commentUserIcon} style={{ maxWidth: '100%', height: 'auto' }} alt="user icon" />
                                ) : (
                                    <PersonIcon />
                                )}
                            </Avatar>
                        </Grid>
                        <Grid item>
                            <Typography variant="h6" marginRight="10px">
                                {displayUserName}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs zeroMinWidth>
                    <div key={comment.id}>
                        <DisplayMarkup html={comment.html_text} />
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'flex-end',
                            }}
                        >
                            <Checkbox
                                color="primary"
                                checked={checkedCommentId.includes(comment.id)}
                                onChange={(event) => {
                                    if (event.target.checked) {
                                        setCheckedCommentId((prevCheckedCommentId) => [
                                            ...prevCheckedCommentId,
                                            comment.id,
                                        ]);
                                    } else {
                                        setCheckedCommentId((prevCheckedCommentId) =>
                                            prevCheckedCommentId.filter((id) => id !== comment.id)
                                        );
                                    }
                                }}
                            />
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};


// 入力された質問をサーバーを介してDBに保存する
function AnswerForm({ questionId, userId, handleEditing }) {
    return (
        <div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    p: 1,
                    m: 1,
                    bgcolor: 'background.paper',
                    borderRadius: 1,
                }}
            >
            </Box>
        </div>
    );
};

// 入力された質問をサーバーを介してDBに保存する
function AnswerEditor({
    checkedAnswerId,
    checkedCommentId,
    question_id,
    user_id,
    moveTop,
    setLoading
}
) {
    const [errorMessage, setErrorMessage] = useState("");
    const [editorContent, setEditorContent] = useState('');
    const [plainTextContent, setPlainTextContent] = useState('');
    const [selectedImages, setSelectedImages] = useState([]);
    const customButtonList = [
        ['undo', 'redo']
    ];

    const handleImageUpload = async (files, info, uploadHandler) => {
        const file = files[0];
        console.log('画像をアップロード:', file);

        // FileReaderを使用して一時的なURLを生成
        const reader = new FileReader();
        reader.onloadend = () => {
            const tempUrl = reader.result;

            // 画像を選択した際に、その画像と一時的なURLをステートに保存
            setSelectedImages(prevImages => [...prevImages, { file, url: tempUrl }]);

            uploadHandler({ result: [tempUrl] });
        };
        reader.readAsDataURL(file);
    };

    // HTMLからプレーンテキストを抽出する関数
    function extractTextFromHtml(html) {
        const tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = html;
        return tempDivElement.textContent || tempDivElement.innerText || "";
    }

    // エディタの内容を処理する関数
    const handleEditorChange = (content) => {
        // HTMLコンテンツをステートに保存
        setEditorContent(content);

        // エディタ内の画像URLを抽出
        const editorImageUrls = content.match(/<img [^>]*src="([^"]+)"[^>]*>/g)?.map(imgTag => {
            const match = imgTag.match(/src="([^"]+)"/);
            return match ? match[1] : '';
        }) || [];

        console.log('エディタ内の画像URL:', editorImageUrls);

        // selectedImagesからエディタ内にない画像を削除
        setSelectedImages(prevImages => prevImages.filter(img => editorImageUrls.includes(img.url)));

        // HTMLからプレーンテキストを抽出
        const plainText = extractTextFromHtml(content);
        setPlainTextContent(plainText);
    };


    // 回答を投稿する関数
    const postSolution = async (
        checkedAnswerId,
        checkedCommentId,
        text,
        question_id,
        user_id,
        moveTop
    ) => {
        setLoading(true); // ローディング開始
        const logger = new CustomLogger();
        console.log(
            checkedAnswerId,
            checkedCommentId,
            text,
            question_id,
            user_id
        )

        console.log('解決策を投稿します');
        console.log('回答ID:', checkedAnswerId);
        console.log('コメントID:', checkedCommentId);
        try {
            //ここで実際にDBから取得する
            const res = await axios.post(`/backend/solution`,
                {
                    answer_ids: checkedAnswerId,
                    comment_ids: checkedCommentId,
                    text: plainTextContent,
                    question_id: question_id,
                    user_id: user_id,
                    withCredentials: true
                })
            console.log(res)
            moveTop();
        } catch (error) {
            logger.error(error)
            setErrorMessage("データ送信中にエラーが発生しました。再度試してください。");
        } finally {
            setLoading(false); // ローディング終了
        }
    };

    return (
        <div>
            <SunEditor
                onImageUploadBefore={handleImageUpload}
                setOptions={{
                    height: 200,
                    buttonList: customButtonList,
                }}
                onChange={handleEditorChange}
            />
            <Button
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                size="large"
                sx={{ my: 2 }}
                onClick={() => {
                    // HTMLコンテンツからテキストを抽出
                    const text = extractTextFromHtml(editorContent);

                    console.log(checkedAnswerId);

                    if (checkedAnswerId.length === 0 && checkedCommentId.length === 0 && !text) {
                        console.log(checkedAnswerId.length);
                        alert("回答またはコメントが選択されていません。");
                    } else {
                        console.log(checkedAnswerId.length);
                        postSolution(
                            checkedAnswerId,
                            checkedCommentId,
                            text,
                            question_id,
                            user_id,
                            moveTop
                        );
                    }
                }}
            >
                送信する
            </Button>
            {errorMessage && (
                <Typography variant="body2" color="error">
                    {errorMessage}
                </Typography>
            )}
        </div>
    );
}

export default function Solution() {
    const location = useLocation();
    const { notificationCount, notifications, userData, category } = location.state || {};
    const [question, setQuestion] = useState();
    const [title, setTitle] = useState('')
    const [userId, setUserId] = useState(null);
    const [questionId, setQuestionId] = useState(null);
    const [editing, setEditing] = useState(true)
    const [answers, setAnswers] = useState([]);
    const [answerId, setAnswerId] = useState([]);
    const [questionUserId, setQuestionUserId] = useState(null);
    const [comments, setComments] = useState([]);
    const [replycomments, setReplyComments] = useState([]);
    const [repliedCommentId, setRepliedCommentId] = useState([]);
    const [editingAnswer, setEditingAnswer] = useState({ id: null, isEditing: false });
    const [showCommentsForAnswer, setShowCommentsForAnswer] = useState({});
    const [checkedAnswerId, setCheckedAnswerId] = useState([]);
    const [checkedCommentId, setCheckedCommentId] = useState([]);
    const navigate = useNavigate();
    const [cookies, setCookie, removeCookie] = useCookies();
    const [newToken, setNewToken] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [arrowLeft, setArrowLeft] = useState(0);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const iconRef = useRef(null);
    const [tags, setTags] = useState([]);
    const logger = new CustomLogger();
    const [loading, setLoading] = useState(false);

    const handleEditing = (e) => {
        setEditing(!editing);
    };
    const handleEditingComments = (answerId) => {
        if (editingAnswer.id === answerId) {
            setEditingAnswer({ id: answerId, isEditing: !editingAnswer.isEditing });
        } else {
            setEditingAnswer({ id: answerId, isEditing: true });
        }
    };
    // スタイルペーパの設定などの関数
    const StyledPaper = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        color: theme.palette.text.primary,
    }));
    // 受け取ったマークアップをマークアップとして表示する関数
    const DisplayMarkup = ({ html }) => {
        const globalStyle = `
            table {
                border-collapse: collapse;
                width: 100%;
            }

            th, td {
                border: 1px solid black;
                padding: 8px;
                text-align: left;
            }

            pre {
                background-color: #f5f5f5;
                border: 1px solid #ddd;
                padding: 10px;
                white-space: pre-wrap;
            }

            code {
                font-family: 'Courier New', Courier, monospace;
            }

            blockquote {
                border-left: 4px solid #ccc;
                margin: 0 0 0 10px;
                padding: 0 15px;
                color: #666;
                font-style: italic;
            }

            img {
                max-width: 100%; /* 親要素の幅に収まる */
                height: auto;    /* アスペクト比を維持 */
                display: block;  /* ブロック要素にすることで、余分な余白を排除 */
                margin: 0 auto;  /* 中央寄せにしたい場合は有効 */
            }
        `;

        return (
            <>
                <style dangerouslySetInnerHTML={{ __html: globalStyle }} />
                <div dangerouslySetInnerHTML={{ __html: html }} />
            </>
        );
    };

    // トップ画面に戻る
    const moveTop = async () => {
        if (cookies.token !== "") {
            console.log('トップ画面に戻ります')
            try {
                const res = await axios.get(`/backend/me`, {
                    data: {},
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + cookies.token,
                    },
                });

                console.log(res);
                if (res.data.login_ok === "login_ok") {
                    console.log(res.data);
                    console.log('トップ画面に戻ります')
                    navigate("/top", {
                        state: {
                            userId: res.data.id,
                            userPassword: res.data.password,
                        },
                    });
                }
            } catch (error) {
                // トークンの有効期限が切れている場合、リフレッシュトークンを再取得する
                if (error.response.data.msg === "Token has expired") {
                    console.log('トークンの有効期限が切れています');
                    console.log('リフレッシュトークンを再取得します');
                    try {
                        const res = await axios.get(`/backend/refresh`, {
                            data: {},
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + cookies.refresh_token,
                            },
                        });

                        console.log(res);
                        removeCookie("token");
                        setCookie("token", res.data.access_token);
                        setNewToken(res.data.access_token);

                        // 再取得したトークンでtop画面に戻る
                        const updatedToken = res.data.access_token;
                        await new Promise((resolve) => setTimeout(resolve, 0));
                        console.log('トップ画面に戻ります')
                        const res2 = await axios.get(`/backend/me`, {
                            data: {},
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: "Bearer " + updatedToken,
                            },
                        });

                        console.log(res2);
                        if (res2.data.login_ok === "login_ok") {
                            console.log('トップ画面に戻ります')
                            navigate("/top", {
                                state: {
                                    userId: res2.data.id,
                                    userPassword: res2.data.password,
                                },
                            });
                        }
                    } catch (error) {
                        logger.error(error);
                    }
                }
            }
        }
    };

    // 画面が再描画される度にチェックされた回答IDを表示する
    useEffect(() => {
        console.log("Current checkedAnswerId:", checkedAnswerId);
    }, [checkedAnswerId]);

    //　コメントのツリーをデフォルトで開いた状態にする
    useEffect(() => {
        const newShowCommentsForAnswer = {};
        answers.forEach((answer) => {
            newShowCommentsForAnswer[answer.id] = true;
        });
        setShowCommentsForAnswer(newShowCommentsForAnswer);
    }, [answers]);

    // 画面が再描画される度にユーザーIDと質問IDをセットする
    useEffect(() => {
        if (location.state) {
            setUserId(location.state.userId);
            setQuestionUserId(location.state.questionUserId)
            setQuestionId(location.state.questionId);
        }
    }, [location.state]);

    // 画面が再描画される度に質問を取得するようにする
    useEffect(() => {
        console.log('質問を取得します');
        async function fetchQuestion() {
            const res = await axios.post(
                `/backend/getquestion`,
                {
                    question_id: questionId,
                    withCredentials: true
                }
            );
            console.log(res)
            setQuestion(res.data.html_question);
            setTitle(res.data.title);
        }
        if (questionId) {
            fetchQuestion();
        }
    }, [userId, questionId]);

    // 画面が再描画される度に回答を取得するようにする
    useEffect(() => {
        console.log('回答を取得します');
        async function fetchAnswers() {
            const res = await axios.post(
                `/backend/getanswer`,
                {
                    question_id: questionId,
                    withCredentials: true
                }
            );
            console.log(res)
            setAnswers(res.data);
            setAnswerId(res.data.map(answer => answer.id));
        }
        if (questionId) {
            fetchAnswers();
        }
    }, [questionId]);

    // 画面が再描画される度にコメントを取得するようにする
    useEffect(() => {
        async function fetchComments() {
            console.log('コメントを取得します');
            try {
                const promises = answerId.map(async (id) => {
                    const res = await axios.post(
                        `/backend/getcomment`,
                        {
                            answer_id: id,
                            withCredentials: true
                        }
                    );
                    console.log(res);

                    // 全てのreplied_comment_idをsetRepliedCommentIdに追加
                    res.data.forEach((comment) => {
                        setRepliedCommentId((prevRepliedCommentIds) => [
                            ...prevRepliedCommentIds,
                            comment.replied_comment_id,
                        ]);
                    });

                    return res.data;
                });

                // すべてのPromiseが完了するまで待機し、結果をまとめる
                const results = await Promise.all(promises);
                const flattenedResults = results.flat();  // 結果をフラットにする
                console.log(flattenedResults);
                setComments(prevComments => [...prevComments, ...flattenedResults]);
            } catch (error) {
                console.error("コメントの取得中にエラーが発生しました:", error);
            }
        }
        if (answerId.length) {
            fetchComments();
        }
    }, [answerId]);

    // 画面が再描画される度に返信コメントを取得するようにする
    useEffect(() => {
        console.log('返信コメントを取得します');
        async function fetchReplyComments() {
            const promises = repliedCommentId.map(async (id) => {
                const res = await axios.post(`/backend/getreplycomment`, {
                    replied_comment_id: id,
                    withCredentials: true
                });
                console.log(res)
                console.log(res.data)
                return res.data
            });
            // すべてのPromiseが完了するまで待機し、結果をまとめる
            const results = await Promise.all(promises);
            setReplyComments([...replycomments, ...results]);
            console.log(replycomments)
            if (replycomments == []) {
                setReplyComments([...replycomments, ...[0, 0, 0]]);
            }
        };
        if (repliedCommentId) {
            fetchReplyComments();
        }
    }, [repliedCommentId]);

    // 画面が再描画される度にタグを取得するようにする
    useEffect(() => {
        console.log('タグを取得します');
        async function getTags() {
            const res = await axios.post(
                `/backend/gettags`,
                {
                    questionId: questionId,
                    withCredentials: true
                }
            );
            console.log(res)
            setTags(res.data);
        }
        getTags();
    }, [answerId]);

    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, paddingTop: '10px' }}>
                <Header
                    searchText={searchText}
                    notificationCount={notificationCount}
                    id={id}
                    iconRef={iconRef}
                    arrowLeft={arrowLeft}
                    notifications={notifications}
                    userId={userId}
                    userData={userData}
                    categories={category}
                />
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    my={0}
                    mx="auto"
                    width="100%"
                    padding="35px"
                ></Box>
            </Box>

            <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: "100vh", backgroundColor: '#f2f2f2', padding: '16px' }}>
                {loading && (
                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2 }}>
                        <CircularProgress />
                    </Box>
                )}
                {loading && (
                    <Box sx={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 1 }} />
                )}
                <Grid item xs={12} lg={8}>
                    {/* 質問ボックス */}
                    <Box bgcolor="white" p={3} borderRadius={3} boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)" mb={4}>
                        <Typography variant="h4" fontWeight="bold">質問</Typography>
                        <StyledPaper sx={{ mt: 2, p: 2 }}>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item>
                                    <Avatar sx={{ width: 24, height: 24 }}>
                                        {userData.icon !== "null"
                                            ? <img src={userData.icon} alt="User icon" style={{ height: 24, width: 24 }} />
                                            : <PersonIcon fontSize="small" />
                                        }
                                    </Avatar>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6">{userData.name ? userData.name : questionId}</Typography>
                                </Grid>
                            </Grid>
                            <Typography variant="h5" fontWeight="bold" sx={{ mt: 2 }}>{title}</Typography>
                            {tags && tags.map((tag, index) => (
                                <Chip key={index} label={tag} sx={{ ml: 1, mt: 1 }} />
                            ))}
                            <DisplayMarkup html={question} />
                        </StyledPaper>
                    </Box>

                    {/* 回答セクション */}
                    <Box bgcolor="white" p={3} borderRadius={3} boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)" mb={4}>
                        <Typography variant="h4" fontWeight="bold">回答 ({answers ? answers.length : 0}件)</Typography>
                        <div>
                            {answers.map((answer, index) => (
                                <StyledPaper key={index} sx={{ mt: 2, p: 2 }}>
                                    <AnswerList
                                        questionId={questionId}
                                        userId={userId}
                                        handleEditing={handleEditingComments}
                                        answer={answer}
                                        checkedAnswerId={checkedAnswerId}
                                        setCheckedAnswerId={setCheckedAnswerId}
                                        userData={userData}
                                        notificationCount={notificationCount}
                                        notifications={notifications}
                                        categories={category}
                                    />
                                    <Button
                                        onClick={() => {
                                            setShowCommentsForAnswer((prevState) => ({
                                                ...prevState,
                                                [answer.id]: !prevState[answer.id],
                                            }));
                                        }}
                                        sx={{
                                            mt: 2,
                                            textTransform: 'none',
                                            backgroundColor: '#f5f5f5',
                                            color: '#333',
                                            borderColor: '#ddd',
                                            '&:hover': {
                                                backgroundColor: '#e0e0e0',
                                            },
                                            borderRadius: '12px',
                                            fontWeight: '500',
                                            padding: '8px 16px',
                                            fontSize: '14px',
                                        }}
                                    >
                                        {`コメント ${comments.flat().filter(comment => comment.answer_id === answer.id).length} 件`}
                                    </Button>
                                    <Collapse in={showCommentsForAnswer[answer.id] || false}>
                                        {comments.filter((comment) => comment.answer_id === answer.id)
                                            .map((filteredComment, index) => (
                                                <StyledPaper
                                                    key={index}
                                                    sx={{ my: 1, mx: 'auto', p: 2 }}
                                                >
                                                    <CommentList
                                                        questionId={questionId}
                                                        userId={userId}
                                                        handleEditing={handleEditingComments}
                                                        comment={filteredComment}
                                                        checkedCommentId={checkedCommentId}
                                                        setCheckedCommentId={setCheckedCommentId}
                                                        userData={userData}
                                                        notificationCount={notificationCount}
                                                        notifications={notifications}
                                                        categories={category}
                                                    />
                                                </StyledPaper>
                                            ))
                                        }
                                    </Collapse>
                                </StyledPaper>
                            ))}
                        </div>
                    </Box>

                    {/* 解決セクション */}
                    <Box bgcolor="white" p={3} borderRadius={3} boxShadow="0px 4px 15px rgba(0, 0, 0, 0.1)" mb={4}>
                        <Typography variant="h4" fontWeight="bold">解決</Typography>
                        <Typography variant="subtitle1" sx={{ mt: 2, mb: 3 }}>
                            有効な回答にチェックを入れたあとに、実際の解決方法や自己解決した際の方法を記載してください。
                        </Typography>
                        <AnswerForm
                            questionId={questionId}
                            userId={userId}
                            handleEditing={handleEditing}
                        />
                        <AnswerEditor
                            checkedAnswerId={checkedAnswerId}
                            checkedCommentId={checkedCommentId}
                            question_id={questionId}
                            user_id={userId}
                            moveTop={moveTop}
                            setLoading={setLoading}
                        />
                    </Box>

                    {/* ローディングインジケーター */}
                    {loading && <CircularProgress />}
                </Grid>
            </Grid>
        </div >
    );
};
