import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Checkbox, FormControlLabel } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import axios from 'axios';
import { EditorState } from 'draft-js';
import { React, useEffect, useRef, useState } from 'react';
import { useCookies } from "react-cookie";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation, useNavigate } from 'react-router-dom';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';
import Header from '../header/Header';
import CustomLogger from '../logger/Logger';

export default function Question() {
    //　Log　in画面から必要情報を受け取る
    const navigate = useNavigate();
    const location = useLocation();
    const { userId, notificationCount, notifications, userData, questionContent } = location.state || {};
    const [cookies, setCookie, removeCookie] = useCookies();
    const [title, setTitle] = useState('')
    const [newToken, setNewToken] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [arrowLeft, setArrowLeft] = useState(0);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const iconRef = useRef(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [newFileName, setNewFileName] = useState(null);
    const logger = new CustomLogger();
    const [isTextLongEnough, setIsTextLongEnough] = useState(false);
    const [loading, setLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [editorContent, setEditorContent] = useState(questionContent || '');
    const [plainTextContent, setPlainTextContent] = useState('');
    const [loadingMessage, setLoadingMessage] = useState("");
    const [isAnonymous, setIsAnonymous] = useState(false);
    const editorRef = useRef(null);
    const customButtonList = [
        ['undo', 'redo'],
        ['fontSize', 'formatBlock'],
        ['paragraphStyle', 'blockquote'],
        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
        ['fontColor', 'hiliteColor', 'textStyle'],
        ['removeFormat'],
        ['outdent', 'indent'],
        ['align', 'horizontalRule', 'list', 'lineHeight'],
        ['table', 'link', 'image'],
    ];
    const fileInputRef = useRef(null);
    const handleFileInputClick = () => {
        fileInputRef.current.click();
    };

    // `questionContent`が変わったときに`editorContent`と関連状態を更新
    useEffect(() => {
        if (questionContent) {
            setEditorContent(questionContent);

            // HTMLからプレーンテキストを抽出し、文字数をチェック
            const plainText = extractTextFromHtml(questionContent);
            setPlainTextContent(plainText);
            setIsTextLongEnough(plainText.length >= 10);  // 初期状態で文字数を確認
        }
    }, [questionContent]);

    // `editorContent`が変わったときにエディタに反映
    useEffect(() => {
        if (editorRef.current && editorContent) {
            editorRef.current.setContents(editorContent);
        }
    }, [editorContent]);

    // 画面が再描画される度にカテゴリを取得するようにする
    useEffect(() => {
        console.log('カテゴリを取得します')
        async function fetchCategory() {
            const res = await axios.post(
                `/backend/getcategories`,
                {
                    withCredentials: true
                }
            );
            console.log(res)
            setCategories(res.data);
        }
        fetchCategory();
    }, []);

    // エディタのインスタンスを取得したときに呼ばれる関数
    const getEditorInstance = (editor) => {
        editorRef.current = editor;
        if (editorContent) {
            editor.setContents(editorContent);
        }
    };

    // チェックボックスの変更を処理します
    const handleTagChange = (event) => {
        const tag = event.target.value;
        if (selectedTags.includes(tag)) {
            setSelectedTags(selectedTags.filter((s) => s !== tag));
        } else {
            setSelectedTags([...selectedTags, tag]);
        }
    };
    // チェックボックスの削除を処理します
    const handleDelete = (tagToDelete) => {
        setSelectedTags(selectedTags.filter((tag) => tag !== tagToDelete));
    };

    // HTMLからプレーンテキストを抽出する関数
    function extractTextFromHtml(html) {
        const tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = html;
        return tempDivElement.textContent || tempDivElement.innerText || "";
    }

    // エディタの内容を処理する関数
    const handleEditorChange = (content) => {

        // エディタ内の画像URLを抽出
        const editorImageUrls = content.match(/<img [^>]*src="([^"]+)"[^>]*>/g)?.map(imgTag => {
            const match = imgTag.match(/src="([^"]+)"/);
            return match ? match[1] : '';
        }) || [];

        console.log('エディタ内の画像URL:', editorImageUrls);

        // selectedImagesからエディタ内にない画像を削除
        setSelectedImages(prevImages => prevImages.filter(img => editorImageUrls.includes(img.url)));

        // HTMLからプレーンテキストを抽出
        const plainText = extractTextFromHtml(content);
        setPlainTextContent(plainText);

        // プレーンテキストの長さを確認し、ステートを更新
        setIsTextLongEnough(plainText.length >= 10);
    };

    // ファイル選択時のハンドラ
    const handleFileSelect = (event) => {
        // 選択された新しいファイルのリストを取得
        const newFilesArray = Array.from(event.target.files);
        console.log(newFilesArray);
        // 既存のファイルリストに新しいファイルを追加
        setSelectedFiles(prevFiles => [...prevFiles, ...newFilesArray]);
    };
    // ファイル削除時のハンドラ
    const handleRemoveFile = (fileName) => {
        setSelectedFiles(selectedFiles.filter(file => file.name !== fileName));
    };
    // ファイルリストの表示
    const renderFileList = () => {
        if (!selectedFiles || selectedFiles.length === 0) {
            return <div style={{ marginTop: '10px' }}>No files selected.</div>;
        }
        return (
            <ul style={{ listStyleType: 'none', padding: 0 }}>
                {selectedFiles.map((file, index) => (
                    <li key={index} style={{ margin: '10px 0', display: 'flex', alignItems: 'center' }}>
                        <span style={{ flexGrow: 1, marginRight: '10px' }}>{file.name}</span>
                        <button
                            onClick={() => handleRemoveFile(file.name)}
                            style={{
                                border: 'none',
                                background: '#990000',
                                color: 'white',
                                padding: '5px 10px',
                                cursor: 'pointer',
                                transition: 'background-color 0.3s ease'
                            }}
                            onMouseOver={(e) => e.target.style.backgroundColor = '#bb3333'}
                            onMouseOut={(e) => e.target.style.backgroundColor = '#990000'}
                        >削除
                        </button>
                    </li>
                ))}
            </ul>
        );
    };

    // 入力された質問をサーバーを介してDBに保存する
    async function PostQuestion() {
        if (!title.trim()) {
            setErrorMessage("タイトルを入力してください。");
            return;
        }
        setLoading(true); // ローディング開始
        const content = editorRef.current.getContents(); // エディタ内容を取得

        let questionId = 0
        let imageFiles = [];
        let otherFiles = [];

        // selectedFilesが存在する場合のみファイルアップロード処理を実行
        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}${currentDate.getMonth() + 1}${currentDate.getDate()}${currentDate.getHours()}${currentDate.getMinutes()}${currentDate.getSeconds()}`;

        // 画像ファイルのアップロード
        setLoadingMessage("画像ファイルをアップロード中...");
        if (selectedImages && selectedImages.length > 0) {
            for (let imageObj of selectedImages) {
                const file = imageObj.file;
                const fileName = `${userId}_${formattedDate}_${file.name}`;
                const formData = new FormData();
                formData.append('file', file, fileName);

                try {
                    await axios.post(`/backend/uploadfile`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    });
                    imageFiles.push(fileName);
                } catch (error) {
                    console.error('Image upload failed:', error);
                }
            }
        }

        // その他のファイルのアップロード
        setLoadingMessage("その他のファイルをアップロード中...");
        if (selectedFiles && selectedFiles.length > 0) {
            for (let file of selectedFiles) {
                const fileName = `${userId}_${formattedDate}_${file.name}`;
                const formData = new FormData();
                formData.append('file', file, fileName);

                try {
                    await axios.post(`/backend/uploadfile`, formData, {
                        headers: { 'Content-Type': 'multipart/form-data' }
                    });
                    otherFiles.push(fileName);
                } catch (error) {
                    console.error('File upload failed:', error);
                }
            }
        }
        //DBに質問を登録してページ遷移
        setLoadingMessage("質問をデータベースに登録中...");
        console.log('質問を投稿します...');
        console.log('ファイル:', imageFiles);
        console.log('その他のファイル:', otherFiles);
        try {
            const res = await axios.post(`/backend/question`,
                {
                    question: plainTextContent,
                    title: title,
                    markup: content,
                    userId: userId,
                    selectedTags: selectedTags,
                    imageFiles: imageFiles,
                    otherFiles: otherFiles,
                    isAnonymous: isAnonymous,
                    withCredentials: true
                })
            console.log(res)

            if (res.data && res.data.id) {
                questionId = res.data.id

                // 通知テーブルにデータを登録
                console.log('通知テーブルにデータを登録します...');
                const notificationRes = await axios.post(`/backend/notifications`,
                    {
                        userId: userId,
                        title: title,
                        questionId: questionId,
                        withCredentials: true
                    })
                if (!notificationRes.data.success) {
                    console.error('Error during the notification registration:', notificationRes.data);
                }
                // ここで画面遷移
                console.log('回答画面に移動します');
                navigate(
                    '/answer', {
                    state: {
                        userId: userId,
                        questionId: questionId,
                        notificationCount: notificationCount,
                        notifications: notifications,
                        userData: userData,
                    },
                    replace: true,
                }
                )
            } else {
                console.log(res.data)
            }
        } catch (error) {
            logger.error('データ送信中にエラーが発生しました:', error);
            setErrorMessage("データ送信中にエラーが発生しました。再度試してください。");
        } finally {
            setLoading(false); // ローディング終了（成功または失敗）
            setLoadingMessage("");
        }
    };

    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, paddingTop: '4px' }}>
                <Header
                    searchText={searchText}
                    notificationCount={notificationCount}
                    id={id}
                    iconRef={iconRef}
                    arrowLeft={arrowLeft}
                    notifications={notifications}
                    userId={userId}
                    userData={userData}
                    categories={categories}
                />
                <Box
                    display="flex"
                    justifyContent="flex-start"
                    my={0}
                    mx="auto"
                    width="100%"
                    py="35px"
                ></Box>
            </Box>

            <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{
                    minHeight: '100vh',
                    backgroundColor: '#f2f2f2',
                    padding: '16px',
                    overflow: 'auto',
                }}
            >
                {loading && (
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 2,
                        padding: '30px',
                        backgroundColor: '#ffffff',
                        borderRadius: '12px',
                        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.1)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <CircularProgress style={{ animationDuration: '550ms' }} />
                        <Typography variant="subtitle1" sx={{ marginTop: '20px', fontWeight: 'bold', color: '#333' }}>
                            {loadingMessage}
                        </Typography>
                    </Box>
                )}

                <Grid
                    container
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        p: 2,
                        borderRadius: 3,
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#ffffff',
                        padding: '16px',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                        }}
                    >
                        {/* タイトル */}
                        <Box mt={3}>
                            <InputLabel htmlFor="editor-content" sx={{ mb: 1, color: '#333', fontWeight: 'bold' }}>
                                タイトル
                            </InputLabel>
                            <Typography variant="subtitle2" sx={{ mb: 1, color: '#555' }}>
                                質問のタイトルを入力してください。できるだけ具体的なタイトルをつけると、回答が得やすくなります。
                            </Typography>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                                sx={{
                                    my: 1,
                                    '& .MuiOutlinedInput-root': {
                                        borderRadius: 2,
                                        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.1)',
                                    },
                                }}
                            />
                        </Box>

                        {/* 本文 */}
                        <Box mt={5}>
                            <InputLabel htmlFor="editor-content" sx={{ mb: 1, color: '#333', fontWeight: 'bold' }}>
                                本文
                            </InputLabel>
                            <Typography variant="subtitle2" sx={{ mb: 1, color: '#555' }}>
                                質問の詳細をここに記述してください。できるだけ詳しく書くことで、他のユーザーがより正確な回答をしやすくなります。
                            </Typography>
                            <SunEditor
                                getSunEditorInstance={getEditorInstance}
                                setOptions={{
                                    height: 200,
                                    buttonList: customButtonList,
                                }}
                                // 初期コンテンツを設定
                                defaultValue={editorContent}
                                // 変更時に状態を更新
                                onChange={handleEditorChange}
                            />
                        </Box>

                        {/* ファイル選択 */}
                        <Box mt={5}>
                            <InputLabel htmlFor="file-upload" sx={{ mb: 1, color: '#333', fontWeight: 'bold' }}>
                                ファイルを選択
                            </InputLabel>
                            <Typography variant="subtitle2" sx={{ mb: 1, color: '#555' }}>
                                質問に関連するファイルがある場合は、ここから選択してアップロードしてください。
                            </Typography>
                            <input
                                ref={fileInputRef}
                                type="file"
                                multiple
                                onChange={handleFileSelect}
                                accept=".pdf,.xls,.xlsx,.gif,.jpeg,.jpg,.png,.svg,.dwf,.dwg,.dxf,.doc,.docx,.ppt,.pptx,.txt"
                                style={{ display: 'none' }}
                            />
                            <Button
                                onClick={handleFileInputClick}
                                sx={{ mt: 2 }}
                            >
                                ファイルを選択
                            </Button>
                            <div>{renderFileList()}</div>
                        </Box>

                        {/* タグ選択 */}
                        <Box mt={5}>
                            <InputLabel htmlFor="tags" sx={{ mb: 1, color: '#333', fontWeight: 'bold' }}>
                                タグ
                            </InputLabel>
                            <Typography variant="subtitle2" sx={{ mb: 1, color: '#555' }}>
                                質問に関連するタグを選択してください。タグを追加することで、質問が特定のトピックに関連していることが明確になります。
                            </Typography>

                            {categories.map((mainCategory, mainIndex) => (
                                <Accordion key={mainIndex}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        sx={{
                                            backgroundColor: '#f7f7f7',
                                            '& .MuiTypography-root': {
                                                fontWeight: 'bold',
                                                color: '#333',
                                            },
                                        }}
                                    >
                                        <Typography variant="subtitle1">{mainCategory.main_category}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {mainCategory.sub_categories.map((subCategory, subIndex) => (
                                            <Accordion key={subIndex}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    sx={{ backgroundColor: '#f9f9f9' }}
                                                >
                                                    <Typography variant="subtitle1">{subCategory.sub_category}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <FormGroup>
                                                        {subCategory.tags.map((tag, tagIndex) => (
                                                            <FormControlLabel
                                                                key={`${tag}-${tagIndex}`}
                                                                control={
                                                                    <Checkbox
                                                                        checked={selectedTags.includes(tag)}
                                                                        onChange={handleTagChange}
                                                                        value={tag}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={tag}
                                                            />
                                                        ))}
                                                    </FormGroup>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </Box>

                        {/* 選択されたタグを表示 */}
                        <Box mt={3}>
                            <Typography variant="h8">選択されたタグ:</Typography>
                            <Box mt={1} sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                                {selectedTags.map((tag, index) => (
                                    <Chip
                                        key={index}
                                        label={tag}
                                        onDelete={() => handleDelete(tag)}
                                        color="primary"
                                        variant="outlined"
                                    />
                                ))}
                            </Box>
                        </Box>

                        {/* 匿名で投稿 */}
                        <Box mt={5}>
                            <InputLabel htmlFor="tags" sx={{ mb: 1, color: '#333', fontWeight: 'bold' }}>
                                匿名投稿
                            </InputLabel>
                            <Typography variant="subtitle2" sx={{ mb: 1, color: '#555' }}>
                                匿名で投稿する場合はこちらにチェックを入れてください。
                                匿名で投稿すると、あなたの名前は他のユーザーには表示されません。
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isAnonymous}
                                        onChange={(e) => setIsAnonymous(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="匿名で投稿する"
                            />
                        </Box>

                        {/* 質問投稿ボタン */}
                        <Button
                            fullWidth
                            type="submit"
                            color="primary"
                            variant="contained"
                            size="large"
                            sx={{
                                my: 4,
                                height: '50px',
                                borderRadius: '8px',
                                fontWeight: 'bold',
                                backgroundColor: '#1976d2',
                                boxShadow: '0px 4px 15px rgba(25, 118, 210, 0.4)',
                                '&:hover': {
                                    backgroundColor: '#1565c0',
                                },
                            }}
                            onClick={() => PostQuestion()}
                            disabled={loading || !isTextLongEnough || !title.trim()} // タイトルが空の場合もボタンを無効化
                        >
                            <Typography style={{ fontWeight: 'bold' }}>
                                質問を投稿する
                            </Typography>
                        </Button>

                        {errorMessage && (
                            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                                {errorMessage}
                            </Typography>
                        )}
                        {errorMessage && (
                            <Typography variant="body2" color="error" sx={{ mt: 2 }}>
                                {errorMessage}
                            </Typography>
                        )}
                        {!title.trim() && (
                            <Typography color="error" variant="body2" sx={{ my: 2 }}>
                                タイトルを入力してください。
                            </Typography>
                        )}
                        {!isTextLongEnough && plainTextContent.length < 10 && (
                            <Typography color="error" variant="body2" sx={{ my: 2 }}>
                                本文を10文字以上入力してください。
                            </Typography>
                        )}
                        {loading && <CircularProgress />}
                    </Box>
                </Grid>
            </Grid>
        </div >
    );
};
