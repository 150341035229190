// DialogComponents.jsx
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

/** 削除前の確認 */
export function ConfirmDeleteDialog({ open, onClose, onConfirm }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>本当に削除しますか？</DialogTitle>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button onClick={onConfirm} color="primary">
          削除
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/** 削除後ダイアログ */
export function DeleteSuccessDialog({ open, onClose, fetchFileList }) {
  const handleClose = () => {
    fetchFileList?.();
    onClose();
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>削除されました</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/** 更新後ダイアログ */
export function UpdateSuccessDialog({ open, onClose, fetchFileList }) {
  const handleClose = () => {
    fetchFileList?.();
    onClose();
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>更新されました</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/** 移動完了ダイアログ */
export function MoveSuccessDialog({ open, onClose, fetchFileList }) {
  const handleClose = () => {
    fetchFileList?.();
    onClose();
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>移動完了</DialogTitle>
      <DialogContent>
        <Typography>ファイルが正常に移動されました。</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/** 編集完了ダイアログ */
export function EditSuccessDialog({ open, onClose, fetchFileList }) {
  const handleClose = () => {
    fetchFileList?.();
    onClose();
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>編集完了</DialogTitle>
      <DialogContent>
        <Typography>ファイルが正常に編集されました。</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/** ファイルを更新しますか？ダイアログ */
export function ConfirmUpdateDialog({ open, onClose, onConfirm }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>ファイルを更新しますか？</DialogTitle>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button onClick={onConfirm} color="primary">
          はい
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/** ファイル移動ダイアログ */
export function MoveFileDialog({
  open,
  onClose,
  hierarchyData,
  onMove,
  fileList,
  checkedFiles,
}) {
  const [selectedHierarchyId, setSelectedHierarchyId] = useState("");

  const handleMove = () => {
    if (!selectedHierarchyId) {
      alert("階層を選択してください。");
      return;
    }
    const filesToMove = Array.from(checkedFiles).map((fileId) =>
      fileList.find((f) => f.file_name === fileId)
    );
    onMove(filesToMove, selectedHierarchyId);
    onClose();
  };

  function renderHierarchyOptions(hierarchies, prefix = "") {
    let items = [];
    hierarchies.forEach((h) => {
      items.push(
        <MenuItem key={h.id} value={h.id}>
          {prefix + h.name}
        </MenuItem>
      );
      if (h.children) {
        items = items.concat(renderHierarchyOptions(h.children, prefix + "  "));
      }
    });
    return items;
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>ファイルを移動</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel>階層</InputLabel>
          <Select
            value={selectedHierarchyId}
            onChange={(e) => setSelectedHierarchyId(e.target.value)}
            displayEmpty
          >
            <MenuItem value="">
              <em>選択してください</em>
            </MenuItem>
            {renderHierarchyOptions(hierarchyData)}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button onClick={handleMove} color="primary">
          移動
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/** ファイル名変更ダイアログ */
export function RenameFileDialog({ open, onClose, file, onRename }) {
  const initialName = file ? file.title.replace(/\.[^/.]+$/, "") : "";
  const [newFileName, setNewFileName] = useState(initialName);
  const extension = file ? file.title.split(".").pop() : "";

  useEffect(() => {
    if (file) {
      const base = file.title.replace(/\.[^/.]+$/, "");
      setNewFileName(base);
    }
  }, [file]);

  const handleRename = () => {
    if (!newFileName.trim()) {
      alert("ファイル名を入力してください。");
      return;
    }
    const fullName = `${newFileName}.${extension}`;
    onRename({ fileId: file.file_name, newName: fullName });
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>ファイル名を変更</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="新しいファイル名"
          type="text"
          fullWidth
          value={newFileName}
          onChange={(e) => setNewFileName(e.target.value)}
        />
        <Typography variant="body2" color="textSecondary">
          .{extension}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button onClick={handleRename} color="primary">
          変更
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/** Chroma 再取得ダイアログ */
export function ConfirmRetrieveChromaDialog({ open, onClose, onConfirm }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Chroma再取得の確認</DialogTitle>
      <DialogContent>
        <Typography>
          現在のChromaデータを削除し、新しいデータを再取得します。元に戻せませんが、よろしいですか？
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button onClick={onConfirm} color="primary">
          はい
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/** Chroma再取得成功 */
export function RetrieveChromaSuccessDialog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>再取得成功</DialogTitle>
      <DialogContent>
        <Typography>Chromaデータが正常に再取得されました。</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
/** 新しい階層を追加するダイアログ */
export function AddHierarchyDialog({ open, onClose, onAdd, parentId }) {
  const [newHierarchyName, setNewHierarchyName] = useState("");

  const handleAddClick = () => {
    if (!newHierarchyName.trim()) {
      alert("階層名を入力してください。");
      return;
    }
    onAdd(parentId, newHierarchyName);
    setNewHierarchyName("");
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>新しい階層を追加</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          fullWidth
          label="階層名"
          value={newHierarchyName}
          onChange={(e) => setNewHierarchyName(e.target.value)}
          margin="dense"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button onClick={handleAddClick} color="primary">
          追加
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * 階層削除ダイアログ
 */
export function ConfirmDeleteHierarchyDialog({
  open,
  onClose,
  onConfirm,
  targetName,
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>階層の削除</DialogTitle>
      <DialogContent>
        <Typography>
          {targetName
            ? `「${targetName}」を削除してもよろしいですか？`
            : "この階層を削除してもよろしいですか？"}
        </Typography>
        <Typography sx={{ mt: 2 }}>
          削除されると、この階層に含まれるファイルもすべて削除されます。
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button onClick={onConfirm} color="error">
          削除
        </Button>
      </DialogActions>
    </Dialog>
  );
}

/**
 * 階層名変更ダイアログ
 */
export function RenameHierarchyDialog({
  open,
  onClose,
  renameValue,
  setRenameValue,
  onConfirm,
}) {
  const handleConfirm = () => {
    if (!renameValue.trim()) {
      alert("階層名を入力してください。");
      return;
    }
    onConfirm(renameValue);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>階層名の変更</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="新しい階層名"
          type="text"
          fullWidth
          value={renameValue}
          onChange={(e) => setRenameValue(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>キャンセル</Button>
        <Button onClick={handleConfirm} color="primary">
          変更
        </Button>
      </DialogActions>
    </Dialog>
  );
}
