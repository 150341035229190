import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Checkbox, FormControlLabel } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Chip from '@mui/material/Chip';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { React, useEffect, useState } from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const Categories = ({ onSelectedTagsChange }) => {
    const [selectedTags, setSelectedTags] = useState([]);
    const [category, setCategory] = useState(null);


    // 画面が再描画される度にカテゴリを取得するようにする
    useEffect(() => {
        console.log('カテゴリを取得します')
        async function fetchCategory() {
            const res = await axios.post(
                `/backend/getcategories`,
                {
                    withCredentials: true
                }
            );
            console.log(res)
            setCategory(res.data);
        }
        fetchCategory();
    }, []);

    // 選択されたタグの変更を処理します
    useEffect(() => {
        onSelectedTagsChange(selectedTags);
    }, [selectedTags]);

    // チェックボックスの変更を処理します
    const handleTagChange = (event) => {
        const tag = event.target.value;
        if (selectedTags.includes(tag)) {
            setSelectedTags(prevTags => prevTags.filter((s) => s !== tag));
        } else {
            setSelectedTags(prevTags => [...prevTags, tag]);
        }
        console.log(tag);
    };

    // 選択されたタグの削除を処理します
    const handleDelete = (tagToDelete) => () => {
        setSelectedTags((tags) => tags.filter((tag) => tag !== tagToDelete));
    };

    return (
        <div>
            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', marginLeft: '25px' }}>
                カテゴリー
            </Typography>
            <div style={{ marginLeft: '25px' }}>
                {selectedTags.map((tag, index) => (
                    <Chip
                        label={tag}
                        key={index}
                        onDelete={handleDelete(tag)}
                        style={{ margin: '5px' }}
                    />
                ))}
            </div>
            {category ? (
                category.map((mainCategory) => (
                    <Accordion key={mainCategory.main_category}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{
                                backgroundColor: mainCategory.main_category === '機械系' ? '#f6fffe'
                                    : mainCategory.main_category === '情報IT系' ? '#f6fffe'
                                        : '#f6fffe'
                            }}
                        >
                            <Typography variant="subtitle1">{mainCategory.main_category}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            {mainCategory.sub_categories.map((subCategory) => (
                                <Accordion key={subCategory.sub_category}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        style={{
                                            backgroundColor: '#f9f9f9'
                                        }}
                                    >
                                        <Typography variant="subtitle1">{subCategory.sub_category}</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <FormGroup>
                                            {subCategory.tags.map((tag) => (
                                                <FormControlLabel
                                                    key={tag}
                                                    control={
                                                        <Checkbox
                                                            checked={selectedTags.includes(tag)}
                                                            onChange={handleTagChange}
                                                            value={tag}
                                                        />
                                                    }
                                                    label={tag}
                                                />
                                            ))}
                                        </FormGroup>
                                    </AccordionDetails>
                                </Accordion>
                            ))}
                        </AccordionDetails>
                    </Accordion>
                ))
            ) : (
                <p>Loading...</p>
            )}
        </div>
    );
};
export default Categories;
