
import React from 'react';

const PaginationControls = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
    // ページ数を計算
    const totalPages = Math.ceil(totalItems / itemsPerPage);


    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', width: '100%' }}>
            {currentPage > 1 &&
                <button
                    style={{
                        backgroundColor: '#1976d2',
                        color: 'white',
                        padding: '10px 20px',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontSize: '16px',
                        fontWeight: '600',
                        transition: 'background-color 0.3s',
                        margin: '5px'
                    }}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#1565c0'}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#1976d2'}
                    onClick={() => onPageChange(currentPage - 1)}
                >
                    前へ
                </button>
            }

            {Array.from({ length: totalPages }).map((_, index) => (
                <button
                    key={index}
                    style={{
                        backgroundColor: currentPage === (index + 1) ? '#1565c0' : '#1976d2',
                        color: 'white',
                        padding: '10px 20px',
                        border: currentPage === (index + 1) ? '2px solid #ffffff' : 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontSize: currentPage === (index + 1) ? '18px' : '16px',
                        fontWeight: currentPage === (index + 1) ? '700' : '600',
                        transition: 'background-color 0.3s',
                        margin: '5px',
                        boxShadow: currentPage === (index + 1) ? '0px 4px 6px rgba(0, 0, 0, 0.1)' : 'none'
                    }}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#1565c0'}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = currentPage === (index + 1) ? '#1565c0' : '#1976d2'}
                    onClick={() => onPageChange(index + 1)}
                >
                    {index + 1}
                </button>
            ))}

            {currentPage < totalPages &&
                <button
                    style={{
                        backgroundColor: '#1976d2',
                        color: 'white',
                        padding: '10px 20px',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        fontSize: '16px',
                        fontWeight: '600',
                        transition: 'background-color 0.3s',
                        margin: '5px'
                    }}
                    onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#1565c0'}
                    onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#1976d2'}
                    onClick={() => onPageChange(currentPage + 1)}
                >
                    次へ
                </button>
            }
        </div>
    );
};

export default PaginationControls;
