import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, Button, Checkbox, FormControlLabel } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Chip from '@mui/material/Chip';
import FormGroup from '@mui/material/FormGroup';
import Grid from '@mui/material/Grid';
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { React, useEffect, useRef, useState } from 'react';
import { useCookies } from "react-cookie";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLocation, useNavigate } from 'react-router-dom';
import CustomLogger from '../logger/Logger';
import { skills } from './skills';

export default function Register() {
    const navigate = useNavigate();
    const location = useLocation();
    const { userId, employeeNumberFromParam } = location.state || {};
    const [searchText, setSearchText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [arrowLeft, setArrowLeft] = useState(0);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const iconRef = useRef(null);
    const [selectedSkills, setSelectedSkills] = useState([]);
    const [error, setError] = useState('');
    const [cookies, setCookie, removeCookie] = useCookies();
    const [userData, setUserData] = useState({
        id: '',
        password: '',
        icon: 'null',
        name: '',
        affiliation: '',
        skill: '',
        skill_level: 2
    });
    const logger = new CustomLogger();

    useEffect(() => {
        if (employeeNumberFromParam) {
            setUserData((prevData) => ({ ...prevData, id: employeeNumberFromParam }));
        }
    }, [employeeNumberFromParam]);

    // アイコンが変更された時の処理
    const handleIconChange = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            // バイナリデータに変換した画像データをセット
            setUserData({ ...userData, icon: reader.result });
        };
        if (file) {
            reader.readAsDataURL(file);
        }
    };

    // スキルが変更された時の処理
    const handleSkillChange = (e) => {
        const skill = e.target.value;
        if (e.target.checked) {
            // チェックが入った場合、新しいスキルを追加する
            setUserData((prevUserData) => ({
                ...prevUserData,
                skill: Array.isArray(prevUserData.skill) ? [...prevUserData.skill, skill] : [skill],
            }));
            // 選択されたスキルのリストに新しいスキルを追加
            setSelectedSkills(prev => [...prev, skill]);
        } else {
            // チェックが外れた場合、既存のスキルから削除する
            setUserData((prevUserData) => ({
                ...prevUserData,
                skill: Array.isArray(prevUserData.skill)
                    ? prevUserData.skill.filter((selectedSkill) => selectedSkill !== skill)
                    : [],
            }));
            // 選択されたスキルのリストから削除
            setSelectedSkills(prev => prev.filter(selectedSkill => selectedSkill !== skill));
        }
        console.log(userData.skill);
    };

    // 保存ボタンを押せるかどうかを判定する関数
    const canSaveUserData = () => {
        // チェックされているスキルが存在するかを確認
        return userData.skill !== null && userData.skill.length > 0;
    };

    // 保存ボタンのdisabled属性を設定する値を取得
    const saveButtonDisabled = !canSaveUserData();

    // ユーザーデータを保存する関数
    async function saveUserData() {
        console.log('保存するデータ:', userData);
        try {
            // ここで実際にDBから取得する
            const res = await axios.post(`/backend/adduserdata`, {
                userData: userData,
            }, {
                withCredentials: true
            });
            console.log(res);
            setError('');

            // ここで実際にDBから取得する
            const resTop = await axios.post(`/backend/totop`, {
                id: userData.id,
            }, {
                withCredentials: true
            });
            console.log(resTop);

            // クッキーにトークンを設定
            setCookie("token", resTop.data.access_token);
            setCookie("refresh_token", resTop.data.refresh_token);

            // /topに移動
            navigate('/top', {
                state: {
                    userId: userData.id,
                    userPassword: userData.password
                }
            });

        } catch (error) {
            if (error.response && error.response.status === 409) { // IDが重複している場合
                setError('IDがすでに登録されています。');
            } else {
                setError('データ送信中に未知のエラーが発生しました。');
            }
            console.error('データ送信中にエラーが発生しました:', error);
            return; // エラーが発生した場合は画面を更新しない
        }
        // 画面を更新
        window.location.reload();
    };


    return (
        <div>
            <Grid container item xs={12} sm={1} md={12} justifyContent="center" alignItems="center"
                sx={{ flexGrow: 1, backgroundColor: '#F2F2F2', height: '100vh', overflow: 'auto' }}>
                <Grid container item xs={12} justifyContent="center" alignItems="center">

                    <TableContainer component={Paper} sx={{ maxHeight: 880, marginTop: 2 }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableBody>
                                {/* 社員番号の入力欄 */}
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginBottom: '20px' }}>社員番号</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <input
                                            type="number"
                                            value={userData.id || ""}
                                            onChange={(e) => {
                                                if (/^\d*$/.test(e.target.value)) {
                                                    setUserData({ ...userData, id: e.target.value });
                                                }
                                            }}
                                            required
                                            readOnly={!!employeeNumberFromParam}
                                        />
                                    </TableCell>
                                </TableRow>
                                {/* パスワードの入力欄 */}
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginBottom: '20px' }}>パスワード</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <input
                                            pattern="[a-zA-Z0-9]*"
                                            value={userData.password || ""}
                                            onChange={(e) => setUserData({ ...userData, password: e.target.value })}
                                            required
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginBottom: '20px' }}>アイコン</Typography>
                                        {userData.icon && userData.icon !== 'null' && (
                                            <img src={userData.icon} alt="Icon Preview" style={{ width: '100%', maxWidth: '100px' }} />
                                        )}
                                    </TableCell>

                                    <TableCell>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleIconChange}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginBottom: '20px' }}>ニックネーム</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <input
                                            type="text"
                                            value={userData.name}
                                            onChange={(e) => setUserData({ ...userData, name: e.target.value })}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginBottom: '20px' }}>所属</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <input
                                            type="text"
                                            value={userData.affiliation}
                                            onChange={(e) => setUserData({ ...userData, affiliation: e.target.value })}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row">
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginBottom: '20px' }}>通知</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <select
                                            value={userData.skill_level === 5 ? 'on' : 'off'}
                                            onChange={(e) => {
                                                console.log(e.target.value);
                                                const value = e.target.value === 'on' ? 5 : 2;
                                                setUserData({ ...userData, skill_level: value });
                                            }}
                                        >
                                            <option value='on'>オン</option>
                                            <option value='off'>オフ</option>
                                        </select>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell component="th" scope="row" style={{ verticalAlign: 'top' }}>
                                        <Typography variant="subtitle1" style={{ fontWeight: 'bold', marginBottom: '20px' }}>スキル</Typography>
                                    </TableCell>
                                    <TableCell>
                                        {skills.map((skillCategory) => (
                                            <Accordion key={skillCategory.category}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography variant="subtitle1">{skillCategory.category}</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <FormGroup>
                                                        {skillCategory.skills.map((skill) => (
                                                            <FormControlLabel
                                                                key={skill}
                                                                control={
                                                                    <Checkbox
                                                                        checked={userData.skill !== null && userData.skill.includes(skill)}
                                                                        onChange={handleSkillChange}
                                                                        value={skill}
                                                                    />
                                                                }
                                                                label={skill}
                                                            />
                                                        ))}
                                                    </FormGroup>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))}
                                        <div style={{ marginTop: '50px' }}>
                                            <Typography variant="h6">選択されたスキル:</Typography>
                                            <div>
                                                {selectedSkills.flatMap(skill => skill.split(', ')).map((skill, index) => (
                                                    <Chip key={index} label={skill.trim()} />
                                                ))}
                                            </div>

                                        </div>
                                    </TableCell>

                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                        <Button
                            onClick={saveUserData}
                            variant="contained"
                            color="primary"
                            disabled={saveButtonDisabled}
                            sx={{ width: '200px', height: '50px' }}
                        >
                            保存
                        </Button>
                        {error && <div style={{ color: 'red' }}>{error}</div>}
                    </Box>
                </Grid >
            </Grid >
        </div >
    );
};
