import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import axios from 'axios';
import { React, useEffect, useRef, useState } from 'react';
import { useCookies } from "react-cookie";
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../header/Header';
import QuestionsList from './QuestionsList';

export default function Top() {
    const location = useLocation();
    const navigate = useNavigate();
    const [userId, setUserId] = useState(null);
    const iconRef = useRef(null);
    const [selectedTab, setSelectedTab] = useState(0);
    const [questions, setQuestions] = useState(null);
    const [searchText, setSearchText] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [arrowLeft, setArrowLeft] = useState(0);
    const [notificationCount, setNotificationCount] = useState(0);
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const [notifications, setNotifications] = useState([]);
    const [userData, setUserData] = useState({
        id: '',
        password: '',
        icon: '',
        name: '',
        affiliation: '',
        skill: '',
        role: '',
    });
    const [categories, setCategories] = useState(null);
    const [selectedTags, setSelectedTags] = useState([]);
    const [cookies, setCookie, removeCookie] = useCookies();

    // location.stateからuserIdを取得
    useEffect(() => {
        // location.stateが存在する場合は、その値を使用
        if (location.state && location.state.userId) {
                // location.stateが存在する場合は、その値を使用
                setUserId(location.state.userId);
        } else {
            // トークンからユーザー情報を取得
            const fetchUserId = async () => {
                try {
                    const res = await axios.get(`/backend/me`, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + cookies.token,
                        },
                    });
                    if (res.data.login_ok === "login_ok") {
                        setUserId(res.data.id);
                    } else {
                        // ユーザー情報が取得できない場合、ログインページにリダイレクト
                        navigate('/login');
                    }
                } catch (error) {
                    console.error('ユーザー情報の取得中にエラーが発生しました:', error);
                    navigate('/login');
                }
            };
            fetchUserId();
        }
    }, [location, cookies.token, navigate]);

    // 画面が再描画される度に質問を取得するようにする
    useEffect(() => {
        console.log('質問を取得します');
        async function fetchQuestion() {
            const res = await axios.post(
                `/backend/getquestions`,
                {
                    withCredentials: true
                }
            );
            console.log(res);
            setQuestions(res.data);
        }

        fetchQuestion(); 
    }, []);

    // ユーザーデータを取得
    useEffect(() => {
        console.log('ユーザーデータを取得します');
        async function fetchUserData() {
            console.log('userId:', userId);
            try {
                if (!userId) {
                    return;
                }
                const res = await axios.post(`/backend/getuserdata`, {
                    user_id: userId,
                    withCredentials: true
                });
                console.log(res);
                setUserData(res.data.user);
            } catch (error) {
                console.error('ユーザーデータの取得中にエラーが発生しました:', error);
            }
        }

        fetchUserData();
    }, [userId]);

    // 通知を取得
    useEffect(() => {
        console.log('通知を取得します');
        async function fetchNotification() {
            try {
                if (!userId) {
                    return;
                }
                const res = await axios.post(`/backend/getnotifications`, {
                    user_id: userId,
                    withCredentials: true
                });
                console.log(res);
                setNotifications(res.data);
                setNotificationCount(res.data.length);
            } catch (error) {
                console.error('通知の取得中にエラーが発生しました:', error);
            }
        }

        fetchNotification();
    }, [userId]);

    // カテゴリを取得
    useEffect(() => {
        console.log('カテゴリを取得します');
        async function fetchCategory() {
            const res = await axios.post(
                `/backend/getcategories`,
                {
                    withCredentials: true
                }
            );
            console.log(res);
            setCategories(res.data);
        }

        fetchCategory();
    }, []);

    // アイコンの位置を更新
    useEffect(() => {
        const updateArrowPosition = () => {
            if (iconRef.current) {
                const iconRect = iconRef.current.getBoundingClientRect();
                setArrowLeft(iconRect.left + iconRect.width / 2 - 8);
            }
        };

        // ウィンドウのリサイズ時にアイコンの位置を更新
        window.addEventListener('resize', updateArrowPosition);
        updateArrowPosition();

        return () => {
            window.removeEventListener('resize', updateArrowPosition);
        };
    }, []);

    // サービスワーカーの登録
    useEffect(() => {
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/service-worker.js')
                .then((registration) => {
                    console.log('Service Worker registered with scope:', registration.scope);
                })
                .catch((err) => {
                    console.error('Service Worker registration failed:', err);
                });
        }
    }, []);

    // 通知の許可を求める
    useEffect(() => {
        const askForNotificationPermission = () => {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                }
            });
        };

        // ブラウザが通知をサポートしているか確認
        if (!('Notification' in window)) {
            console.log('This browser does not support notifications.');
        } else {
            if (Notification.permission === 'granted') {
                console.log('Permission has already been granted');
            } else if (Notification.permission !== 'denied') {
                askForNotificationPermission();
            }
        }
    }, []);

    // プッシュ通知の購読
    useEffect(() => {
        if (!('serviceWorker' in navigator && 'PushManager' in window)) {
            console.warn('Service Workers or PushManager are not supported in this browser.');
            return;
        }

        // サービスワーカーを処理
        const handleServiceWorker = async () => {
            try {
                const registration = await navigator.serviceWorker.ready;
                const subscription = await registration.pushManager.getSubscription();

                //
                if (subscription) {
                    try {
                        await subscription.unsubscribe();
                        console.log('Unsubscribed!');
                    } catch (e) {
                        console.log('Failed to unsubscribe.', e);
                    }
                }

                // サーバーから公開鍵を取得
                const response = await axios.get(`/backendpublicKey`, {
                    withCredentials: true
                });

                // サーバーから公開鍵を取得
                const serverPublicKey = response.data.publicKey;

                // Base64URL形式の公開鍵かどうかを確認
                if (/^[-A-Za-z0-9_=]*$/.test(serverPublicKey)) {
                    console.log("The public key is in Base64URL format.");
                } else {
                    console.log("The public key is not in Base64URL format.");
                    return;
                }

                // 公開鍵をUint8Arrayに変換
                const convertedVapidKey = urlBase64ToUint8Array(serverPublicKey);

                // プッシュ通知の購読
                try {
                    const subscription = await registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: convertedVapidKey,
                    });

                    console.log('Subscription:', convertedVapidKey);
                    await axios.post(
                        `/backend/subscribe`,
                        {
                            user_id: userId,
                            subscription: subscription,
                            withCredentials: true
                        }
                    );
                } catch (error) {
                    console.error("Error during pushManager subscription:", error);
                }
            } catch (error) {
                console.error("Error handling service worker:", error);
            }
        };

        // Base64URL形式の文字列をUint8Arrayに変換
        const urlBase64ToUint8Array = (base64String) => {
            const base64 = base64String.replace(/-/g, '+').replace(/_/g, '/');
            const rawData = atob(base64);
            const outputArray = new Uint8Array(rawData.length);
            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        };

        handleServiceWorker();
    }, []);

    // 画面が再描画される度に質問を取得するようにする
    useEffect(() => {
        console.log("質問を取得します");
        async function fetchQuestion() {
            const res = await axios.post(
                `/backend/getquestions`,
                {
                    withCredentials: true
                }
            );
            console.log(res)
            console.log(res.data)
            setQuestions(res.data);
        }
        fetchQuestion();

    }, []);

    // タグが選択されたら質問を絞り込む
    useEffect(() => {
        console.log(selectedTags);
    }, [questions, selectedTags]);


    return (
        <div>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0, paddingTop: '10px' }}>
                <Header
                    searchText={searchText}
                    notificationCount={notificationCount}
                    id={id}
                    iconRef={iconRef}
                    arrowLeft={arrowLeft}
                    notifications={notifications}
                    userId={userId}
                    userData={userData}
                    categories={categories}
                />

            </Box>

            <Grid
                container
                direction="row"
                justifyContent="center"
                spacing={0}
                sx={{ width: '100%', mx: 0 }}
            >
                <Grid
                    item xs={12} md={12}
                    sx={{ mt: 5, order: { xs: 1, md: 0 }, padding: 0 }}
                >
                    <QuestionsList
                        userId={userId}
                        selectedTab={selectedTab}
                        setSelectedTab={setSelectedTab}
                        questions={questions}
                        userData={userData}
                        notificationCount={notificationCount}
                        notifications={notifications}
                        category={categories}
                    />
                </Grid>
            </Grid>
        </div>
    );
}
