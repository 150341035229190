import React from 'react';

function MaintenancePage() {
    return (
        <div style={{ textAlign: 'center', marginTop: '15%' }}>
            <h1>メンテナンス中</h1>
            <p>申し訳ありませんが、現在、システムメンテナンスを行っています。</p>
            <p>メンテナンス終了後、再度アクセスしてください。</p>
        </div>
    );
}

export default MaintenancePage;
